import {
  FETCH_SUBSCRIPTIONS_REQUEST,
  FETCH_SUBSCRIPTIONS_SUCCESS,
  FETCH_SUBSCRIPTIONS_FAILURE,
  CREATE_SUBSCRIPTION_REQUEST,
  CREATE_SUBSCRIPTION_SUCCESS,
  CREATE_SUBSCRIPTION_FAILURE,
  ACTIVATE_SUBSCRIPTION_REQUEST,
  ACTIVATE_SUBSCRIPTION_SUCCESS,
  ACTIVATE_SUBSCRIPTION_FAILURE,
  DEACTIVATE_SUBSCRIPTION_REQUEST,
  DEACTIVATE_SUBSCRIPTION_SUCCESS,
  DEACTIVATE_SUBSCRIPTION_FAILURE,
  SELECTED_SUBSCRIPTION,
  REMOVE_SUBSCRIPTION_REQUEST,
  REMOVE_SUBSCRIPTION_FAILURE,
} from "../constants/subscriptionConstants";

import { authApi, getAuthorizationHeader } from "../../api/api";

export const fetchSubscriptions =
  (page = 1, limit = 10, search = "", sort = "package_name") =>
  async (dispatch) => {
    try {
      dispatch({ type: FETCH_SUBSCRIPTIONS_REQUEST });

      const { data } = await authApi.get(
        `/subscriptions?page=${page}&limit=${limit}&search=${search}&sort=${sort}`,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );

      dispatch({ type: FETCH_SUBSCRIPTIONS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: FETCH_SUBSCRIPTIONS_FAILURE, payload: error.message });
    }
  };

export const createSubscription = (subscriptionData) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_SUBSCRIPTION_REQUEST });
    const { data } = await authApi.post("/subscriptions", subscriptionData, {
      headers: { Authorization: getAuthorizationHeader() },
    });

    dispatch({
      type: CREATE_SUBSCRIPTION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_SUBSCRIPTION_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const fetchSubscription = (id) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_SUBSCRIPTIONS_REQUEST });

    const { data } = await authApi.get(`/subscriptions/${id}`, {
      headers: { Authorization: getAuthorizationHeader() },
    });

    dispatch({
      type: SELECTED_SUBSCRIPTION,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_SUBSCRIPTIONS_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateSubscription =
  (id, subscriptionData) => async (dispatch) => {
    try {
      dispatch({ type: FETCH_SUBSCRIPTIONS_REQUEST });

      const { data } = await authApi.put(
        `/subscriptions/${id}`,
        subscriptionData,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );

      dispatch({
        type: SELECTED_SUBSCRIPTION,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_SUBSCRIPTIONS_FAILURE,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const activateSubscription = (subscriptionId) => async (dispatch) => {
  try {
    dispatch({ type: ACTIVATE_SUBSCRIPTION_REQUEST });

    const { data } = await authApi.put(
      `/subscriptions/active/${subscriptionId}`,
      {
        headers: { Authorization: getAuthorizationHeader() },
      }
    );

    dispatch({ type: ACTIVATE_SUBSCRIPTION_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ACTIVATE_SUBSCRIPTION_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deactivateSubscription =
  (subscriptionId, setIsActive) => async (dispatch) => {
    try {
      dispatch({ type: DEACTIVATE_SUBSCRIPTION_REQUEST });

      const { data } = await authApi.put(
        `/subscriptions/deactive/${subscriptionId}`,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );
      setIsActive(false);
      dispatch({ type: DEACTIVATE_SUBSCRIPTION_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: DEACTIVATE_SUBSCRIPTION_FAILURE,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteSubscription = (id) => async (dispatch) => {
  try {
    dispatch({ type: REMOVE_SUBSCRIPTION_REQUEST });

    const { data } = await authApi.delete(`/subscriptions/${id}`, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    if (data.message === "Subscription removed") {
      dispatch(fetchSubscriptions(1, 10, "", "packageName"));
    }
  } catch (error) {
    dispatch({
      type: REMOVE_SUBSCRIPTION_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
