import moment from "moment";
import { authApi, getAuthorizationHeader } from "../../api/api";
import {
  CREATE_COMMUNICATION_NOTIFICATION_FAIL,
  CREATE_COMMUNICATION_NOTIFICATION_REQUEST,
  CREATE_COMMUNICATION_NOTIFICATION_SUCCESS,
  DELETE_COMMUNICATION_NOTIFICATION_FAIL,
  DELETE_COMMUNICATION_NOTIFICATION_REQUEST,
  LIST_COMMUNCATION_FAIL,
  LIST_COMMUNCATION_REQUEST,
  LIST_COMMUNCATION_SUCCESS,
  ONE_COMMUNICATION_FAIL,
  ONE_COMMUNICATION_REQUEST,
  ONE_COMMUNICATION_SUCCESS,
} from "../constants/communicationConstants";

export const createCommunicationNotification =
  (userEntry) => async (dispatch) => {
    try {
      dispatch({
        type: CREATE_COMMUNICATION_NOTIFICATION_REQUEST,
      });

      const { data } = await authApi.post(`/communication/create`, userEntry, {
        headers: { Authorization: getAuthorizationHeader() },
      });
      console.log(data);
      dispatch({
        type: CREATE_COMMUNICATION_NOTIFICATION_SUCCESS,
        payload: data,
      });

    } catch (error) {
      dispatch({
        type: CREATE_COMMUNICATION_NOTIFICATION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const GetCommunicationList = (page, limit, sort, userEntry, state) => async (dispatch) => {
  try {
    dispatch({
      type: LIST_COMMUNCATION_REQUEST,
    });
    console.log(state);
    const { data } = await authApi.post(`/communication/list?page=${page || 1}&limit=${limit || 10}&sort=${sort}&start_date=${moment(state[0].startDate).format("YYYY-MM-DD")}&end_date=${moment(state[0].endDate).format("YYYY-MM-DD")}`, userEntry, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    console.log(data);
    dispatch({
      type: LIST_COMMUNCATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LIST_COMMUNCATION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const DeleteselectedCommunication = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_COMMUNICATION_NOTIFICATION_REQUEST,
    });
    const { data } = await authApi.delete(`/communication/delete/${id}`, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    console.log(data);
    const data1 = await authApi.post(`/communication/list`, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    console.log(data1.data);
    dispatch({
      type: LIST_COMMUNCATION_SUCCESS,
      payload: data1.data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_COMMUNICATION_NOTIFICATION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getOneCommunicationDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ONE_COMMUNICATION_REQUEST,
    });
    const { data } = await authApi.get(`/communication/view/${id}`, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    console.log(data);
    dispatch({
      type: ONE_COMMUNICATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ONE_COMMUNICATION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const UpdateCommunicationNotification = (userEntry, id) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_COMMUNICATION_NOTIFICATION_REQUEST,
    });

    const { data } = await authApi.put(`/communication/update/${id}`, userEntry, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    console.log(data);
    dispatch({
      type: CREATE_COMMUNICATION_NOTIFICATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_COMMUNICATION_NOTIFICATION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}

export const ResendNotification = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_COMMUNICATION_NOTIFICATION_REQUEST,
    });
    const { data } = await authApi.put(`/communication/resend/${id}`, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    console.log(data);
    const data1 = await authApi.post(`/communication/list`, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    console.log(data1.data);
    dispatch({
      type: LIST_COMMUNCATION_SUCCESS,
      payload: data1.data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_COMMUNICATION_NOTIFICATION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

