
//Listing Subscriptions
export const FETCH_SUBSCRIPTIONS_REQUEST = 'FETCH_SUBSCRIPTION_REQUEST';
export const FETCH_SUBSCRIPTIONS_SUCCESS = 'FETCH_SUBSCRIPTIONS_SUCCESS';
export const FETCH_SUBSCRIPTIONS_FAILURE = 'FETCH_SUBSCRIPTIONS_FAILURE';

//Creating Subscriptions
export const CREATE_SUBSCRIPTION_REQUEST = 'CREATE_SUBSCRIPTION_REQUEST';
export const CREATE_SUBSCRIPTION_SUCCESS = 'CREATE_SUBSCRIPTION_SUCCESS';
export const CREATE_SUBSCRIPTION_FAILURE = 'CREATE_SUBSCRIPTION_FAILURE';

//activate subscriptions
export const ACTIVATE_SUBSCRIPTION_REQUEST = 'ACTIVATE_SUBSCRIPTION_REQUEST';
export const ACTIVATE_SUBSCRIPTION_SUCCESS = 'ACTIVATE_SUBSCRIPTION_SUCCESS';
export const ACTIVATE_SUBSCRIPTION_FAILURE = 'ACTIVATE_SUBSCRIPTION_FAILURE';

//deactivate subscriptions

export const DEACTIVATE_SUBSCRIPTION_REQUEST = 'DEACTIVATE_SUBSCRIPTION_REQUEST';
export const DEACTIVATE_SUBSCRIPTION_SUCCESS = 'DEACTIVATE_SUBSCRIPTION_SUCCESS';
export const DEACTIVATE_SUBSCRIPTION_FAILURE = 'DEACTIVATE_SUBSCRIPTION_FAILURE';

//delete subscriptions

export const REMOVE_SUBSCRIPTION_REQUEST = 'REMOVE_SUBSCRIPTION_REQUEST';
export const REMOVE_SUBSCRIPTION_SUCCESS = 'REMOVE_SUBSCRIPTION_SUCCESS';
export const REMOVE_SUBSCRIPTION_FAILURE = 'REMOVE_SUBSCRIPTION_FAILURE';

//Fetch Subscription

export const SELECTED_SUBSCRIPTION = 'SELECTED_SUBSCRIPTION';

