import {
  CREATE_COMMUNICATION_NOTIFICATION_FAIL,
  CREATE_COMMUNICATION_NOTIFICATION_REQUEST,
  CREATE_COMMUNICATION_NOTIFICATION_SUCCESS,
  DELETE_COMMUNICATION_NOTIFICATION_FAIL,
  DELETE_COMMUNICATION_NOTIFICATION_REQUEST,
  LIST_COMMUNCATION_FAIL,
  LIST_COMMUNCATION_REQUEST,
  LIST_COMMUNCATION_SUCCESS,
  ONE_COMMUNICATION_FAIL,
  ONE_COMMUNICATION_REQUEST,
  ONE_COMMUNICATION_SUCCESS,
} from "../constants/communicationConstants";

const initialState = {
  loading: true,
  createError: null,
  totalCount: 0,
  communicationList: [],
  errorlist: null,
  oneCommunication: null,
};

export const communicationReducers = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_COMMUNICATION_NOTIFICATION_REQUEST:
      return { ...state, loading: true };

    case CREATE_COMMUNICATION_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        createError: null,
      };
    case CREATE_COMMUNICATION_NOTIFICATION_FAIL:
      return {
        ...state,
        loading: false,
        createError: action.payload,
      };
    case LIST_COMMUNCATION_REQUEST:
      return { ...state, loading: true };

    case LIST_COMMUNCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        communicationList: action.payload.communications,
        totalCount: action.payload.totalCount,
        oneCommunication: null,
        errorlist: null,
      };
    case LIST_COMMUNCATION_FAIL:
      return {
        ...state,
        loading: false,
        errorlist: action.payload,
      };
    case DELETE_COMMUNICATION_NOTIFICATION_REQUEST:
      return { ...state, loading: true };
    case DELETE_COMMUNICATION_NOTIFICATION_FAIL:
      return {
        ...state,
        loading: false,
        errorlist: action.payload,
      };
    case ONE_COMMUNICATION_REQUEST:
      return { ...state, loading: true };

    case ONE_COMMUNICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        oneCommunication: action.payload,
        errorlist: null,
      };
    case ONE_COMMUNICATION_FAIL:
      return {
        ...state,
        loading: false,
        errorlist: action.payload,
      };
    default:
      return state;
  }
};
