import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchLeads } from "../../../Redux/actions/customerActions";
import {
  Typography,
  TextField,
  InputAdornment,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Card,
  TableBody,
} from "@mui/material";
import classes from "./customer.module.css";
import LeadListItem from "./LeadListItem";
import Pagination from "../../../Components//Pagination";
import { useNavigate } from "react-router-dom";
import FilterMenu from "../../../Components/customerPageFilter";
import { CustomerDesignationFilter } from "../../../Redux/actions/filterActions";
import {
  OppositeSortArrawBtnIcons,
  RoundAddIcon,
  SearchIcon,
  SortArrawBtnIcons,
} from "../../../Components/Icons/Icons";
import NoDataFound from "../../../Assets/images/No data-pana 1.png";
import { handleSortClick } from "../../../Utilities/commonfunctions";

const Leads = () => {
  const PaginationData = useSelector(
    (state) => state.paginationData.Pagination
  );
  const { rowCount, currentPage } = PaginationData || {};
  const [designationList, setDesignationList] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  /*   const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10); */
  const customerList = useSelector((state) => state.customerData);
  const { loading, error, leads, totalCountLeads } = customerList;

  const { sortBy, designation } = useSelector((state) => state.filter);
  useEffect(() => {
    const filterArray = {
      designation,
    };
    dispatch(fetchLeads(currentPage, rowCount, search, sortBy, filterArray));
  }, [dispatch, currentPage, rowCount, search, sortBy, designation]);

  useEffect(() => {}, [leads]);

  useEffect(() => {
    if (leads?.length > 0) {
      const array = leads.map((customer) => {
        return customer.designation;
      });
      const resultArray = array.filter((value, index, self) => {
        return (
          value !== undefined && value !== "" && self.indexOf(value) === index
        );
      });
      console.log(resultArray);
      setDesignationList(resultArray);
    }
  }, [leads]);

  return (
    <div>
      {totalCountLeads > 0 ? (
        <div>
          <div className={classes.HeaderInputContainer}>
            <div>
              <TextField
                sx={{
                  "& legend": {
                    display: "none",
                  },
                  "& fieldset": {
                    top: 0,
                  },
                }}
                placeholder="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                className={classes.teamMemberSearch}
                id="firstNameinput"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                size="small"
              />
            </div>
            <div>
              <Button
                variant="contained"
                className={classes.MainHeaderCustomerButton}
                onClick={() => navigate("/customers/invite")}
              >
                <RoundAddIcon />
                <span> Invite Customer </span>
              </Button>
            </div>
          </div>
          <div className={classes.tableContainer}>
            <Table
              style={{
                marginTop: "20px",
              }}
              className={classes.MainTable}
            >
              <TableHead>
                <TableRow className={classes.tableHeadRow}>
                  <TableCell className={classes.tableHeadCell}>
                    <div>
                      <span> Company Name </span>
                      <span
                        onClick={() =>
                          handleSortClick(
                            dispatch,
                            sortBy,
                            "companyInfo.company_name"
                          )
                        }
                      >
                        {sortBy?.includes("desc") &&
                        sortBy?.includes("companyInfo.company_name") ? (
                          <OppositeSortArrawBtnIcons />
                        ) : (
                          <SortArrawBtnIcons />
                        )}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell className={classes.tableHeadCell}>
                    <div className={classes.tableHEadercelldiv}>
                      <span> Contact Person </span>
                      <span
                        onClick={() =>
                          handleSortClick(dispatch, sortBy, "first_name")
                        }
                      >
                        {sortBy?.includes("desc") &&
                        sortBy?.includes("first_name") ? (
                          <OppositeSortArrawBtnIcons />
                        ) : (
                          <SortArrawBtnIcons />
                        )}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell className={classes.tableHeadCell}>
                    <div className={classes.tableHEadercelldiv}>
                      <span> Designation </span>
                      {designationList && designationList.length > 0 && (
                        <FilterMenu
                          option={designationList}
                          name={CustomerDesignationFilter}
                        />
                      )}
                      <span
                        onClick={() =>
                          handleSortClick(dispatch, sortBy, "designation")
                        }
                      >
                        {sortBy?.includes("desc") &&
                        sortBy?.includes("designation") ? (
                          <OppositeSortArrawBtnIcons />
                        ) : (
                          <SortArrawBtnIcons />
                        )}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell className={classes.tableHeadCell}>
                    <span> Mobile Number </span>
                  </TableCell>
                  <TableCell className={classes.tableHeadCell}>
                    <span> Email ID </span>
                    <span
                      onClick={() => handleSortClick(dispatch, sortBy, "email")}
                    >
                      {sortBy?.includes("desc") && sortBy?.includes("email") ? (
                        <OppositeSortArrawBtnIcons />
                      ) : (
                        <SortArrawBtnIcons />
                      )}
                    </span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={`list form-check-all ${classes.MainTable}`}>
                {/* loop customer.data is > 0 */}
                {loading ? (
                  <TableRow>
                    <TableCell colSpan="9" className="text-center">
                      Loading...
                    </TableCell>
                  </TableRow>
                ) : error ? (
                  <TableRow>
                    <TableCell colSpan="9" className="text-center">
                      {error}
                    </TableCell>
                  </TableRow>
                ) : leads && leads.length > 0 ? (
                  leads.map((item, index) => (
                    <LeadListItem key={index} leads={item} />
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan="9" className="text-center">
                      No Data Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <Pagination total={totalCountLeads} />
        </div>
      ) : (
        <div>
          <Card className={classes.noDataCardContainer}>
            <img
              src={NoDataFound}
              alt="No Data Found"
              width={386}
              height={290}
            />
            <Typography className={classes.cardHeading}>
              You have not invited any customers at the moment
            </Typography>
            <div className="mt-3">
              <Button
                variant="contained"
                className={classes.MainHeaderCustomerButton}
                onClick={() => navigate("/customers/invite")}
              >
                <RoundAddIcon />
                <span> Invite Customer </span>
              </Button>
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};

export default Leads;
