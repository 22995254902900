import seedrandom from "seedrandom";
import moment from "moment";
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import { refreshJWITokenaction } from "../Redux/actions/userActions";
import { setSortName } from "../Redux/actions/filterActions";


export const Alphabetic = (event) => {
    var x = event.charCode || event.keyCode;
    if ((x > 96 && x < 123) || (x > 64 && x < 91) || x === 32) {
        return true;
    } else {
        event.preventDefault();
        return false;
    }
};

export const getNotificationUnseenCount = (notificationList) => {
    if (notificationList && notificationList.length > 0) {
        return notificationList.filter((item) => item.is_seen === false).length;
    }
    return 0;
}

export const checkJwt = (dispatch, id) => {
    const refreshToken = Cookies.get('userToken') ? JSON.parse(Cookies.get('userToken')) : {};
    console.log(refreshToken);
    if (refreshToken && refreshToken.token) {
        const decodedToken = jwt_decode(refreshToken.token);
        const currentTime = Date.now() / 1000;
        const tokenExpiryTime = decodedToken.exp;
        console.log(tokenExpiryTime - currentTime);
        if (tokenExpiryTime - currentTime < 30 * 60) {
            dispatch(refreshJWITokenaction(id));
            console.log("need to refresh token");
        }
    }
}

export const Numeric = (event) => {
    var x = event.charCode || event.keyCode;
    if (x > 47 && x < 58) {
        return true;
    } else {
        event.preventDefault();
        return false;
    }
};

export const generateRandomString = (inputText) => {
    const alphabet = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    const rng = seedrandom(inputText);

    let randomString = "";

    for (let i = 0; i < inputText?.length + 15; i++) {
        const randomIndex = Math.floor(rng() * alphabet.length);
        randomString += alphabet[randomIndex];
    }

    return randomString;
};

export const generateRandomStringforMap = () => {
    const alphanumericChars = "abcdefghijklmnopqrstuvwxyz0123456789";
    let randomString = "";
    const length = 5;

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * alphanumericChars.length);
        randomString += alphanumericChars.charAt(randomIndex);
    }
    return randomString;
};

export function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export const generateColor = (text) => {
    const randomst = generateRandomString(text);
    // console.log(randomst);
    const cancat = text?.replace(" ", "") + randomst;
    const hashCode = (s) => {
        let h = 0;
        for (let i = 0; i < s.length; i++) {
            h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
        }
        return h;
    };

    const hash = hashCode(cancat);

    const redComponent = (hash & 0xff0000) >> 16;
    const greenComponent = (hash & 0x00ff00) >> 16;
    const blueComponent = hash & 0x0000ff;

    const color1 = `#${redComponent.toString(16).padStart(2, "0")}${greenComponent
        .toString(16)
        .padStart(2, "0")}${blueComponent.toString(16).padStart(2, "0")}`;

    // If you want to generate a second color with reduced opacity (e.g., 40%), you can do this:

    return color1;
};

export const getExpiryDate = (item) => {
    //calculate expiry date using created at and only using item.duration
    const expiryDate = moment(item.start_date).add(
        item.duration_details.days,
        "days"
    );
    //return days in DD MMM YYYY format
    return moment(expiryDate).format("DD MMM YYYY");
};

export const claculateRemaining = (item) => {
    //calculate remaining days as a number of days in number using created at and item.duration
    const expiryDate = moment(item.start_date).add(
        item.duration_details.days,
        "days"
    ); //no of remaing days as int
    const today = moment(); //today's date
    const remainingDays = expiryDate.diff(today, "days"); //no of remaing days as int
    return item.duration_details.days - remainingDays;
};

export const IsEmailCheck = (event) => {
    var x = event.charCode || event.keyCode;
    if ((x > 96 && x < 123) || x === 46 || x === 64 || (x > 47 && x < 58)) {
        return true;
    } else {
        event.preventDefault();
        return false;
    }
};

export const stringAvatar = (name) => {
    if (name) {
        if (name.trimStart().trimEnd().includes(" ")) {
            return {
                children: `${name.split(" ")[0][0].toUpperCase()}${name.split(" ")[1][0].toUpperCase()}`,
            };
        } else {
            return {
                children: `${name[0].toUpperCase()}${name[1].toUpperCase()}`,
            };
        }
    } else {
        return {
            children: "",
        };
    }
};

export const handleSortClick = (dispatch, sortBy, name) => {
    console.log(name);
    const namearr = name?.split("-");
    const sortByarr = sortBy?.split("-");
    console.log(sortBy);
    if (sortByarr && sortByarr.length > 0 && namearr[0] === sortByarr[0]) {
        if (sortByarr[1] === "asc") {
            dispatch(setSortName(`${namearr[0]}-desc`));
        }
        else {
            console.log("condition 3");
            dispatch(setSortName(`${namearr[0]}-asc`));
        }
    } else {
        dispatch(setSortName(`${namearr[0]}-asc`));
    }
}