import React from "react";
import classes from "./auditTrial.module.css";
import {
    TableCell,
    TableRow,
    Typography,
    Avatar,
} from "@mui/material";
import moment from "moment";

function AuditTrialListItem(props) {
    return (
        <TableRow className={`${classes.table_bg1}`}>
            <TableCell>
                <div>
                    <Typography
                        href="#javascript: void(0);"
                        className={classes.TableCellMainText}
                    >
                        {moment(props.item.createdAt).format("DD MMM YYYY")}
                    </Typography>
                    <span className={classes.SomeOtheText}>{moment(props.item.createdAt).format("HH:mm A")}</span>
                </div>
            </TableCell>
            <TableCell><div>
                <Typography
                    href="#javascript: void(0);"
                    className={classes.TableCellMainText}
                >
                    {props.item.companyInfo?.company_name}
                </Typography>
                <span className={classes.SomeOtheText}>{props.item.companyInfo ? `Id:${props.item.companyInfo?._id.slice(0, 7)}` : null}</span>
            </div></TableCell>
            <TableCell><div className={classes.displayFlex}>
                {props.item.userInfo && props.item.userInfo.profile_pic ?
                    <Avatar
                        src={`${process.env.REACT_APP_IMAGE_URL}/${props.item.userInfo.profile_pic.destination}${props.item.userInfo.profile_pic.filename}`}
                        alt=""
                        className="avatar-xs rounded-circle me-2"
                    /> :
                    <Avatar
                        src={""}
                        alt=""
                        className="avatar-xs rounded-circle me-2"
                    />}
                <div>
                    <Typography
                        href="#javascript: void(0);"
                        className={classes.TableCellMainText}
                    >
                        {props.item.customer ? `${props.item.customer?.first_name} ${props.item.customer?.last_name}` : null}
                    </Typography>
                    <span className={classes.SomeOtheText}>{props.item.customer?.designation}</span>
                </div>
            </div></TableCell>
            <TableCell style={{ minWidth: 235 }}>
                <Typography
                    href="#javascript: void(0);"
                    className={classes.TableCellMainText}
                >
                    {props.item.action}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography
                    href="#javascript: void(0);"
                    className={classes.TableCellMainText}
                >
                    {props.item.channel}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography
                    href="#javascript: void(0);"
                    className={classes.TableCellMainText}
                >
                    {props.item.ip_address}
                </Typography>
            </TableCell>
        </TableRow>
    );
}

export default AuditTrialListItem;