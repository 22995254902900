import moment from "moment";
import { authApi, getAuthorizationHeader } from "../../api/api";
import { AUDIT_TRIAL_LIST_FAIL, AUDIT_TRIAL_LIST_REQUEST, AUDIT_TRIAL_LIST_SUCCESS } from "../constants/auditTrialConstants";

export const fetchAuditTrialList =
    (page, limit, search, filter, sort, state) => async (dispatch) => {
        try {
            dispatch({
                type: AUDIT_TRIAL_LIST_REQUEST,
            });
            console.log(state);
            const { data } = await authApi.post(
                `/users/user-audit-trial?start_date=${moment(state[0].startDate).format("YYYY-MM-DD")}&end_date=${moment(state[0].endDate).format("YYYY-MM-DD")}&page=${page}&limit=${limit}&search=${search}&sort=${sort}`, filter,
                {
                    headers: { Authorization: getAuthorizationHeader() },
                }
            );

            console.log(data.support_tickets);

            dispatch({
                type: AUDIT_TRIAL_LIST_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: AUDIT_TRIAL_LIST_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
        }
    };