import React, { useState } from "react";
import classes from "./profile.module.css"
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { Paper, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { authApi, getAuthorizationHeader } from "../../../api/api";
import Cookies from "js-cookie";
import { CHANGE_PASSWORD } from "../../../api/api-urls";
import { BrudCrumbBackIcon, BrudCrumbNextIcon, CloseEyeIcon, OpenEyeIcon } from "../../../Components/Icons/Icons";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    height: "200px",
    width: "99%",
}));

const ChangePassword = () => {
    const navigate = useNavigate();
    const [oldPassword, setOldPassword] = useState();
    const [newPassword, setNewPassword] = useState();
    const [conformNewPassword, setConformNewPassword] = useState();
    const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [passMatch, SetPassMatch] = useState();
    const [oldPassNewPassMatch, SetOldPassNewPassMatch] = useState();
    const [errorMessaage, setErrorMessage] = useState(null);

    const handleSubmit = async () => {
        const userEntry = {
            user_id: userInfo._id,
            oldPassword: oldPassword,
            newPassword: newPassword,
            confirmPassword: conformNewPassword,
        };
        console.log(userEntry);
        const { data } = await authApi.post(CHANGE_PASSWORD, userEntry, {
            headers: { Authorization: getAuthorizationHeader() },
        });
        if (data.error) {
            console.log(data);
            setErrorMessage(data.error);
        } else {
            sessionStorage.removeItem("userInfo");
            Cookies.remove("userToken")
            navigate("/login");
        }
    };

    function changePasswordFunction() {
        if (newPassword !== conformNewPassword) {
            SetPassMatch(true);
        } else {
            SetPassMatch(false);
        }

        if (
            oldPassword !== conformNewPassword &&
            newPassword === conformNewPassword
        ) {
            handleSubmit();
        } else if (oldPassword !== newPassword && oldPassword !== conformNewPassword) {
            SetOldPassNewPassMatch(true);
        } else {
            SetOldPassNewPassMatch(false);
        }
    }

    function oldPassFun(e) {
        setOldPassword(e.target.value);
        SetPassMatch(false);
        setErrorMessage(null);
        SetOldPassNewPassMatch(true);
    }
    function newPassFun(e) {
        setNewPassword(e.target.value);
        SetPassMatch(false);
        setErrorMessage(null);
        SetOldPassNewPassMatch(true);
    }
    function confirmPassFun(e) {
        setConformNewPassword(e.target.value);
        SetPassMatch(false);
        setErrorMessage(null);
        SetOldPassNewPassMatch(true);
    }

    return (
        <div>
            <div className={classes.brudCrumb}>
                <span onClick={() => navigate(-1)}><BrudCrumbBackIcon /></span>
                <span onClick={() => navigate("/my-profile")}>Profile</span>
                <BrudCrumbNextIcon />
                <span>Reset Password</span>
            </div>
            <Grid item xs={12} className={`mt-3`}>
                <Item
                    sx={{
                        height: "600px",
                        display: "grid",
                        padding: "0",
                        position: "relative",
                    }}
                >
                    <div style={{ padding: "20px 30px" }}>
                        <div className={"Comapny_headerContainer"}>
                            <Typography className={`mb-4 ${classes.MainHeader}`}>Reset Password</Typography>
                        </div>

                        <ValidatorForm
                            useref="form"
                            fullwidth
                            onSubmit={changePasswordFunction}
                            style={{ margin: "0px auto 30px" }}
                            className={"InputForm"}
                        >
                            <div className={classes.passwordInputContainer}>
                                <Typography for="firstNameinput" className={`mb-1 ${classes.form_label}`}>
                                    Current Password
                                </Typography>
                                <TextValidator

                                    sx={{
                                        "& legend": { display: "none" },
                                        "& fieldset": { top: 0 },
                                        width: "344px",
                                    }}
                                    id="outlined-adornment-password"
                                    type={showPassword ? "text" : "password"}
                                    fullWidth
                                    size="small"
                                    required
                                    value={oldPassword}
                                    onChange={(e) => oldPassFun(e)}
                                    validators={[
                                        "required",
                                    ]}
                                    errorMessages={[
                                        "Old password is required",
                                    ]}
                                />
                                <div className={classes.showPassword12}>
                                    {showPassword ? (
                                        <Button
                                            variant="text"
                                            onClick={() => setShowPassword(false)}
                                        >
                                            <OpenEyeIcon />
                                        </Button>
                                    ) : (
                                        <Button onClick={() => setShowPassword(true)}>
                                            <CloseEyeIcon />
                                        </Button>
                                    )}
                                </div>
                            </div>
                            <div className={classes.passwordInputContainer}>
                                <Typography for="firstNameinput" className={`mb-1 mt-4 ${classes.form_label}`}>
                                    New Password
                                </Typography>
                                <TextValidator
                                    sx={{
                                        "& legend": { display: "none" },
                                        "& fieldset": { top: 0 },
                                        width: "344px",
                                    }}
                                    id="outlined-adornment-password"
                                    type={showPassword1 ? "text" : "password"}
                                    fullWidth
                                    size="small"
                                    value={newPassword}
                                    onChange={(e) => newPassFun(e)}
                                    validators={[
                                        "required",
                                        "matchRegexp:^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
                                    ]}
                                    errorMessages={[
                                        "Password is required",
                                        "password should contain 1 capital letter, 1 small letter, 1 number, 1 special characters  and should be 8 digits",
                                    ]}
                                />
                                <div className={classes.showPassword12}>
                                    {showPassword1 ? (
                                        <Button
                                            variant="text"
                                            onClick={() => setShowPassword1(false)}
                                        >
                                            <OpenEyeIcon />
                                        </Button>
                                    ) : (
                                        <Button onClick={() => setShowPassword1(true)}>
                                            <CloseEyeIcon />
                                        </Button>
                                    )}
                                </div>
                            </div>
                            <div className={classes.passwordInputContainer}>
                                <Typography for="firstNameinput" className={`mb-1 mt-4 ${classes.form_label}`}>
                                    Confirm Password
                                </Typography>
                                <TextValidator
                                    sx={{
                                        "& legend": { display: "none" },
                                        "& fieldset": { top: 0 },
                                        width: "344px",
                                    }}
                                    id="outlined-adornment-password"
                                    type={showPassword2 ? "text" : "password"}
                                    fullWidth
                                    size="small"
                                    value={conformNewPassword}
                                    onChange={(e) => confirmPassFun(e)}
                                    validators={[
                                        "required",
                                        "matchRegexp:^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
                                    ]}
                                    errorMessages={[
                                        "Confirm password is required",
                                        "password should contain 1 capital letter, 1 small letter, 1 number, 1 special characters  and should be 8 digits",
                                    ]}
                                />
                                {passMatch === true ? (
                                    <p style={{ color: "red" }}>
                                        Password and confirm password not matches
                                    </p>
                                ) : null}
                                {oldPassNewPassMatch === false ? (
                                    <p style={{ color: "red" }}>
                                        Old password and new password should not be same
                                    </p>
                                ) : null}
                                {errorMessaage ? (
                                    <p style={{ color: "red" }}>{errorMessaage}</p>
                                ) : null}
                                <div className={classes.showPassword12}>
                                    {showPassword2 ? (
                                        <Button
                                            variant="text"
                                            onClick={() => setShowPassword2(false)}
                                        >
                                            <OpenEyeIcon />
                                        </Button>
                                    ) : (
                                        <Button onClick={() => setShowPassword2(true)}>
                                            <CloseEyeIcon />
                                        </Button>
                                    )}
                                </div>
                            </div>
                            <div>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    className={`mt-5 ${classes.ResetPasswordButton}`}
                                >
                                    Change Password
                                </Button>
                            </div>
                        </ValidatorForm>
                    </div>
                </Item>
            </Grid>
        </div>
    );
};

export default ChangePassword;
