import { AUDIT_TRIAL_LIST_FAIL, AUDIT_TRIAL_LIST_REQUEST, AUDIT_TRIAL_LIST_SUCCESS } from "../constants/auditTrialConstants";

const initialState = {
    loading: true,
    audit_trial: [],
    error: null,
    totalCount: 0,
};

export const auditTrialReducers = (state = initialState, action) => {
    switch (action.type) {
        case AUDIT_TRIAL_LIST_REQUEST:
            return { ...state, loading: true };

        case AUDIT_TRIAL_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                audit_trial: action.payload.activities,
                totalCount: action.payload.totalCount,
                error: null,
            };
        case AUDIT_TRIAL_LIST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};