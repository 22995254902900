import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import classes from "./referral.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getRefferalDetails } from "../../../Redux/actions/customerActions";
import { useEffect } from "react";
import { OppositeSortArrawBtnIcons, SortArrawBtnIcons } from "../../../Components/Icons/Icons";
import InvoiceFilter from "../../../Components/InvoiceFilters";
import Pagination from "../../../Components/Pagination";
import ReferralsListItems from "./ReferralsListItems";
import { handleSortClick } from "../../../Utilities/commonfunctions";

const Referrals = () => {
  const dispatch = useDispatch();
  //const [page, setPage] = useState(currentPage);
  //const [limit, setLimit] = useState(Number(rowCount));
  const PaginationData = useSelector(
    (state) => state.paginationData.Pagination
  );
  const { rowCount, currentPage } = PaginationData || {};
  const { sortBy } = useSelector((state) => state.filter);
  const { referals, error, loading, totalCount } = useSelector((state) => state.customerData)
  const { invoice } = useSelector((state) => state.filter) || {};

  useEffect(() => {
    const filterArray = {
      active: invoice,
    };
    dispatch(getRefferalDetails(rowCount, currentPage, filterArray, sortBy));
  }, [rowCount, currentPage, invoice, sortBy, dispatch]);

  return (
    <div>
      <div className={classes.tableContainer}>
        <Table
          style={{
            marginTop: "20px",
          }}
          className={classes.MainTable}
        >
          <TableHead>
            <TableRow className={classes.tableHeadRow}>
              <TableCell />
              <TableCell className={classes.tableHeadCell}>
                <div>
                  <span> Customer </span>
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "company_details.company_name")
                    }
                  >
                    {sortBy?.includes("desc") &&
                      sortBy?.includes("company_details.company_name") ? (
                      <OppositeSortArrawBtnIcons />
                    ) : (
                      <SortArrawBtnIcons />
                    )}
                  </span>
                </div>
              </TableCell>{" "}
              <TableCell className={classes.tableHeadCell}>
                <div className={classes.tableHEadercelldiv}>
                  <span> Contact Person </span>
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "first_name")
                    }
                  >
                    {sortBy?.includes("desc") &&
                      sortBy?.includes("first_name") ? (
                      <OppositeSortArrawBtnIcons />
                    ) : (
                      <SortArrawBtnIcons />
                    )}
                  </span>
                </div>
              </TableCell>{" "}
              <TableCell className={classes.tableHeadCell}>
                <div className={classes.tableHEadercelldiv}>
                  <span> Referral Code </span>{" "}
                  {/* <FilterMenu
                    option={Industries}
                    name={CustomerIndustyFilter}
                  />{" "} */}
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "my_referral_code")
                    }
                  >
                    {sortBy?.includes("desc") &&
                      sortBy?.includes("my_referral_code") ? (
                      <OppositeSortArrawBtnIcons />
                    ) : (
                      <SortArrawBtnIcons />
                    )}
                  </span>
                </div>
              </TableCell>{" "}
              <TableCell className={classes.tableHeadCell}>
                <span> Mobile Number </span>{" "}
              </TableCell>{" "}
              <TableCell className={classes.tableHeadCell}>
                <span> Total Subscriptions </span>
              </TableCell>{" "}
              <TableCell className={classes.tableHeadCell}>
                <div className={classes.tableHEadercelldiv}>
                  <span> Status </span>
                  <InvoiceFilter />
                </div>
              </TableCell>{" "}
            </TableRow>{" "}
          </TableHead>{" "}
        </Table>
        <Table>
          <TableBody className={`list form-check-all ${classes.MainTable}`}>
            {" "}
            {/* loop customer.data is > 0 */}{" "}
            {loading ? (
              <TableRow>
                <TableCell colSpan="9" className="text-center">
                  Loading...
                </TableCell>{" "}
              </TableRow>
            ) : error ? (
              <TableRow>
                <TableCell colSpan="9" className="text-center">
                  {" "}
                  {error}{" "}
                </TableCell>{" "}
              </TableRow>
            ) : referals && referals.length > 0 ? (
              referals
                .map((item, index) => (
                  <ReferralsListItems key={index} referral={item} />
                ))
            ) : (
              <TableRow>
                <TableCell colSpan="9" className="text-center">
                  No Data Found{" "}
                </TableCell>{" "}
              </TableRow>
            )}{" "}
          </TableBody>{" "}
        </Table>{" "}
      </div >
      <Pagination total={totalCount} />
    </div >
  );
};

export default Referrals;
