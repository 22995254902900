import React from 'react'
import classes from './customer.module.css'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { Paper, Typography, Button } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { createCustomer } from '../../../Redux/actions/customerActions'
import SuccessModal from './successModal'
import { useDispatch } from 'react-redux'
import { Alphabetic, Numeric } from '../../../Utilities/commonfunctions'
import { BrudCrumbBackIcon, BrudCrumbNextIcon } from '../../../Components/Icons/Icons'

const InviteNewCustomer = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [designation, setDesignation] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [email, setEmail] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [moddleToggle, setModdleToggle] = useState(false);

    const handleSubmit = () => {
        let newCustomer = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            mobile_number: mobileNumber,
            company: companyName,
            designation: designation
        }
        dispatch(createCustomer(newCustomer));
        console.log(newCustomer);
        setModdleToggle(true);
    }

    const modalClose = () => {
        setModdleToggle(false);
        navigate('/customers');
    }

    return (
        <div>
            <div className={`${classes.brudCrumb} mb-4 `}>
                <span onClick={() => navigate(-1)}>
                    <BrudCrumbBackIcon />
                </span>
                <span
                    onClick={() => navigate("/customers")}
                    className={classes.form_lable}
                    style={{ color: "#0A1F41" }}
                >
                    Customer
                </span>
                <BrudCrumbNextIcon />
                <span className={classes.form_lable}>Invite Customer</span>
            </div>
            <Paper style={{ padding: 30 }}>
                <Typography className={`mb-4 ${classes.inviteCustomerMainHeading}`}>Invite Customer</Typography>
                <ValidatorForm
                    useref="form"
                    onSubmit={handleSubmit}
                    style={{ width: "100%" }}
                    className={classes.InputForm}
                >
                    <div className={classes.FormInputsContainer}>
                        <div>
                            <TextValidator
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                }}
                                placeholder="First Name"
                                id="address1ControlTextarea"
                                onKeyPress={(e) => Alphabetic(e)}
                                value={firstName}
                                fullWidth
                                onChange={(e) => setFirstName(e.target.value)}
                                size="small"
                                validators={["required"]}
                                errorMessages={["This Feild is required"]}
                            />
                        </div>
                        <div>
                            <TextValidator
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                }}
                                placeholder="Last Name"
                                id="address1ControlTextarea"
                                value={lastName}
                                fullWidth
                                onKeyPress={(e) => Alphabetic(e)}
                                onChange={(e) => setLastName(e.target.value)}
                                size="small"
                                validators={["required"]}
                                errorMessages={["This Feild is required"]}
                            />
                        </div>
                        <div>
                            <TextValidator
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                }}
                                placeholder="Designation(optional)"
                                id="address1ControlTextarea"
                                value={designation}
                                fullWidth
                                onChange={(e) => setDesignation(e.target.value)}
                                size="small"
                            />
                        </div>
                        <div>
                            <TextValidator
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                    marginBottom: "15px",
                                }}
                                id="emailidInput"
                                placeholder='Mobile Number'
                                fullWidth
                                inputProps={{
                                    maxLength: 10,
                                }}
                                onKeyPress={(e) => Numeric(e)}
                                value={mobileNumber}
                                onChange={(e) => {
                                    setMobileNumber(e.target.value);
                                }}
                                size="small"
                                validators={["required", "matchRegexp:[0-9]{10}"]}
                                errorMessages={[
                                    "This field is required",
                                    "Please Enter the 10 digit mobile number",
                                ]}
                            />
                        </div>
                        <div>
                            <TextValidator
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                }}
                                id="firstNameinput"
                                fullWidth
                                placeholder="Email ID(optional)"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                size="small"
                                validators={["isEmail"]}
                                errorMessages={[
                                    "This is not a valid Email",
                                ]}
                            />
                        </div>
                        <div>
                            <TextValidator
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                }}
                                placeholder="Company Name"
                                id="address1ControlTextarea"
                                value={companyName}
                                fullWidth
                                onChange={(e) => setCompanyName(e.target.value)}
                                size="small"
                                validators={["required"]}
                                errorMessages={["This Feild is required"]}
                            />
                        </div>
                    </div>
                    <div className={classes.InviteCustmerBtnSection}>
                        <Button
                            variant="outlined"
                            onClick={() => navigate("/subscription-management")}
                            className={classes.invitePAgeMainBtn}
                            style={{ border: "3px solid #1976d2" }}
                        >
                            Cancel
                        </Button>
                        <Button variant="contained" type="submit" className={classes.invitePAgeMainBtn}>
                            Submit
                        </Button>
                    </div>
                </ValidatorForm>
            </Paper>
            {moddleToggle ? <SuccessModal modalClose={modalClose} /> : null}
        </div>
    )
}

export default InviteNewCustomer