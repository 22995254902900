import { authApi, getAuthorizationHeader } from "../../api/api";
import { FEEDBACK_LIST_FAIL, FEEDBACK_LIST_SUCCESS, FEEDBACK_REQUEST } from "../constants/feedbackConstants";

export const fetchFeedbacks =
    (page, limit, user_type, sort) => async (dispatch) => {
        try {
            dispatch({
                type: FEEDBACK_REQUEST,
            });

            const { data } = await authApi.get(
                `/feedback/list?page=${page}&limit=${limit}&user_type=${user_type}&sort=${sort}`,
                {
                    headers: { Authorization: getAuthorizationHeader() },
                }
            );

            dispatch({
                type: FEEDBACK_LIST_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: FEEDBACK_LIST_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
        }
    };