import { ACTIVE_CUSTOMERS_REPORT_LIST_FAIL, ACTIVE_CUSTOMERS_REPORT_LIST_REQUEST, ACTIVE_CUSTOMERS_REPORT_LIST_SUCCESS } from "../constants/reportConstants";

const initialState = {
    loading: true,
    reportList: [],
    error: null,
    totalCount: 0,
};

export const reportReducers = (state = initialState, action) => {
    switch (action.type) {
        case ACTIVE_CUSTOMERS_REPORT_LIST_REQUEST:
            return { ...state, loading: true };

        case ACTIVE_CUSTOMERS_REPORT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                reportList: action.payload.data,
                totalCount: action.payload.totalCount,
                error: null,
            };
        case ACTIVE_CUSTOMERS_REPORT_LIST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};