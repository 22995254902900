module.exports = {
  LIST_BLOGS_REQUEST: "LIST_BLOGS_REQUEST",
  LIST_BLOGS_SUCCESS: "LIST_BLOGS_SUCCESS",
  LIST_BLOGS_FAIL: "LIST_BLOGS_FAIL",

  BLOGS_TAGS_LIST_REQUEST: "BLOGS_TAGS_LIST_REQUEST",
  BLOGS_TAGS_LIST_SUCCESS: "BLOGS_TAGS_LIST_SUCCESS",
  BLOGS_TAGS_LIST_FAIL: "BLOGS_TAGS_LIST_FAIL",

  BLOGS_CREATED_REQUEST: "BLOGS_CREATED_REQUEST",
  BLOGS_CREATED_SUCCESS: "BLOGS_CREATED_SUCCESS",
  BLOGS_CREATED_FAIL: "BLOGS_CREATED_FAIL",

  BLOGS_ONE_SUCCESS: "BLOGS_ONE_SUCCESS",
};
