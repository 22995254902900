import { NOTIFICATION_LIST_FAIL, NOTIFICATION_LIST_REQUEST, NOTIFICATION_LIST_SUCCESS } from "../constants/notificationConstants";

const initialState = {
    loading: true,
    notificationList: [],
    error: null,
};

export const notificationReducers = (state = initialState, action) => {
    switch (action.type) {
        case NOTIFICATION_LIST_REQUEST:
            return { ...state, loading: true };
        case NOTIFICATION_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                notificationList: action.payload,
                error: null,
            };
        case NOTIFICATION_LIST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};