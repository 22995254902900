import { authApi, getAuthorizationHeader } from "../../api/api";
import { CONTACT_US_LIST_FAIL, CONTACT_US_LIST_SUCCESS, CONTACT_US_REQUEST } from "../constants/contactUsContants";

export const getContactUsList =
    (page, limit, sort) => async (dispatch) => {
        try {
            dispatch({
                type: CONTACT_US_REQUEST,
            });

            const { data } = await authApi.get(
                `/contact-us/list?page=${page}&limit=${limit}&sort=${sort}`,
                {
                    headers: { Authorization: getAuthorizationHeader() },
                }
            );
            console.log(data);
            dispatch({
                type: CONTACT_US_LIST_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: CONTACT_US_LIST_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
        }
    };