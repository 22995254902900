import React from "react";
import classes from "./customer.module.css";
import { Box, Tabs, Tab } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  BrudCrumbBackIcon,
  BrudCrumbNextIcon,
} from "../../../Components/Icons/Icons";
import { useEffect } from "react";
import { useState } from "react";
import { fetchCustomer } from "../../../Redux/actions/customerActions";
import CustomerCard from "./CustomerCard";
import CompanyCard from "./CompanyCard";
import Subscription from "./subscription/Subscription";
import TeamList from "./Teams/TeamsList";
import AuditTrial from "./AuditTrial/AuditTrial";
import TimeLine from "./TimeLine/TimeLine";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CustomerProfileIndex = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [id, setId] = useState();

  useEffect(() => {
    const path = window.location.pathname;
    const arr = path.split("/");
    console.log(arr);
    setId(arr[arr.length - 1]);
    dispatch(fetchCustomer(arr[arr.length - 1]));
  }, [dispatch]);

  const customerData = useSelector((state) => state.customerData.customer);

  const { first_name, last_name } = customerData || {};

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <div className={classes.brudCrumb}>
        <span onClick={() => navigate(-1)}>
          <BrudCrumbBackIcon />
        </span>
        <span onClick={() => navigate("/customers")}>Customer</span>
        <BrudCrumbNextIcon />
        <span>{`${first_name} ${last_name}`}</span>
      </div>
      <div className={`mb-3 mt-4 ${classes.customerProfileCardContainer}`}>
        <CustomerCard />
        <CompanyCard />
      </div>
      {id ? (
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              className={classes.HeadTabButton}
              aria-label="basic tabs example"
            >
              <Tab
                label={"Subscriptions"}
                {...a11yProps(0)}
                style={{ textTransform: "capitalize" }}
              />
              <Tab
                label={"Teams"}
                {...a11yProps(1)}
                style={{ textTransform: "capitalize" }}
              />
              <Tab
                label={"Audit Trail"}
                {...a11yProps(2)}
                style={{ textTransform: "capitalize" }}
              />
              <Tab
                label={"Timeline"}
                {...a11yProps(3)}
                style={{ textTransform: "capitalize" }}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0} className={classes.TabPanel1}>
            <Subscription id={id} />
          </TabPanel>
          <TabPanel value={value} index={1} className={classes.TabPanel}>
            <TeamList id={id} />
          </TabPanel>
          <TabPanel
            value={value}
            index={2}
            className={classes.TabPanel}
          >
            <AuditTrial id={id} />
          </TabPanel>
          <TabPanel
            value={value}
            index={3}
            className={classes.TabPanel}
          >
            <TimeLine id={id} />
          </TabPanel>
        </Box>
      ) : null}
    </div>
  );
};

export default CustomerProfileIndex;
