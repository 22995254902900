import {
  COMPANY_NAME_FAILURE,
  COMPANY_NAME_REQUEST,
  COMPANY_NAME_SUCCESS,
  CONTACT_PERSON_FAILURE,
  CONTACT_PERSON_REQUEST,
  CONTACT_PERSON_SUCCESS,
  CUSTOMER_DESIGNATION_FAILURE,
  CUSTOMER_DESIGNATION_SUCCESS,
  CUSTOMER_INDUSTRY_FAILURE,
  CUSTOMER_INDUSTRY_REQUEST,
  CUSTOMER_INDUSTRY_SUCCESS,
  CUSTOMER_SUBS_FILTER_FAILURE,
  CUSTOMER_SUBS_FILTER_REQUEST,
  CUSTOMER_SUBS_FILTER_SUCCESS,
  DURATION_FAILURE,
  DURATION_REQUEST,
  DURATION_SUCCESS,
  INVOICE_FILTER_FAILURE,
  INVOICE_FILTER_REQUEST,
  INVOICE_FILTER_SUCCESS,
  PLAN_NAME_FAILURE,
  PLAN_NAME_REQUEST,
  PLAN_NAME_SUCCESS,
  SORT_NAME,
  TEAM_NAME_FAILURE,
  TEAM_NAME_REQUEST,
  TEAM_NAME_SUCCESS,
} from "../constants/filterConstatns";

export const InvoiceFilterAction = (is_active) => async (dispatch) => {
  try {
    dispatch({
      type: INVOICE_FILTER_REQUEST,
    });

    dispatch({
      type: INVOICE_FILTER_SUCCESS,
      payload: is_active,
    });
  } catch (error) {
    dispatch({
      type: INVOICE_FILTER_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const CustomerIndustyFilter = (industries) => async (dispatch) => {
  try {
    dispatch({
      type: CUSTOMER_INDUSTRY_REQUEST,
    });

    const data = industries;

    dispatch({
      type: CUSTOMER_INDUSTRY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CUSTOMER_INDUSTRY_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const CustomerDesignationFilter = (designation) => async (dispatch) => {
  try {
    dispatch({
      type: CUSTOMER_INDUSTRY_REQUEST,
    });

    const data = designation;

    dispatch({
      type: CUSTOMER_DESIGNATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CUSTOMER_DESIGNATION_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const CompanyNameFilter = (companynames) => async (dispatch) => {
  try {
    dispatch({
      type: COMPANY_NAME_REQUEST,
    });

    const data = companynames;

    dispatch({
      type: COMPANY_NAME_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COMPANY_NAME_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}

export const ContactPersonFilter = (contactpersons) => async (dispatch) => {
  try {
    dispatch({
      type: CONTACT_PERSON_REQUEST,
    });

    const data = contactpersons;

    dispatch({
      type: CONTACT_PERSON_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONTACT_PERSON_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}

export const setSortName = (sort) => async (dispatch) => {
  try {
    dispatch({
      type: SORT_NAME,
      payload: sort,
    });
  } catch (error) {
    dispatch({
      type: SORT_NAME,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}

export const teamNameFilter = (names) => async (dispatch) => {
  try {
    dispatch({
      type: TEAM_NAME_REQUEST,
    });

    const data = names;

    dispatch({
      type: TEAM_NAME_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TEAM_NAME_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}

export const durationFilter = (names) => async (dispatch) => {
  try {
    dispatch({
      type: DURATION_REQUEST,
    });

    const data = names;

    dispatch({
      type: DURATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DURATION_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}

export const planNameFilter = (names) => async (dispatch) => {
  try {
    dispatch({
      type: PLAN_NAME_REQUEST,
    });

    const data = names;

    dispatch({
      type: PLAN_NAME_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PLAN_NAME_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}

export const subscriptionFilter = (names) => async (dispatch) => {
  try {
    dispatch({
      type: CUSTOMER_SUBS_FILTER_REQUEST,
    });

    const data = names;

    dispatch({
      type: CUSTOMER_SUBS_FILTER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CUSTOMER_SUBS_FILTER_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}