import { Outlet } from "react-router-dom";
import classes from "../index.module.css";
import Header from "../Header";

function SpecialLayout() {
  return (
    <div>
      <div>
        <Header />
      </div>
      <div className={classes.content}>
        <div className={classes.scrollableOutlet}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default SpecialLayout;
