import React, { useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { MdModeEditOutline } from "react-icons/md";
import InputAdornment from "@mui/material/InputAdornment";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Paper } from "@mui/material";
import { HiOutlinePhone } from "react-icons/hi";
import { MdOutlineEmail } from "react-icons/md";
import { BsGenderAmbiguous } from "react-icons/bs";
import {
  Avatar,
  Badge,
  Typography,
  MenuItem,
  Button,
  Menu,
} from "@mui/material";
import classes from "./profile.module.css";
import {
  GetProfileDetails,
  UpdateUser,
} from "../../../Redux/actions/userActions";
import cameraimage from "../../../Assets/images/camera.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  BrudCrumbBackIcon,
  DeleteBinIcons,
  EditPenIcon,
} from "../../../Components/Icons/Icons";
import {
  Alphabetic,
  Numeric,
  generateColor,
  stringAvatar,
} from "../../../Utilities/commonfunctions";
import SuccessPopup from "./successPopup";
import { authImageApi, getAuthorizationHeader } from "../../../api/api";
import { UPLOAD_IMAGE } from "../../../api/api-urls";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  height: "200px",
}));
const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 35,
  height: 35,
  border: `2px solid ${theme.palette.background.paper}`,
  background: "#227DC3",
}));

const MyProfile = () => {
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [height, setHeight] = useState(270);
  const [visibility, setVisibility] = useState(true);
  const [name, setName] = useState();
  const [mobile, setMobile] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [dbprofilePic, setDbprofilePic] = useState();
  const [profilePic, setProfilePic] = useState();
  const [email, setEmail] = useState();
  const [type, setType] = useState();
  const [gender, setGender] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const loggedUser = useSelector((state) => state.userLogin.loggedUser);
  const Gender = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "other", label: "Other" },
  ];
  const [successModal, setSuccessModal] = useState(false);

  const closeModal = () => {
    setSuccessModal(false);
    setVisibility(true);
    setHeight(270);
  };

  const handleEdit = () => {
    setVisibility((item) => !item);
    if (visibility) {
      setHeight(430);
    } else {
      setHeight(270);
      dispatch(GetProfileDetails());
    }
  };

  const CreateimageArray = async () => {
    let formData = new FormData();
    formData.append("file", selectedFile);
    const { data } = await authImageApi.post(UPLOAD_IMAGE, formData, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    return data.data;
  };
  const handleSubmit = async () => {
    if (selectedFile) {
      var Buildimages = await CreateimageArray();
    }
    try {
      const userEntry = {
        name: name,
        phone: `${mobile}`,
        email: email,
        gender: gender,
        profile_pic: Buildimages || dbprofilePic,
      };
      await dispatch(UpdateUser(userEntry, loggedUser._id));
      setSuccessModal(true);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    dispatch(GetProfileDetails());
  }, [dispatch]);

  const openFileSelector = () => {
    inputRef.current?.click();
  };

  const handleMenuclick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFileChange = async (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    console.log("fileObj is", fileObj);
    const objectURL = URL.createObjectURL(fileObj);
    console.log(objectURL);
    setProfilePic(objectURL);
    setSelectedFile(fileObj);
  };

  const handleMenuItemClick = async (event, name) => {
    event.stopPropagation();
    if (name === "edit") {
      openFileSelector();
      handleClose();
    } else if (name === "delete") {
      console.log("Comes Here");
      setDbprofilePic({});
      setProfilePic();
      setSelectedFile();
      handleClose();
      // const { data } = await authApi.delete(`${TEAM_LIST}/${selected._id}`, {
      //   headers: { Authorization: getAuthorizationHeader() },
      // });
      // console.log(data);
      // getData();
    }
  };
  useEffect(() => {
    if (loggedUser) {
      //let Mobile = loggedUser.phone?.split(" ");
      console.log(loggedUser);
      setEmail(loggedUser.email);
      setName(loggedUser.name);
      setMobile(loggedUser.phone);
      setGender(loggedUser.gender);
      setType(loggedUser.user_type);
      if (loggedUser.profile_pic) {
        setDbprofilePic(loggedUser.profile_pic);
        setProfilePic(
          `${process.env.REACT_APP_IMAGE_URL}/${loggedUser.profile_pic.destination}${loggedUser.profile_pic.filename}`
        );
      }
    }
  }, [loggedUser]);

  return (
    <div>
      <div className={classes.brudCrumb}>
        <span onClick={() => navigate(-1)}>
          <BrudCrumbBackIcon />
        </span>
        <span onClick={() => navigate("/my-profile")}>Profile</span>
      </div>
      <div
        className={`mt-3`}
        style={{
          width: "98%",
        }}
      >
        <div>
          <Grid item xs={12}>
            <Item
              sx={{
                height: { height },
                display: "grid",
                padding: "0",
                position: "relative",
              }}
            >
              <div
                style={{ backgroundColor: "#EAF6FF", height: "125px" }}
              ></div>
              <div>
                <div
                  style={{
                    position: "absolute",
                    top: "63px",
                    left: "4%",
                  }}
                >
                  <input
                    style={{ display: "none" }}
                    ref={inputRef}
                    type="file"
                    onChange={handleFileChange}
                  />
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    badgeContent={
                      <SmallAvatar alt="Remy Sharp">
                        {visibility ? (
                          <span onClick={() => handleEdit()}>
                            <MdModeEditOutline />
                          </span>
                        ) : (
                          <span onClick={(e) => handleMenuclick(e)}>
                            <img
                              src={cameraimage}
                              style={{ position: "absolute", left: 7, top: 7 }}
                              alt=""
                            />
                          </span>
                        )}
                      </SmallAvatar>
                    }
                  >
                    {profilePic ? (
                      <Avatar
                        alt="Travis Howard"
                        src={profilePic}
                        sx={{
                          width: "120px",
                          height: "120px",
                        }}
                      />
                    ) : loggedUser ? (
                      <Avatar
                        {...stringAvatar(`${loggedUser.name}`)}
                        sx={{
                          bgcolor: `${generateColor(`${loggedUser.name}`)}`,
                          width: "120px",
                          height: "120px",
                        }}
                      />
                    ) : null}
                  </Badge>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem
                      key={`edit1`}
                      className={classes.EditMenuTeam}
                      onClick={(e) => handleMenuItemClick(e, "edit")}
                      style={{ paddingLeft: 22 }}
                    >
                      <EditPenIcon />
                      <span style={{ marginLeft: 5 }}>Edit Image</span>
                    </MenuItem>
                    <MenuItem
                      key={`delete2`}
                      onClick={(e) => handleMenuItemClick(e, "delete")}
                    >
                      <DeleteBinIcons />
                      <span>Delete Image</span>
                    </MenuItem>
                  </Menu>
                  {visibility ? (
                    <div>
                      <Typography className={classes.ProfileNameMain}>
                        {name}
                      </Typography>
                      <Typography className={classes.ProfileNameSub}>
                        {type}
                      </Typography>
                    </div>
                  ) : null}
                </div>
                {!visibility ? (
                  <ValidatorForm
                    useref="form"
                    fullwidth
                    onSubmit={handleSubmit}
                    style={{ margin: "30px auto", width: "95%" }}
                    className="scrollbaroverflow"
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <label for="firstNameinput" className="form-label">
                          Name
                        </label>
                        <TextValidator
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                            width: "90%",
                          }}
                          id="Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          onKeyPress={(e) => Alphabetic(e)}
                          size="small"
                          validators={["required"]}
                          errorMessages={["Company Name is required"]}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <label for="phonenumberInput" className="form-label">
                          Mobile Number
                        </label>
                        <TextValidator
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                            width: "90%",
                          }}
                          inputProps={{
                            maxLength: 10,
                          }}
                          id="phonenumberInput"
                          value={mobile}
                          onChange={(e) => setMobile(e.target.value)}
                          onKeyPress={(e) => Numeric(e)}
                          size="small"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                +91
                              </InputAdornment>
                            ),
                          }}
                          validators={["required", "matchRegexp:[0-9]{10}"]}
                          errorMessages={[
                            "This field is required",
                            "Please Enter the 10 digit mobile number",
                          ]}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <label for="firstNameinput" className="form-label">
                          Email ID
                        </label>
                        <TextValidator
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                            width: "90%",
                          }}
                          id="firstNameinput"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          size="small"
                          validators={["required", "isEmail"]}
                          errorMessages={[
                            "Customer Type is required",
                            "This is not a valid Email",
                          ]}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <label for="firstNameinput" className="form-label">
                          Gender
                        </label>
                        <TextValidator
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                            width: "90%",
                          }}
                          select
                          size="small"
                          value={gender}
                          onChange={(e) => setGender(e.target.value)}
                          required={true}
                        >
                          {Gender.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextValidator>
                      </Grid>
                    </Grid>
                    <div className={classes.EditProfileBtnSection}>
                      <Button
                        variant="outlined"
                        style={{ padding: "5px 30px", textTransform: "none" }}
                        onClick={() => handleEdit()}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        type="submit"
                        style={{ padding: "5px 30px", textTransform: "none" }}
                      >
                        Save
                      </Button>
                    </div>
                  </ValidatorForm>
                ) : null}
              </div>
            </Item>
          </Grid>
        </div>
        {visibility ? (
          <div style={{ margin: "15px 0" }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Item
                  sx={{
                    padding: "20px",
                    display: "grid",
                    alignContent: "space-evenly",
                  }}
                >
                  <Avatar
                    alt="Travis Howard"
                    sx={{
                      width: "50px",
                      height: "50px",
                      background: "#227DC3",
                    }}
                  >
                    <HiOutlinePhone />
                  </Avatar>
                  <Typography className={classes.containerLable}>
                    Mobile Number:
                  </Typography>
                  <Typography
                    className={`${classes.containerLable} ${classes.containerValue}`}
                  >
                    +91 {mobile}
                  </Typography>
                </Item>
              </Grid>
              <Grid item xs={4}>
                <Item
                  sx={{
                    padding: "20px",
                    display: "grid",
                    alignContent: "space-evenly",
                  }}
                >
                  <Avatar
                    alt="Travis Howard"
                    sx={{
                      width: "50px",
                      height: "50px",
                      background: "#227DC3",
                    }}
                  >
                    <MdOutlineEmail />
                  </Avatar>
                  <Typography className={classes.containerLable}>
                    Email ID:
                  </Typography>
                  <Typography
                    className={`${classes.containerLable} ${classes.containerValue}`}
                  >
                    {email}
                  </Typography>
                </Item>
              </Grid>
              <Grid item xs={4}>
                <Item
                  sx={{
                    padding: "20px",
                    display: "grid",
                    alignContent: "space-evenly",
                  }}
                >
                  <Avatar
                    alt="Travis Howard"
                    sx={{
                      width: "50px",
                      height: "50px",
                      background: "#227DC3",
                    }}
                  >
                    <BsGenderAmbiguous />
                  </Avatar>
                  <Typography className={classes.containerLable}>
                    Gender:
                  </Typography>
                  <Typography
                    className={`${classes.containerLable} ${classes.containerValue}`}
                  >
                    {gender}
                  </Typography>
                </Item>
              </Grid>
            </Grid>
          </div>
        ) : null}
      </div>
      {successModal ? <SuccessPopup modalClose={closeModal} /> : null}
    </div>
  );
};

export default MyProfile;
