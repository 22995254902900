import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  GENERATE_EMAIL_OTP_REQUEST,
  GENERATE_EMAIL_OTP_SUCCESS,
  GENERATE_EMAIL_OTP_FAILURE,
  VERIFY_EMAIL_OTP_REQUEST,
  VERIFY_EMAIL_OTP_SUCCESS,
  VERIFY_EMAIL_OTP_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  MY_PROFILE_REQUEST,
  MY_PROFILE_DATA,
  USER_UPDATE,
  SET_HEADER_NAME,
  TOGGLE_SIDEBAR,
  USER_MANAGEMENT_CREATE_REQUEST,
  USER_MANAGEMENT_CREATE_FAIL,
  USERS_MANAGEMENT_LIST_REQUEST,
  USERS_MANAGEMENT_LIST_SUCCESS,
  USERS_MANAGEMENT_LIST_FAIL,
} from "../constants/userConstants";
import { api, authApi, getAuthorizationHeader } from "../../api/api";
import Cookies from "js-cookie";

export const login = (email, password, rememberMe) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await api.post(
      "/users/login",
      { email, password },
      config
    );

    console.log(data);
    if (data.user_type !== "admin") {
      //return this user is not admin cant access
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: "This user is not admin can't access",
      });
      return;
    }

    // console.log("Datas OFfered Befrore Consition", rememberMe, data);
    if (rememberMe && data && data.token) {
      // console.log("Datas OFfered", rememberMe, data);
      Cookies.set("userToken", JSON.stringify(data), { expires: 7 });
      // localStorage.setItem('userToken', JSON.stringify(data.token)); // Expires in 7 days
    }

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });
    sessionStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const GetProfileDetails = () => async (dispatch) => {
  try {
    dispatch({
      type: MY_PROFILE_REQUEST,
    });

    const { data } = await authApi.get(`/users/profile`, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    console.log(data);

    dispatch({
      type: MY_PROFILE_DATA,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MY_PROFILE_DATA,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const UpdateUser = (user, id) => async (dispatch) => {
  dispatch({
    type: USER_UPDATE,
  });

  const { data } = await authApi.put(`/users/${id}`, user);
  console.log(data);

  dispatch(GetProfileDetails());
};
export const generateEmailOtp = (email) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    dispatch({ type: GENERATE_EMAIL_OTP_REQUEST });

    const { data } = await api.post("/users/generateotp", { email }, config);

    dispatch({
      type: GENERATE_EMAIL_OTP_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GENERATE_EMAIL_OTP_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export function ToggleSideBar(data) {
  return {
    type: TOGGLE_SIDEBAR,
    payload: data,
  };
}

export const verifyEmailOtp = (otp) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    dispatch({ type: VERIFY_EMAIL_OTP_REQUEST });

    const { data } = await api.post("/users/verifyotp", { otp }, config);
    dispatch({
      type: VERIFY_EMAIL_OTP_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VERIFY_EMAIL_OTP_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetPassword =
  (password, confirmPassword) => async (dispatch) => {
    try {
      const config = {
        headers: {
          Content_Type: "application/json",
        },
      };
      dispatch({ type: RESET_PASSWORD_REQUEST });
      const { data } = await api.post(
        "/users/resetpassword",
        { password, confirmPassword },
        config
      );
      dispatch({
        type: RESET_PASSWORD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: RESET_PASSWORD_FAILURE,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const setHeaderName = (data) => async (dispatch) => {
  dispatch({
    type: SET_HEADER_NAME,
    payload: data,
  });
};

export const CreatenewAdminUser = (userEntry) => async (dispatch) => {
  try {
    dispatch({
      type: USER_MANAGEMENT_CREATE_REQUEST,
    });
    const { data } = await authApi.post(`/users/addAdmin`, userEntry, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    console.log(data);
  } catch (error) {
    dispatch({
      type: USER_MANAGEMENT_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getUserManagementList =
  (page, limit, search, sort, filter) => async (dispatch) => {
    try {
      dispatch({
        type: USERS_MANAGEMENT_LIST_REQUEST,
      });

      const { data } = await authApi.post(
        `/users/user-management/list?page=${page}&limit=${limit}&search=${search}&sort=${sort}`,
        filter,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );

      dispatch({
        type: USERS_MANAGEMENT_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USERS_MANAGEMENT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const refreshJWITokenaction = (id) => async (dispatch) => {
  try {
    const { data } = await authApi.post(
      `/users/refresh-jwt-token/${id}`,
      {},
      {
        headers: { Authorization: getAuthorizationHeader() },
      }
    );
    console.log(data);
    if (data.token) {
      // Cookies.set('userToken', JSON.stringify(data.token), { expires: 7 });
      const user = JSON.parse(sessionStorage.getItem("userInfo"));
      user.token = data.token;
      sessionStorage.setItem("userInfo", JSON.stringify(user));
    }
  } catch (error) {
    console.log(error);
  }
};

export const activeUser =
  (id, page, limit, search, sort, filter) => async (dispatch) => {
    try {
      dispatch({
        type: USERS_MANAGEMENT_LIST_REQUEST,
      });

      const { data } = await authApi.put(`/users/user-status/${id}`);
      console.log(data);
      dispatch(getUserManagementList(page, limit, search, sort, filter));
    } catch (error) {
      dispatch({
        type: USERS_MANAGEMENT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteAdminUser = (id, setDeleteSuccess) => async (dispatch) => {
  try {
    const { data } = await authApi.delete(`/users/${id}`);
    setDeleteSuccess(true);
    dispatch(getUserManagementList(1, 10, "", null));
    console.log(data);
  } catch (error) {
    console.log(error);
  }
};
