import React, { useEffect } from "react";
import classes from "./referral.module.css";
import {
  BrudCrumbBackIcon,
  BrudCrumbNextIcon,
  DatePickerCalendarIcon,
} from "../../../Components/Icons/Icons";
import { Paper, Typography, Button, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import DatePicker from "react-datepicker";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createPromoCodeFunction, getOnePromoCode, updatePromoCodeFunction } from "../../../Redux/actions/promoCodeActions";

const AddPromoCode = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [referral_name, setReferral_name] = useState("");
  const [audianceType, setAudianceTyoe] = useState("");
  const [referral_code, setReferral_code] = useState();
  const [discount, setDiscount] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const { onePromocode } = useSelector((state) => state.promoCodeData)


  useEffect(() => {
    if (window.location.pathname.includes("edit-promocode")) {
      const path = window.location.pathname;
      const arr = path.split("/");
      const id = arr[arr.length - 1];
      dispatch(getOnePromoCode(id));
    }
  }, [dispatch]);

  useEffect(() => {
    console.log(new Date(onePromocode?.start_date));
    if (window.location.pathname.includes("edit-promocode")) {
      setReferral_code(onePromocode?.referral_code);
      setReferral_name(onePromocode?.referral_name);
      setAudianceTyoe(onePromocode?.audiance_type);
      if (onePromocode?.start_date && onePromocode?.end_date) {
        setStartDate(new Date(onePromocode?.start_date));
        setEndDate(new Date(onePromocode?.end_date));
      }
      setDiscount(onePromocode?.discount);
    }
  }, [onePromocode])

  const handleSubmit = () => {
    const userEntry = {
      referral_name: referral_name,
      audiance_type: audianceType,
      referral_code: referral_code,
      discount: `${discount}`,
      start_date: startDate,
      end_date: endDate,
    }
    if (window.location.pathname.includes("edit-promocode")) {
      dispatch(updatePromoCodeFunction(userEntry, onePromocode?._id));
    }
    else {
      dispatch(createPromoCodeFunction(userEntry));
    }
    navigate("/referrals");
  };
  return (
    <div>
      <div className={classes.brudCrumb}>
        <span onClick={() => navigate(-1)}>
          <BrudCrumbBackIcon />
        </span>
        <span
          className={classes.brudcrumback}
          onClick={() => navigate("/referrals")}
        >
          Referrals
        </span>
        <BrudCrumbNextIcon />
        <span className={classes.form_lable}>{window.location.pathname.includes("edit-promocode") ? "Edit" : "Generate"} Promo code</span>
      </div>
      <Paper style={{ padding: 30, marginTop: 20 }}>
        <Typography className={classes.addPromoMainHeading}>
          Generate Promo Code
        </Typography>
        <ValidatorForm
          useref="form"
          onSubmit={handleSubmit}
          className={classes.addPromoform}
        >
          <div className={classes.inputgridcontainer}>
            <div className="mt-3 mb-3">
              <Typography className={`mb-2 ${classes.form_lable}`}>
                Referral Name
              </Typography>
              <TextValidator
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                placeholder=""
                id="address1ControlTextarea"
                value={referral_name}
                fullWidth
                onChange={(e) => setReferral_name(e.target.value)}
                size="small"
                validators={["required"]}
                errorMessages={["This Feild is required"]}
              />
            </div>
            <div className="mt-3 mb-3">
              <Typography className={`mb-2 ${classes.form_lable}`}>
                Audience Type
              </Typography>
              <TextValidator
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                select
                fullWidth
                className={classes.inputs}
                value={audianceType}
                onChange={(e) => {
                  setAudianceTyoe(e.target.value);
                }}
                required={true}
                size="small"
              >
                <MenuItem value="">Select</MenuItem>
                <MenuItem value="all">All</MenuItem>
              </TextValidator>
            </div>
            <div className="mt-3 mb-3">
              <Typography className={`mb-2 ${classes.form_lable}`}>
                Referral Code
              </Typography>
              <TextValidator
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                placeholder=""
                id="address1ControlTextarea"
                value={referral_code}
                fullWidth
                onChange={(e) => setReferral_code(e.target.value)}
                size="small"
                validators={["required"]}
                errorMessages={["This Feild is required"]}
              />
            </div>
            <div className="mt-3 mb-3">
              <Typography className={`mb-2 ${classes.form_lable}`}>
                Discount
              </Typography>
              <TextValidator
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                placeholder=""
                id="address1ControlTextarea"
                value={discount}
                fullWidth
                onChange={(e) => setDiscount(e.target.value)}
                size="small"
                validators={["required"]}
                errorMessages={["This Feild is required"]}
              />
            </div>
            <div className="mt-3 mb-3">
              <Typography className={`mb-2 ${classes.form_lable}`}>
                Start Date
              </Typography>
              <div className={`mb-3 ${classes.datePicker1}`}>
                <DatePicker
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  size="small"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="yyyy-MM-dd"
                />
                <span
                  className={classes.datePickerCalendarIcon}
                  style={{ top: "7px" }}
                >
                  <DatePickerCalendarIcon />
                </span>
              </div>
            </div>
            <div className="mt-3 mb-3">
              <Typography className={`mb-2 ${classes.form_lable}`}>
                End Date
              </Typography>
              <div className={`mb-3 ${classes.datePicker1}`}>
                <DatePicker
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  size="small"
                  selected={endDate}
                  onChange={(date) => { setEndDate(date); console.log(date); }}
                  dateFormat="yyyy-MM-dd"
                />
                <span
                  className={classes.datePickerCalendarIcon}
                  style={{ top: "7px" }}
                >
                  <DatePickerCalendarIcon />
                </span>
              </div>
            </div>
          </div>
          <div className={classes.displayEnd}>
            <Button variant="outlined" className={classes.IndexMainButton} onClick={() => navigate(-1)}>
              Cancel
            </Button>
            <Button
              variant="contained"
              className={classes.IndexMainButton}
              type="submit"
            >
              <span>{window.location.pathname.includes("edit-promocode") ? "Update" : "Save"}</span>
            </Button>
          </div>
        </ValidatorForm>
      </Paper>
    </div>
  );
};

export default AddPromoCode;
