import {
  CUSTOMER_LIST_PAGINATION,
  CUSTOMER_PAGINATION_REQUESTS,
  CUSTOMER_PAGINATION_FAIL,
} from "../constants/customerConstants";

const initialStatePagination = {
  loading: true,
  Pagination: {},
  error: null,
};

export const paginationReducer = (state = initialStatePagination, action) => {
  switch (action.type) {
    case CUSTOMER_PAGINATION_REQUESTS:
      return { ...state, loading: true };

    case CUSTOMER_LIST_PAGINATION:
      return {
        ...state,
        loading: false,
        Pagination: action.payload.pagination,
      };
    case CUSTOMER_PAGINATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
