import React from "react";
import { Box, Typography, Button, Modal } from "@mui/material";
import classes from "./Subscription.module.css";
import successImage from "../../../Assets/images/ILL_Sucess.png";
import { useState } from "react";
import { MdClose } from "react-icons/md";

const SuccessModal = (props) => {
  const rootRef = React.useRef(null);
  const [moddleToggle, setModdleToggle] = useState(true);

  const closeModal = () => {
    setModdleToggle(false);
    props.modalClose(false);
  };

  return (
    <div>
      <Modal
        open={moddleToggle}
        onClose={() => closeModal()}
        aria-labelledby="server-modal-title"
        data-testid="register_modal"
        aria-describedby="server-modal-description"
        sx={{
          display: "flex",
          height: 480,
          marginTop: 10,
          justifyContent: "center",
        }}
        container={() => rootRef.current}
      >
        <Box
          sx={{
            position: "relative",
            bgcolor: "background.paper",
            borderRadius: 1,
            boxShadow: (theme) => theme.shadows[5],
          }}
          style={{
            width: "460px",
            height: "max-content",
            top: "20%",
          }}
          className={classes.loginModalWidth}
        >
          <Button
            variant="text"
            sx={{ minWidth: "unset" }}
            className={classes.closebtn}
            onClick={() => closeModal()}
          >
            <MdClose />
          </Button>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 25,
            }}
          >
            <img src={successImage} alt="" />
            <Typography className={`mt-2 ${classes.modalHeadind}`}>
              {props.type === "add"
                ? "Hurray! Plan Added Successfully!"
                : props.type === "edit"
                ? "Hurry! plan edited successfully!"
                : "Hurry! plan deleted successfully!"}
            </Typography>
            <Typography className={`mt-1 ${classes.modalSubHeading}`}>
              {props.type === "add"
                ? "Plan has been added successfully!"
                : props.type === "edit"
                ? "Plan has been edited successfully!"
                : "Plan has been deleted successfully!"}
            </Typography>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default SuccessModal;
