import React, { useEffect, useState } from "react";
import classes from "./communication.module.css";
import {
  Paper,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
} from "@mui/material";
import comNoDataImg from "../../../Assets/images/NoTeam.png";
import {
  FilterFunnelIcon,
  OppositeSortArrawBtnIcons,
  RoundAddIcon,
  SortArrawBtnIcons,
} from "../../../Components/Icons/Icons";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../Components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import FilterMenu from "../../../Components/customerPageFilter";
import { CompanyNameFilter } from "../../../Redux/actions/filterActions";
import InvoiceFilter from "../../../Components/InvoiceFilters";
import CommunicationListItem from "./communicationListItem";
import { GetCommunicationList } from "../../../Redux/actions/communicationActions";
import { handleSortClick } from "../../../Utilities/commonfunctions";
import { getCompaniesList } from "../../../Redux/actions/customerActions";
import { addDays } from 'date-fns';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';


const Communication = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [communicationList, setCommunicationList] = useState(["ghjgj"]);
  const PaginationData = useSelector(
    (state) => state.paginationData.Pagination
  );
  const { rowCount, currentPage } = PaginationData || {};
  const communicationDate = useSelector((state) => state.communicationDate);
  const { sortBy } = useSelector((state) => state.filter);
  const Companies = useSelector((state) => state.customerData);
  const [companiesListitems, setCompaniesListitems] = useState([]);
  const { companiesList } = Companies || {};
  const [showCalendar, setShowCalendar] = useState(false);
  const { selectedCompany, invoice } = useSelector((state) => state.filter);
  const [state, setState] = useState([
    {
      startDate: addDays(new Date(), -7),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  useEffect(() => {
    dispatch(getCompaniesList());
  }, [dispatch])

  useEffect(() => {
    if (companiesList && companiesList.length > 0) {
      getnamesarray();
    }
  }, [companiesList])

  const getnamesarray = async () => {
    const arr = ['teams', 'team_leader', 'team_player']
    const companyNames = await companiesList.map((item) => {
      return item.company_name
    })
    const uniqueCompanyList = companyNames.filter((value, index, self) => {
      return value !== undefined && value !== "" && self.indexOf(value) === index;
    });
    const newarr = [...arr, ...uniqueCompanyList]
    console.log(newarr);
    setCompaniesListitems(newarr);

  }

  // const loading = false;
  const { loading, errorlist, communicationList, totalCount } = communicationDate || {};

  useEffect(() => {
    const userEntry = {
      company: selectedCompany,
      status: invoice
    }
    dispatch(GetCommunicationList(currentPage, rowCount, sortBy, userEntry, state));
  }, [
    currentPage,
    rowCount,
    sortBy,
    selectedCompany,
    invoice,
    state,
    dispatch
  ]);

  const handleSetTimeRange = (item) => {
    console.log(item.selection);
    setState([item.selection])
    // dispatch(saveLoctionRangeFilter([item.selection]));
  }

  return (
    <div>
      {totalCount && totalCount > 0 ? (
        <div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => navigate("/communication/create-notification")}
              variant="contained"
              color="primary"
              className={classes.AddNotificationMainBtn}
            >
              <span>
                <RoundAddIcon />
              </span>
              Create Notification
            </Button>
          </div>
          <div className={classes.TableContainer}>
            <Table className={classes.MainTable}>
              <TableHead>
                <TableRow className={classes.tableHeadRow}>
                  <TableCell className={classes.tableHeadCell}>
                    <div className={classes.tableHEadercelldiv}>
                      <span> Sent to </span>{" "}
                      {companiesListitems && companiesListitems.length > 0 && (
                        <FilterMenu
                          option={companiesListitems}
                          name={CompanyNameFilter}
                        />
                      )}
                    </div>
                  </TableCell>
                  <TableCell className={classes.tableHeadCell}>
                    <div className={classes.tableHEadercelldiv}>
                      <span> Sent on </span>{" "}
                      <span style={{ marginLeft: "5px" }} onClick={toggleCalendar}>
                        <FilterFunnelIcon />
                      </span>
                      <Dialog open={showCalendar} onClose={toggleCalendar} className={classes.CalendarRangeContainer}>
                        <DateRangePicker
                          onChange={item => handleSetTimeRange(item)}
                          showSelectionPreview={true}
                          moveRangeOnFirstSelection={false}
                          months={2}
                          ranges={state}
                          direction="horizontal"
                        />
                      </Dialog>
                      <span onClick={() => handleSortClick(dispatch, sortBy, "createdAt")}>
                        {sortBy?.includes("desc") && sortBy?.includes("createdAt") ? (
                          <OppositeSortArrawBtnIcons />
                        ) : (
                          <SortArrawBtnIcons />
                        )}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell className={classes.tableHeadCell}>
                    <div className={classes.tableHEadercelldiv}>
                      <span> Status </span>
                      <InvoiceFilter Communication={true} />
                      <span onClick={() => handleSortClick(dispatch, sortBy, "delivered")}>
                        {sortBy?.includes("desc") && sortBy?.includes("delivered") ? (
                          <OppositeSortArrawBtnIcons />
                        ) : (
                          <SortArrawBtnIcons />
                        )}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell className={classes.tableHeadCell}>
                    <span>Action</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <td colSpan="9" className="text-center">
                      Loading...
                    </td>
                  </TableRow>
                ) : errorlist ? (
                  <TableRow>
                    <td colSpan="9" className="text-center">
                      {errorlist}
                    </td>
                  </TableRow>
                ) : communicationList && communicationList.length > 0 ? (
                  communicationList.map((item, index) => (
                    <CommunicationListItem key={index} item={item} />
                  ))
                ) : (
                  <TableRow>
                    <td colSpan="9" className="text-center">
                      No Data Found
                    </td>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <Pagination total={totalCount} />
        </div>
      ) : (
        <Paper className={classes.NoDataImagePagePaper}>
          <div className={classes.NoDataImageMindiv}>
            <img src={comNoDataImg} alt="" />
            <Typography className={`${classes.NoDataPageMainText} mb-3 mt-3`}>
              You have not shared any notification with your customers
            </Typography>
            <Button
              onClick={() => navigate("/communication/create-notification")}
              variant="contained"
              color="primary"
              className={classes.AddNotificationMainBtn}
            >
              <span>
                <RoundAddIcon />
              </span>
              Create Notification
            </Button>
          </div>
        </Paper>
      )}
    </div>
  );
};

export default Communication;
