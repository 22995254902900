import React, { useEffect, useState } from "react";
import classes from "./userManagement.module.css";
import {
  TextField,
  Button,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  OppositeSortArrawBtnIcons,
  RoundAddIcon,
  SearchIcon,
  SortArrawBtnIcons,
} from "../../../Components/Icons/Icons";
import { useDispatch, useSelector } from "react-redux";
import UsersListItem from "./usersListItem";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../Components/Pagination";
import { getUserManagementList } from "../../../Redux/actions/userActions";
import InvoiceFilter from "../../../Components/InvoiceFilters";
import { handleSortClick } from "../../../Utilities/commonfunctions";

const UserManagement = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const PaginationData = useSelector(
    (state) => state.paginationData.Pagination
  );
  const { rowCount, currentPage } = PaginationData || {};
  const userList = useSelector((state) => state.userLogin);
  const { loading, error, admins, totalCount } = userList || {};
  const { sortBy, invoice } = useSelector((state) => state.filter);

  useEffect(() => {
    const filterArray = {
      active: invoice,
    }
    dispatch(getUserManagementList(currentPage, rowCount, search, sortBy, filterArray));
  }, [
    dispatch,
    currentPage,
    rowCount,
    search,
    sortBy, invoice
  ]);

  useEffect(() => {
    console.log(admins);
  }, [admins]);
  return (
    <div>
      <div className={classes.HeaderSearchBar}>
        <TextField
          sx={{
            "& legend": {
              display: "none",
            },
            "& fieldset": {
              top: 0,
            },
          }}
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          className={classes.teamMemberSearch}
          id="firstNameinput"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          size="small"
        />
        <Button
          variant="contained"
          color="primary"
          className={classes.AdduserMainBtn}
          onClick={() => { navigate("/user-management/add-user") }}
        >
          <span>
            <RoundAddIcon />
          </span>
          Add User
        </Button>
      </div>
      <div className={classes.TableContainer}>
        <Table className={classes.MainTable}>
          <TableHead>
            <TableRow className={classes.tableHeadRow}>
              <TableCell className={classes.tableHeadCell}>
                <div className={classes.tableHEadercelldiv}>
                  <span> User Name </span>{" "}<span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "name")
                    }
                  >
                    {sortBy?.includes("desc") &&
                      sortBy?.includes("name") ? (
                      <OppositeSortArrawBtnIcons />
                    ) : (
                      <SortArrawBtnIcons />
                    )}
                  </span>
                </div>
              </TableCell>
              <TableCell className={classes.tableHeadCell}>
                <span> Role </span>
                <span
                  onClick={() =>
                    handleSortClick(dispatch, sortBy, "user_type")
                  }
                >
                  {sortBy?.includes("desc") &&
                    sortBy?.includes("user_type") ? (
                    <OppositeSortArrawBtnIcons />
                  ) : (
                    <SortArrawBtnIcons />
                  )}
                </span>
              </TableCell>
              <TableCell className={classes.tableHeadCell}>
                <span> Mobile Number </span>
                <span
                  onClick={() =>
                    handleSortClick(dispatch, sortBy, "phone")
                  }
                >
                  {sortBy?.includes("desc") &&
                    sortBy?.includes("phone") ? (
                    <OppositeSortArrawBtnIcons />
                  ) : (
                    <SortArrawBtnIcons />
                  )}
                </span>
              </TableCell>
              <TableCell className={classes.tableHeadCell} style={{ minWidth: 235 }}>
                <span>Email ID</span>
                <span
                  onClick={() =>
                    handleSortClick(dispatch, sortBy, "email")
                  }
                >
                  {sortBy?.includes("desc") &&
                    sortBy?.includes("email") ? (
                    <OppositeSortArrawBtnIcons />
                  ) : (
                    <SortArrawBtnIcons />
                  )}
                </span>
              </TableCell>
              <TableCell className={classes.tableHeadCell}>
                <div className={classes.tableHEadercelldiv}>
                  <span> Joining Date </span>
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "joiningDate")
                    }
                  >
                    {sortBy?.includes("desc") &&
                      sortBy?.includes("joiningDate") ? (
                      <OppositeSortArrawBtnIcons />
                    ) : (
                      <SortArrawBtnIcons />
                    )}
                  </span>
                </div>
              </TableCell>
              <TableCell className={classes.tableHeadCell}>
                <div className={classes.tableHEadercelldiv}>
                  <span> Status </span>{" "}
                  <InvoiceFilter />
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "is_active")
                    }
                  >
                    {sortBy?.includes("desc") &&
                      sortBy?.includes("is_active") ? (
                      <OppositeSortArrawBtnIcons />
                    ) : (
                      <SortArrawBtnIcons />
                    )}
                  </span>
                </div>
              </TableCell>
              <TableCell
                className={classes.tableHeadCell}
                style={{ minWidth: 100 }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <td colSpan="9" className="text-center">
                  Loading...
                </td>
              </TableRow>
            ) : error ? (
              <TableRow>
                <td colSpan="9" className="text-center">
                  {error}
                </td>
              </TableRow>
            ) : admins && admins.length > 0 ? (
              admins.map((item, index) => (
                <UsersListItem key={index} user={item} search={search} />
              ))
            ) : (
              <TableRow>
                <td colSpan="9" className="text-center">
                  No Data Found
                </td>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <Pagination total={totalCount} />
    </div>
  );
};

export default UserManagement;
