import React, { useEffect } from "react";
import classes from "./blogs.module.css";
import {
    Paper,
    Typography,
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import comNoDataImg from "../../../Assets/images/NoTeam.png";
import {
    OppositeSortArrawBtnIcons,
    RoundAddIcon,
    SortArrawBtnIcons,
} from "../../../Components/Icons/Icons";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../Components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import FilterMenu from "../../../Components/customerPageFilter";
import { CustomerIndustyFilter } from "../../../Redux/actions/filterActions";
import { Industries } from "../../../Redux/constants/filterConstatns";
import BlogsListItem from "./BlogsListItem";
import { getBlogsList } from "../../../Redux/actions/blogsActions";
import { handleSortClick } from "../../../Utilities/commonfunctions";

const Blogs = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const PaginationData = useSelector(
        (state) => state.paginationData.Pagination
    );
    const { rowCount, currentPage } = PaginationData || {};
    const BlogsDatas = useSelector((state) => state.blogsData);
    const { sortBy } = useSelector((state) => state.filter);

    // const loading = false;
    const { loading, errorlist, blogsList, totalCount } = BlogsDatas || {};

    useEffect(() => {
        dispatch(getBlogsList(currentPage, rowCount, sortBy));
    }, [
        currentPage,
        rowCount,
        dispatch,
        sortBy
    ]);

    return (
        <div>
            {blogsList && blogsList.length > 0 ? (
                <div>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                            onClick={() => navigate("/blogs/create-blogs")}
                            variant="contained"
                            color="primary"
                            className={classes.AddNotificationMainBtn}
                        >
                            <span>
                                <RoundAddIcon />
                            </span>
                            Create Blogs
                        </Button>
                    </div>
                    <div className={classes.TableContainer}>
                        <Table className={classes.MainTable}>
                            <TableHead>
                                <TableRow className={classes.tableHeadRow}>
                                    <TableCell className={classes.tableHeadCell} style={{ width: "17%" }}>
                                        <div className={classes.tableHEadercelldiv}>
                                            <span> Author Name </span>{" "}
                                            {/* <FilterMenu
                                                option={Industries}
                                                name={CustomerIndustyFilter}
                                            />{" "} */}
                                            <span onClick={() => handleSortClick(dispatch, sortBy, "userInfo.name")}>
                                                {sortBy?.includes("desc") && sortBy?.includes("userInfo.name") ? (
                                                    <OppositeSortArrawBtnIcons />
                                                ) : (
                                                    <SortArrawBtnIcons />
                                                )}
                                            </span>
                                        </div>
                                    </TableCell>
                                    <TableCell className={classes.tableHeadCell} style={{ width: "15%" }}>
                                        <div className={classes.tableHEadercelldiv}>
                                            <span> Tags </span>{" "}
                                            {/* <FilterMenu
                                                option={Industries}
                                                name={CustomerIndustyFilter}
                                            />{" "} */}
                                            {/* <span onClick={() => handleSortClick(dispatch, sortBy, "userInfo.name")}><SortArrawBtnIcons /></span> */}
                                        </div>
                                    </TableCell>
                                    <TableCell className={classes.tableHeadCell} style={{ width: "30%" }}>
                                        <div className={classes.tableHEadercelldiv}>
                                            <span> Content </span>{" "}
                                            {/* <FilterMenu
                                                option={Industries}
                                                name={CustomerIndustyFilter}
                                            />{" "} */}
                                            <span onClick={() => handleSortClick(dispatch, sortBy, "heading")}>
                                                {sortBy?.includes("desc") && sortBy?.includes("heading") ? (
                                                    <OppositeSortArrawBtnIcons />
                                                ) : (
                                                    <SortArrawBtnIcons />
                                                )}
                                            </span>
                                        </div>
                                    </TableCell>
                                    <TableCell className={classes.tableHeadCell} style={{ width: "17%" }}>
                                        <div className={classes.tableHEadercelldiv}>
                                            <span> Images </span>{" "}
                                            {/* <FilterMenu
                                                option={Industries}
                                                name={CustomerIndustyFilter}
                                            />{" "} */}
                                        </div>
                                    </TableCell>
                                    <TableCell className={classes.tableHeadCell} style={{ width: "15%" }}>
                                        <div className={classes.tableHEadercelldiv}>
                                            <span> Date & Time </span>{" "}
                                            {/* <FilterMenu
                                                option={Industries}
                                                name={CustomerIndustyFilter}
                                            />{" "} */}
                                            <span onClick={() => handleSortClick(dispatch, sortBy, "createdAt")}>
                                                {sortBy?.includes("desc") && sortBy?.includes("createdAt") ? (
                                                    <OppositeSortArrawBtnIcons />
                                                ) : (
                                                    <SortArrawBtnIcons />
                                                )}
                                            </span>
                                        </div>
                                    </TableCell>
                                    <TableCell className={classes.tableHeadCell} style={{ width: "6%" }}>
                                        <div className={classes.tableHEadercelldiv}>
                                            <span>Actions </span>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    <TableRow>
                                        <td colSpan="9" className="text-center">
                                            Loading...
                                        </td>
                                    </TableRow>
                                ) : errorlist ? (
                                    <TableRow>
                                        <td colSpan="9" className="text-center">
                                            {errorlist}
                                        </td>
                                    </TableRow>
                                ) : blogsList && blogsList.length > 0 ? (
                                    blogsList.map((item, index) => (
                                        <BlogsListItem key={index} item={item} />
                                    ))
                                ) : (
                                    <TableRow>
                                        <td colSpan="9" className="text-center">
                                            No Data Found
                                        </td>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </div>
                    <Pagination total={totalCount} />
                </div>
            ) : (
                <Paper className={classes.NoDataImagePagePaper}>
                    <div className={classes.NoDataImageMindiv}>
                        <img src={comNoDataImg} alt="" />
                        <Typography className={`${classes.NoDataPageMainText} mb-3 mt-3`}>
                            You have not created any blogs yet
                        </Typography>
                        <Button
                            onClick={() => navigate("/blogs/create-blogs")}
                            variant="contained"
                            color="primary"
                            className={classes.AddNotificationMainBtn}
                        >
                            <span>
                                <RoundAddIcon />
                            </span>
                            Create Blogs
                        </Button>
                    </div>
                </Paper>
            )}
        </div>
    );
};

export default Blogs;
