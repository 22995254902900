module.exports = {
  CREATE_COMMUNICATION_NOTIFICATION_REQUEST:
    "CREATE_COMMUNICATION_NOTIFICATION_REQUEST",
  CREATE_COMMUNICATION_NOTIFICATION_SUCCESS:
    "CREATE_COMMUNICATION_NOTIFICATION_SUCCESS",
  CREATE_COMMUNICATION_NOTIFICATION_FAIL:
    "CREATE_COMMUNICATION_NOTIFICATION_FAIL",
  LIST_COMMUNCATION_REQUEST: "LIST_COMMUNCATION_REQUEST",
  LIST_COMMUNCATION_SUCCESS: "LIST_COMMUNCATION_SUCCESS",
  LIST_COMMUNCATION_FAIL: "LIST_COMMUNCATION_FAIL",
  DELETE_COMMUNICATION_NOTIFICATION_REQUEST:
    "DELETE_COMMUNICATION_NOTIFICATION_REQUEST",
  DELETE_COMMUNICATION_NOTIFICATION_SUCCESS:
    "DELETE_COMMUNICATION_NOTIFICATION_SUCCESS",
  DELETE_COMMUNICATION_NOTIFICATION_FAIL:
    "DELETE_COMMUNICATION_NOTIFICATION_FAIL",
  ONE_COMMUNICATION_REQUEST: "ONE_COMMUNICATION_REQUEST",
  ONE_COMMUNICATION_SUCCESS: "ONE_COMMUNICATION_SUCCESS",
  ONE_COMMUNICATION_FAIL: "ONE_COMMUNICATION_FAIL",
};
