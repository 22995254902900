import { SUPPORT_TICKET_LIST_FAIL, SUPPORT_TICKET_LIST_SUCCESS, SUPPORT_TICKET_REQUEST } from "../constants/supportTicketConstants";

const initialState = {
    loading: true,
    support_tickets: [],
    error: null,
    totalCount: 0,
};

export const supportTicketReducer = (state = initialState, action) => {
    switch (action.type) {
        case SUPPORT_TICKET_REQUEST:
            return { ...state, loading: true };

        case SUPPORT_TICKET_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                support_tickets: action.payload.support_tickets,
                totalCount: action.payload.totalCount,
                error: null,
            };
        case SUPPORT_TICKET_LIST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};