import { CREATE_PROMOCODE_FAIL, CREATE_PROMOCODE_REQUEST, CREATE_PROMOCODE_SUCCESS, ONE_PROMOCODE_FAIL, ONE_PROMOCODE_REQUEST, ONE_PROMOCODE_SUCCESS, PROMOCODE_LIST_FAIL, PROMOCODE_LIST_REQUEST, PROMOCODE_LIST_SUCCESS } from "../constants/promoCodeConstants";

const initialState = {
    loading: true,
    createError: null,
    listError: null,
    promocodeList: [],
    onePromocode: null,
    getoneError: null,
    totalCount: 0,
};

export const promoCodeReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_PROMOCODE_REQUEST:
            return { ...state, loading: true };

        case CREATE_PROMOCODE_SUCCESS:
            return {
                ...state,
                loading: false,
                createError: null,
            };
        case CREATE_PROMOCODE_FAIL:
            return {
                ...state,
                loading: false,
                createError: action.payload,
            };
        case PROMOCODE_LIST_REQUEST:
            return { ...state, loading: true };

        case PROMOCODE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                promocodeList: action.payload.promolist,
                totalCount: action.payload.totalCount,
                listError: null,
            };
        case PROMOCODE_LIST_FAIL:
            return {
                ...state,
                loading: false,
                listError: action.payload,
            };
        case ONE_PROMOCODE_REQUEST:
            return { ...state, loading: true };

        case ONE_PROMOCODE_SUCCESS:
            return {
                ...state,
                loading: false,
                onePromocode: action.payload,
                getoneError: null,
            };
        case ONE_PROMOCODE_FAIL:
            return {
                ...state,
                loading: false,
                getoneError: action.payload,
            };
        default:
            return state;
    }
};
