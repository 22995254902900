import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box, Button } from "@mui/material";
import classes from "./referral.module.css";
import { useNavigate } from "react-router-dom";
import Referrals from "./Referrals";
import Promocodes from "./Promocodes";
import { RoundAddIcon } from "../../../Components/Icons/Icons";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function RefferalIndex() {
    const navigate = useNavigate();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                    variant="contained"
                    className={classes.IndexMainButton}
                    onClick={() => navigate("/referrals/add-promocode")}
                >
                    <RoundAddIcon />
                    <span> Generate Promo code </span>{" "}
                </Button>
            </div>
            <Box>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        className={classes.HeadTabButton}
                        aria-label="basic tabs example"
                    >
                        <Tab
                            label={"Referral Codes"}
                            {...a11yProps(0)}
                            style={{ textTransform: "capitalize" }}
                        />
                        <Tab
                            label={"Promo Codes"}
                            {...a11yProps(1)}
                            style={{ textTransform: "capitalize" }}
                        />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0} className={classes.TabPanel}>
                    <Referrals />
                </TabPanel>
                <TabPanel value={value} index={1} className={classes.TabPanel}>
                    <Promocodes />
                </TabPanel>
            </Box>
        </div>
    );
}
