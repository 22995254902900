import {
  COMPANY_NAME_FAILURE,
  COMPANY_NAME_REQUEST,
  COMPANY_NAME_SUCCESS,
  CONTACT_PERSON_FAILURE,
  CONTACT_PERSON_REQUEST,
  CONTACT_PERSON_SUCCESS,
  CUSTOMER_DESIGNATION_FAILURE,
  CUSTOMER_DESIGNATION_SUCCESS,
  CUSTOMER_INDUSTRY_FAILURE,
  CUSTOMER_INDUSTRY_REQUEST,
  CUSTOMER_INDUSTRY_SUCCESS,
  CUSTOMER_SUBS_FILTER_FAILURE,
  CUSTOMER_SUBS_FILTER_REQUEST,
  CUSTOMER_SUBS_FILTER_SUCCESS,
  DURATION_FAILURE,
  DURATION_REQUEST,
  DURATION_SUCCESS,
  INVOICE_FILTER_FAILURE,
  INVOICE_FILTER_REQUEST,
  INVOICE_FILTER_SUCCESS,
  PLAN_NAME_FAILURE,
  PLAN_NAME_REQUEST,
  PLAN_NAME_SUCCESS,
  SORT_NAME,
  TEAM_NAME_FAILURE,
  TEAM_NAME_REQUEST,
  TEAM_NAME_SUCCESS,
} from "../constants/filterConstatns";

const initialStatePagination = {
  loading: true,
  industries: [],
  designation: [],
  invoice: [],
  teamNames: [],
  durations: [],
  planNames: [],
  contactPersons: [],
  selectedCompany: [],
  subscriptionTypes: [],
  error: null,
  sortBy: null,
};

export const filterReducer = (state = initialStatePagination, action) => {
  switch (action.type) {
    case INVOICE_FILTER_REQUEST:
      return { ...state, loading: true };

    case INVOICE_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        invoice: action.payload,
      };
    case INVOICE_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CUSTOMER_INDUSTRY_REQUEST:
      return { ...state, loading: true };

    case CUSTOMER_INDUSTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        industries: action.payload,
      };
    case CUSTOMER_INDUSTRY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CUSTOMER_DESIGNATION_SUCCESS:
      return {
        ...state,
        loading: false,
        designation: action.payload,
      };
    case CUSTOMER_DESIGNATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SORT_NAME:
      return {
        ...state,
        loading: false,
        sortBy: action.payload,
      }
    case TEAM_NAME_REQUEST:
      return { ...state, loading: true };

    case TEAM_NAME_SUCCESS:
      return {
        ...state,
        loading: false,
        teamNames: action.payload,
      };
    case TEAM_NAME_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CUSTOMER_SUBS_FILTER_REQUEST:
      return { ...state, loading: true };

    case CUSTOMER_SUBS_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        subscriptionTypes: action.payload,
      };
    case CUSTOMER_SUBS_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DURATION_REQUEST:
      return { ...state, loading: true };

    case DURATION_SUCCESS:
      return {
        ...state,
        loading: false,
        durations: action.payload,
      };
    case DURATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case COMPANY_NAME_REQUEST:
      return { ...state, loading: true };

    case COMPANY_NAME_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedCompany: action.payload,
      };
    case COMPANY_NAME_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case PLAN_NAME_REQUEST:
      return { ...state, loading: true };

    case PLAN_NAME_SUCCESS:
      return {
        ...state,
        loading: false,
        planNames: action.payload,
      };
    case PLAN_NAME_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CONTACT_PERSON_REQUEST:
      return { ...state, loading: true };

    case CONTACT_PERSON_SUCCESS:
      return {
        ...state,
        loading: false,
        contactPersons: action.payload,
      };
    case CONTACT_PERSON_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
