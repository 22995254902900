import { Button } from "@mui/material";
import classes from "./invoice.module.css";
import React, { useEffect } from "react";
import { getOneInvoice } from "../../../Redux/actions/customerActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Invoicepdf from "./invoicepdf";
import {
  BrudCrumbBackIcon,
  BrudCrumbNextIcon,
} from "../../../Components/Icons/Icons";

const InvoiceViewPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const oneInvoice = useSelector((state) => state.customerData.oneInvoice);

  useEffect(() => {
    const path = window.location.pathname;
    const Arr = path.split("/");
    const name = Arr[Arr.length - 1].replaceAll("-", " ");
    console.log(name);
    dispatch(getOneInvoice(name));
  }, [dispatch]);

  const generatePDF = () => {
    // const input = document.getElementById(`pdf-content-${oneInvoice._id}`);
    // console.log(input);
    // html2canvas(input, {
    //   scale: 2,
    //   useCORS: true,
    //   scrollY: -window.scrollY,
    //   logging: true,
    // }).then((canvas) => {
    //   const imgData = canvas.toDataURL("image/jpeg", 0.6); // Adjust image quality (0.0 - 1.0)
    //   const pdf = new jsPDF("p", "mm", "a4");
    //   const imgWidth = 210; // A4 width in mm
    //   const imgHeight = (canvas.height * imgWidth) / canvas.width;
    //   pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);
    //   const options = {
    //     compress: true,
    //     compression: "SLOW",
    //     precision: 6,
    //     removeInvalid: true, // Remove invalid objects
    //     objectCallback: function (obj) {
    //       obj.compress();
    //     }, // Compress objects
    //     //optimizeFonts: true, // Optimize font sizes
    //   };
    //   const pdfDataUri = pdf.output("datauristring", options);
    //   const downloadLink = document.createElement("a");
    //   downloadLink.href = pdfDataUri;
    //   downloadLink.download = "invoice.pdf";
    //   downloadLink.click();
    // });
    const url = `${process.env.REACT_APP_API_URL}/invoices/downloadInvoice/${oneInvoice.order_id}/${oneInvoice.user_id._id}`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <div>
      <div className={classes.brudCrumb}>
        <span onClick={() => navigate(-1)}>
          <BrudCrumbBackIcon />
        </span>
        <span
          className={classes.brudcrumback}
          onClick={() => navigate("/invoicing")}
        >
          Invoice
        </span>
        <BrudCrumbNextIcon />
        <span className={classes.form_lable}>{oneInvoice?.invoice_number}</span>
      </div>
      <div className={`mt-3`}>
        <Invoicepdf oneInvoice={oneInvoice} />
        <div className={classes.invoiceBtnSection}>
          <Button
            variant="outlined"
            className={classes.invoiceviewbtns}
            style={{ border: "3px solid" }}
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.invoiceviewbtns}
            onClick={generatePDF}
          >
            Download
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InvoiceViewPage;
