import React, { useState } from "react";
import classes from "./contactUs.module.css"
import {
  TableCell,
  TableRow,
  Typography,
  Avatar,
} from "@mui/material";
import moment from "moment";

export const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <Typography className={classes.SomeOtheText}>
      {isReadMore ? text.slice(0, 150) : text}
      {text.length > 150 ? (
        <span
          onClick={toggleReadMore}
          className={classes.ReadMoreToggleButton}
          style={{ marginLeft: "10px" }}
        >
          {isReadMore ? "View More" : " Show less"}
        </span>
      ) : null}
    </Typography>
  );
};

function ContactUsListItem(props) {
  return (
    <TableRow className={`${classes.table_bg1}`}>
      <TableCell style={{ verticalAlign: "top" }}>
        <div className={classes.displayFlex}>
          <Avatar
            src={`${process.env.REACT_APP_IMAGE_URL}/${props.item.userInfo?.profile_pic?.destination}/${props.item.userInfo?.profile_pic?.filename}`}
            alt=""
            className="avatar-xs rounded-circle me-2"
          />
          <div>
            <Typography
              href="#javascript: void(0);"
              className={classes.TableCellMainText}
            >
              {props.item.userInfo.name}
            </Typography>
            <span className={classes.SomeOtheText}>
              {props.item?.companyInfo?.company_name}
            </span>
          </div>
        </div>
      </TableCell>
      <TableCell>
        <div>
          {/* <div className={`mb-3 ${classes.feedbackEmojiContainer}`}>
            <img
              src={poor}
              alt="Poor"
              width={16}
              height={16}
              className={props.item.rating === 1 ? "" : classes.feedbackEmoji}
            />
            <img
              src={unhappy}
              alt="unhappy"
              width={16}
              height={16}
              className={props.item.rating === 2 ? "" : classes.feedbackEmoji}
            />
            <img
              src={apathy}
              alt="apathy"
              width={16}
              height={16}
              className={props.item.rating === 3 ? "" : classes.feedbackEmoji}
            />
            <img
              src={happy}
              alt="happy"
              width={16}
              height={16}
              className={props.item.rating === 4 ? "" : classes.feedbackEmoji}
            />
            <img
              src={love}
              alt="love"
              width={16}
              height={16}
              className={props.item.rating === 5 ? "" : classes.feedbackEmoji}
            />
          </div> */}
          <Typography className={`mb-2 ${classes.TableCellMainText}`}>
            {props.item.subject}
          </Typography>
          <ReadMore>{props.item.message}</ReadMore>
          {/* <Typography className={classes.SomeOtheText}>{props.item.message}</Typography> */}
        </div>
      </TableCell>
      <TableCell style={{ verticalAlign: "top" }}>
        <div>
          {props.item.images?.length > 0 && props.item.images?.length > 3 && (
            <div className={classes.documentImagesContiner}>
              <img
                key={0}
                src={`${process.env.REACT_APP_IMAGE_URL}/${props.item.images[0]?.destination}/${props.item.images[0]?.filename}`}
                alt="profilepic1"
              />
              <img
                key={1}
                src={`${process.env.REACT_APP_IMAGE_URL}/${props.item.images[1]?.destination}/${props.item.images[1]?.filename}`}
                alt="profilepic2"
              />
              <Typography className={classes.TableCellMainText1}>
                +{props.item.images?.length - 2}
              </Typography>
            </div>
          )}
          {props.item.images?.length > 0 && props.item.images?.length < 4 && (
            <div className={classes.documentImagesContiner}>
              {props.item.images.map((item, index) => (
                <img
                  key={index}
                  src={`${process.env.REACT_APP_IMAGE_URL}/${item?.destination}/${item?.filename}`}
                  alt="profilepic3"
                />
              ))}
            </div>
          )}
        </div>
      </TableCell>
      <TableCell style={{ verticalAlign: "top" }}>
        <div>
          <Typography
            href="#javascript: void(0);"
            className={classes.TableCellMainText}
          >
            {moment(props.item.createdAt).format("DD MMM YYYY")}
          </Typography>
          <span className={classes.SomeOtheText}>
            {moment(props.item.createdAt).format("hh:mm A")}
          </span>
        </div>
      </TableCell>
    </TableRow>
  );
}

export default ContactUsListItem;
