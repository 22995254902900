import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Paper, Chip, MenuItem, Menu } from "@mui/material";
import classes from "./customer.module.css";
import EditCompanyModal from "./EditCompanyModal";
import {
  activeCustomer,
  fetchCustomer,
  inActiveCustomer,
} from "../../../Redux/actions/customerActions";
import {
  ArrowDownIcon,
  CusEditPenIcon,
  CusIndustryIcon,
  CusLocation,
} from "../../../Components/Icons/Icons";
import CommonConformationmodal from "../../../Components/CommonConformationmodal";

const CompanyCard = (props) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [moddalToggle, setModdalToggle] = useState(false);
  const customerData = useSelector((state) => state.customerData.customer);
  const [isConfirmModel, setIsConfirmModel] = useState(false);
  const [isActive, setIsActive] = useState();
  const { company, user_id, is_active, _id } = customerData || {};
  const { company_name, gst_number, address_one, address_two, industry, pin } =
    company || {};

  const getCustomer = () => {
    const path = window.location.pathname;
    const arr = path.split("/");
    console.log(arr);
    dispatch(fetchCustomer(arr[arr.length - 1]));
  };
  useEffect(() => {
    const path = window.location.pathname;
    const arr = path.split("/");
    console.log(arr);
    dispatch(fetchCustomer(arr[arr.length - 1]));
  }, [dispatch]);

  const handleChangeStatus = (e, name) => {
    e.stopPropagation();
    setIsConfirmModel(true);
    setIsActive(name);
  };

  useEffect(() => {
    console.log(customerData);
  }, [customerData]);

  const closeHandler = () => {
    getCustomer();
    setModdalToggle(false);
    setIsConfirmModel(false);
  };

  const handleMenuclick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleActiveFunction = () => {
    if (isActive && isActive === "Active" && _id) {
      dispatch(activeCustomer(_id));
      handleClose();
    } else if (isActive && isActive === "Inactive" && _id) {
      dispatch(inActiveCustomer(_id));
      handleClose();
    }
  };

  return (
    <div>
      <Paper style={{ padding: "24px", background: "#F7F9FC", height: 162 }}>
        <div className={classes.customerCardMainDiv}>
          <div style={{ width: "100%" }}>
            <div className={classes.displayFlex}>
              <Typography className={classes.companyIdText}>76D354</Typography>{" "}
              <span className={classes.cardHeading}>|</span>
              <Typography className={classes.cardHeading}>
                {company_name}
              </Typography>
              <Chip
                label={is_active ? "Active" : "Inactive"}
                size="small"
                style={{
                  backgroundColor: is_active ? "#E9F7EC" : "#FCE3E5",
                  color: is_active ? "#317D41" : "#BA1221",
                  padding: "10px",
                  height: "29px",
                }}
                onDelete={() => console.log("deleted")}
                deleteIcon={<ArrowDownIcon />}
                onClick={handleMenuclick}
              />
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={classes.menuListHolder}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem
                  key={`active`}
                  className={classes.menuItems}
                  onClick={(e) => {
                    handleChangeStatus(e, "Active");
                  }}
                >
                  <span>Active</span>
                </MenuItem>
                <MenuItem
                  key={`inActive`}
                  className={classes.menuItems}
                  onClick={(e) => {
                    handleChangeStatus(e, "Inactive");
                  }}
                >
                  <span>Inactive</span>
                </MenuItem>
              </Menu>
            </div>
            <Typography className={`mt-2 ${classes.cardSubHeading}`}>
              <b>GST:</b> {gst_number}
            </Typography>
            <div className={`mt-2 ${classes.companyDetailsContainer}`}>
              <Typography
                className={classes.displayFlex}
                style={{
                  borderRight: "1px solid #CBCFE3",
                  marginRight: "10px",
                }}
              >
                <CusIndustryIcon />
                <span>{industry}</span>
              </Typography>
              <Typography className={classes.displayFlex}>
                <CusLocation />
                <span>{`${address_one ? `${address_one},` : ""} ${
                  address_two ? `${address_two},` : ""
                } ${pin}`}</span>
              </Typography>
            </div>
          </div>
          <Typography
            className={classes.EditButton}
            onClick={() => setModdalToggle(true)}
          >
            <CusEditPenIcon /> <span>Edit</span>
          </Typography>
        </div>
      </Paper>

      {isConfirmModel && (
        <CommonConformationmodal
          type={"inactive"}
          callback={handleActiveFunction}
          modalClose={closeHandler}
        />
      )}
      {moddalToggle ? (
        <EditCompanyModal
          modalClose={closeHandler}
          values={company}
          id={user_id._id}
          _id={_id}
        />
      ) : null}
    </div>
  );
};

export default CompanyCard;
