export const Industries = ["electrical", "realestate", "plumbing", "builders"];
export const Designation = ["owner", "manager", "partners", "labour"];

export const INVOICE_FILTER_REQUEST = "INVOICE_FILTER_REQUEST";
export const INVOICE_FILTER_SUCCESS = "INVOICE_FILTER_SUCCESS";
export const INVOICE_FILTER_FAILURE = "INVOICE_FILTER_FAILURE";

//Customer Industry Filter

export const CUSTOMER_INDUSTRY_REQUEST = "CUSTOMER_INDUSTRY_REQUEST";
export const CUSTOMER_INDUSTRY_SUCCESS = "CUSTOMER_INDUSTRY_SUCCESS";
export const CUSTOMER_INDUSTRY_FAILURE = "CUSTOMER_INDUSTRY_FAILURE";

//Customer Designation Filter

export const CUSTOMER_DESIGNATION_REQUEST = "CUSTOMER_DESIGNATION_REQUEST";
export const CUSTOMER_DESIGNATION_SUCCESS = "CUSTOMER_DESIGNATION_SUCCESS";
export const CUSTOMER_DESIGNATION_FAILURE = "CUSTOMER_DESIGNATION_FAILURE";

export const CUSTOMER_COMPANY_NAME_REQUEST = "CUSTOMER_COMPANY_NAME_REQUEST";
export const CUSTOMER_COMPANY_NAME_SUCCESS = "CUSTOMER_COMPANY_NAME_SUCCESS";
export const CUSTOMER_COMPANY_NAME_FAILURE = "CUSTOMER_COMPANY_NAME_FAILURE";

export const COMPANY_NAME_REQUEST = "COMPANY_NAME_REQUEST";
export const COMPANY_NAME_SUCCESS = "COMPANY_NAME_SUCCESS";
export const COMPANY_NAME_FAILURE = "COMPANY_NAME_FAILURE";

export const CONTACT_PERSON_REQUEST = "CONTACT_PERSON_REQUEST";
export const CONTACT_PERSON_SUCCESS = "CONTACT_PERSON_SUCCESS";
export const CONTACT_PERSON_FAILURE = "CONTACT_PERSON_FAILURE";

export const TEAM_NAME_REQUEST = "TEAM_NAME_REQUEST";
export const TEAM_NAME_SUCCESS = "TEAM_NAME_SUCCESS";
export const TEAM_NAME_FAILURE = "TEAM_NAME_FAILURE";

export const DURATION_REQUEST = "DURATION_REQUEST";
export const DURATION_SUCCESS = "DURATION_SUCCESS";
export const DURATION_FAILURE = "DURATION_FAILURE";

export const PLAN_NAME_REQUEST = "PLAN_NAME_REQUEST";
export const PLAN_NAME_SUCCESS = "PLAN_NAME_SUCCESS";
export const PLAN_NAME_FAILURE = "PLAN_NAME_FAILURE";

export const CUSTOMER_SUBS_FILTER_REQUEST = "CUSTOMER_SUBS_FILTER_REQUEST";
export const CUSTOMER_SUBS_FILTER_SUCCESS = "CUSTOMER_SUBS_FILTER_SUCCESS";
export const CUSTOMER_SUBS_FILTER_FAILURE = "CUSTOMER_SUBS_FILTER_FAILURE";

export const SORT_NAME = "SORT_NAME";
