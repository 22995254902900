import React, { useState } from 'react'
import classes from "./index.module.css";
import { Modal, Box, Button, Typography } from "@mui/material";
import { useRef } from 'react';
import { MdClose } from 'react-icons/md';
import { Cancel } from '@mui/icons-material';

const CommonConformationmodal = ({ type, callback, modalClose }) => {
    const [modalToggle, setModalToggle] = useState(true);
    const rootRef = useRef(null);

    const closeModal = () => {
        setModalToggle(false);
        modalClose();
    };

    const handleSubmit = () => {
        callback();
        closeModal();
    }

    return (
        <div>
            <Modal
                open={modalToggle}
                onClose={() => closeModal()}
                aria-labelledby="server-modal-title"
                data-testid="register_modal"
                aria-describedby="server-modal-description"
                sx={{
                    display: "flex",
                    top: "10%",
                    height: "fit-content",
                    marginTop: 10,
                    justifyContent: "center",
                }}
                container={() => rootRef.current}
            >
                <Box
                    sx={{
                        position: "relative",
                        bgcolor: "background.paper",
                        borderRadius: 1,
                        boxShadow: (theme) => theme.shadows[5],
                    }}
                    style={{ minWidth: "450px", height: "auto" }}
                    className={"loginModalWidth"}
                >
                    <div className={classes.forgetPasswordmodalclose}>
                        <Button
                            variant="text"
                            sx={{ minWidth: "unset" }}
                            className={classes.closebtn}
                            onClick={() => closeModal()}
                        >
                            <MdClose />
                        </Button>
                    </div>
                    <div className={classes.deleteConformationFirstDiv}>
                        <Typography className={classes.successPageSubText}>
                            {type === "delete" ? "Are you sure you want to delete?" : "Are you sure you want to Change Status?"}
                        </Typography>
                        <div className={classes.addMemberModalbtnSection}>
                            <Button variant="outlined" onClick={() => closeModal()}>
                                Cancel
                            </Button>
                            <Button variant="contained" onClick={() => handleSubmit()}>
                                {type === "delete" ? "Delete" : "Change Status"}
                            </Button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default CommonConformationmodal