import React from 'react';
import { useEffect } from 'react';
import classes from "./communication.module.css";
import { Paper, Typography, Chip, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getOneCommunicationDetails } from '../../../Redux/actions/communicationActions';
import { useNavigate } from 'react-router-dom';
import { BlueEditPenIcon, BrudCrumbBackIcon, BrudCrumbNextIcon } from '../../../Components/Icons/Icons';

const CommunicationViewPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const communicationDate = useSelector((state) => state.communicationDate);
    const { oneCommunication } = communicationDate || {};

    useEffect(() => {
        const path = window.location.pathname;
        const arr = path.split("/");
        console.log(arr);
        dispatch(getOneCommunicationDetails(arr[arr.length - 1]));
    }, [dispatch]);
    return (
        <div>
            <div className={classes.brudCrumb}>
                <span onClick={() => navigate(-1)}><BrudCrumbBackIcon /></span>
                <span onClick={() => navigate("/communication")}>Communication</span>
                <span><BrudCrumbNextIcon /></span>
                <span>View Notification</span>
            </div>
            {oneCommunication && (
                <Paper className={`mt-3 ${classes.ViewPageMainContainer}`}>
                    <div className={classes.viewPageHeadContainer}>
                        <Typography className={classes.viewPageHeadtitle}>View Notification</Typography>
                        <Button
                            variant="text"
                            className={classes.viewPageEditBtn}
                            onClick={() => navigate(`/communication/edit-notification/${oneCommunication._id}`)}
                        >
                            <span style={{ marginRight: "5px" }}>
                                <BlueEditPenIcon />
                            </span>
                            Edit Details
                        </Button>
                    </div>
                    <Typography className={`mt-4 ${classes.formLable}`}>
                        To
                    </Typography>
                    <div className={classes.chips_container}>
                        {oneCommunication && oneCommunication.members.groups.length > 0 && oneCommunication.members.groups.map((item, index) => (
                            <Chip
                                key={index}
                                label={item.replace("_", " ")}
                                className={classes.viewChipBtn}
                            />
                        ))}
                        {oneCommunication && oneCommunication.members.companies.length > 0 && oneCommunication.members.companies.map((item, index) => (
                            <Chip
                                key={index}
                                label={item.company_name}
                                className={classes.viewChipBtn}
                            />
                        ))}
                    </div>
                    <Typography className={`mt-4 ${classes.viewPageSubTitle}`}>
                        Notification
                    </Typography>
                    <Typography className={`mt-3 ${classes.formLable}`}>
                        Subject
                    </Typography>
                    <Typography className={`${classes.viewPageNrmlText}`}>
                        {oneCommunication.subject}
                    </Typography>
                    <Typography className={`mt-3 ${classes.formLable}`}>
                        Details
                    </Typography>
                    <Typography className={`${classes.viewPageNrmlText}`}>
                        {oneCommunication.message}
                    </Typography>
                </Paper>
            )}
        </div>
    )
}

export default CommunicationViewPage