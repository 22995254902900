import React, { useEffect, useState } from "react";
import classes from "../customer.module.css";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
} from "@mui/material";
import { addDays } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { fetchAuditTrial } from "../../../../Redux/actions/customerActions";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import {
  FilterFunnelIcon,
  OppositeSortArrawBtnIcons,
  SortArrawBtnIcons,
} from "../../../../Components/Icons/Icons";
import { DateRangePicker } from "react-date-range";
import moment from "moment";
import { handleSortClick } from "../../../../Utilities/commonfunctions";

const AuditTrial = (props) => {
  const dispatch = useDispatch();
  const { sortBy } = useSelector((state) => state.filter);
  const [showCalendar, setShowCalendar] = useState(false);
  const [state, setState] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);
  useEffect(() => {
    console.log(props);
    dispatch(
      fetchAuditTrial(
        props.id,
        sortBy,
        state[0].startDate !== null
          ? moment(state[0].startDate).format("YYYY-MM-DD")
          : null,
        state[0].endDate !== null
          ? moment(state[0].endDate).format("YYYY-MM-DD")
          : null
      )
    );
  }, [sortBy, state, dispatch, props]);

  const auditTrialData = useSelector((state) => state.customerData);

  const { loading, error, auditTrials } = auditTrialData || {};

  const handleSetTimeRange = (item) => {
    console.log(item.selection);
    setState([item.selection]);
    // dispatch(saveLoctionRangeFilter([item.selection]));
  };

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  return (
    <div>
      <div>
        <Table className={classes.MainTable}>
          <TableHead>
            <TableRow className={classes.tableHeadRow}>
              <TableCell className={classes.tableHeadCell}>
                <div className={classes.tableHEadercelldiv}>
                  <span> Date & Time </span>{" "}
                  <span style={{ marginLeft: "5px" }} onClick={toggleCalendar}>
                    <FilterFunnelIcon />
                  </span>
                  <Dialog
                    open={showCalendar}
                    onClose={toggleCalendar}
                    className={classes.CalendarRangeContainer}
                  >
                    <DateRangePicker
                      onChange={(item) => handleSetTimeRange(item)}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      months={2}
                      ranges={state}
                      direction="horizontal"
                    />
                  </Dialog>
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "createdAt")
                    }
                  >
                    {sortBy?.includes("desc") &&
                    sortBy?.includes("createdAt") ? (
                      <OppositeSortArrawBtnIcons />
                    ) : (
                      <SortArrawBtnIcons />
                    )}
                  </span>
                </div>
              </TableCell>
              <TableCell className={classes.tableHeadCell}>
                <span> Action </span>
                <span
                  onClick={() => handleSortClick(dispatch, sortBy, "action")}
                >
                  {sortBy?.includes("desc") && sortBy?.includes("action") ? (
                    <OppositeSortArrawBtnIcons />
                  ) : (
                    <SortArrawBtnIcons />
                  )}
                </span>
              </TableCell>
              <TableCell className={classes.tableHeadCell}>
                <span> Channel </span>
                <span
                  onClick={() => handleSortClick(dispatch, sortBy, "channel")}
                >
                  {sortBy?.includes("desc") && sortBy?.includes("channel") ? (
                    <OppositeSortArrawBtnIcons />
                  ) : (
                    <SortArrawBtnIcons />
                  )}
                </span>
              </TableCell>
              <TableCell className={classes.tableHeadCell}>
                IP Address
                <span
                  onClick={() =>
                    handleSortClick(dispatch, sortBy, "ip_address")
                  }
                >
                  {sortBy?.includes("desc") &&
                  sortBy?.includes("ip_address") ? (
                    <OppositeSortArrawBtnIcons />
                  ) : (
                    <SortArrawBtnIcons />
                  )}
                </span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <td colSpan="9" className="text-center">
                  Loading...
                </td>
              </TableRow>
            ) : error ? (
              <TableRow>
                <td colSpan="9" className="text-center">
                  {error}
                </td>
              </TableRow>
            ) : auditTrials && auditTrials.length > 0 ? (
              auditTrials.map((item, index) => (
                <TableRow className={`${classes.table_bg1}`}>
                  <TableCell>
                    <p className="mb-0">
                      {moment(item.createdAt).format("DD MMM YYYY")}
                    </p>
                    <span>{moment(item.createdAt).format("HH:mm A")}</span>
                  </TableCell>
                  <TableCell>{item.action}</TableCell>
                  <TableCell>{item.channel}</TableCell>
                  <TableCell>{item.ip_address}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <td colSpan="9" className="text-center">
                  No Data Found
                </td>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default AuditTrial;
