import React from "react";
import classes from "../customer.module.css";
import Box from "@mui/material/Box";
import {
  Collapse,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  claculateRemaining,
  getExpiryDate,
} from "../../../../Utilities/commonfunctions";
import ProgressBar from "react-bootstrap/ProgressBar";
import InvoiceSendModel from "../InvoiceSendModel";
import { useState } from "react";
import moment from "moment";
import { SendInvoice } from "../../../../Redux/actions/customerActions";
import { useDispatch } from "react-redux";

function SubscriptionListItem({ subscription }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [modalToggle, setModalToggle] = useState(false);

  const handleSendInvoice = async () => {
    console.log(subscription);

    const data = {
      email: subscription?.user_info.email,
      phone: subscription?.user_info.phone,
      order_id: subscription.order_id,
    };
    dispatch(SendInvoice(data, setModalToggle));
  };

  const CloseModal = () => {
    setModalToggle(false);
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        className={`${classes.table_bg1}`}
        style={{
          borderBottom: !open ? "20px solid  #f4f5f570" : "none",
        }}
        onClick={() => setOpen(!open)}
      >
        <TableCell style={{ minWidth: "0px", padding: 0 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          {subscription.team_info
            ? subscription.team_info.team_name
            : "Not Yet Used"}
        </TableCell>
        <TableCell>
          {subscription.subscription_info
            ? subscription.subscription_info.packageName
            : ""}
        </TableCell>
        <TableCell style={{ textTransform: "capitalize" }}>
          {subscription.duration_details.duration.replaceAll("_", " ")}
        </TableCell>
        <TableCell style={{ minWidth: 150 }}>
          ₹{" "}
          {subscription.duration_details.price
            ? subscription.duration_details.price.$numberDecimal
            : 0}
        </TableCell>
        <TableCell style={{ minWidth: 200 }}>
          {moment(new Date()).isSameOrAfter(moment(subscription.start_date)) ? (
            <span className={classes.someSmallText}>
              {claculateRemaining(subscription)} Days Used
            </span>
          ) : (
            <span className={classes.someSmallText}>Renewed Pack</span>
          )}
          <ProgressBar
            variant="info"
            now={
              (claculateRemaining(subscription) /
                subscription.duration_details.days) *
              100
            }
            style={{ height: "8px" }}
          />

          {moment(new Date()).isSameOrAfter(moment(subscription.start_date)) ? (
            <span className={`table_text mt-2 ${classes.someSmallText}`}>
              <b style={{ color: "#0A1F41" }}>Exp. Date:</b>{" "}
              {getExpiryDate(subscription)}
            </span>
          ) : (
            <span className={`table_text mt-2 ${classes.someSmallText}`}>
              <b style={{ color: "#0A1F41" }}>start's At:</b>{" "}
              {moment(subscription.start_date).format("DD MMM YYYY")}
            </span>
          )}
        </TableCell>
        <TableCell>
          <Chip
            label={
              moment(new Date()).isSameOrBefore(moment(subscription.end_date))
                ? "Active"
                : "Inactive"
            }
            size="small"
            style={{
              backgroundColor: moment(new Date()).isSameOrBefore(
                moment(subscription.end_date)
              )
                ? "#E9F7EC"
                : "#fce3e5",
              color: moment(new Date()).isSameOrBefore(
                moment(subscription.end_date)
              )
                ? "#317D41"
                : "#ba1221",
              padding: "10px",
            }}
          />
        </TableCell>
      </TableRow>
      <TableRow
        className={`${classes.table_bg1} ${classes.childRow} mb-3`}
        style={{
          borderBottom: open ? "20px solid  #f4f5f570" : "none",
          display: open ? "table-row" : "none",
        }}
      >
        <TableCell colSpan={7} className={classes.ColspanMainTd}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table
                size="small"
                aria-label="purchases"
                className={classes.SubTable}
              >
                <TableHead>
                  <TableRow className={`${classes.TableSubRow}`}>
                    <TableCell>Start Date</TableCell>
                    <TableCell>Plan</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Invoice</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={0} className={`${classes.TableSubRow}`}>
                    <TableCell className="fw-medium">
                      <span>
                        {moment(subscription.start_date).format("LL")}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span>{subscription.subscription_info.packageName}</span>
                      <br />{" "}
                      <span
                        className={classes.someSmallText}
                        style={{ textTransform: "capitalize" }}
                      >
                        {subscription.duration_details.duration.replaceAll(
                          "_",
                          " "
                        )}{" "}
                        Subscription
                      </span>
                    </TableCell>
                    <TableCell>
                      <span>
                        ₹{" "}
                        {subscription.duration_details.price
                          ? (
                              subscription.duration_details.price
                                .$numberDecimal /
                              (subscription.duration_details.days / 30)
                            ).toFixed(2)
                          : 0}
                        /month
                      </span>
                    </TableCell>
                    <TableCell>
                      <a
                        href
                        role="button"
                        style={{ color: "#227DC3", fontWeight: 600 }}
                        onClick={() => handleSendInvoice()}
                      >
                        Send invoice by email
                      </a>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {modalToggle && (
        <InvoiceSendModel
          item={subscription}
          modalClose={CloseModal}
          value={modalToggle}
        />
      )}
    </React.Fragment>
  );
}

export default SubscriptionListItem;
