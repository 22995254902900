import React from "react";
import classes from "./customer.module.css";
import { TableRow, TableCell, Typography, Chip, Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { VictoryPie } from "victory";
import { VictoryLabel } from "victory-core";
import { stringAvatar, generateColor } from "../../../Utilities/commonfunctions"

function CustomerListItem(Props) {
    const data = [
        { x: "Expired", y: Props?.customer?.SubscriptionData.active },
        { x: "Expiring", y: Props?.customer?.SubscriptionData.expiry_soon },
        { x: "Active", y: Props?.customer?.SubscriptionData.expired },
    ];
    //console.log(Props.customer.company.company_name );

    const navigate = useNavigate();

    return (
        <TableRow
            className={`${classes.table_bg}`}
            onClick={() => navigate(`/customer/profile/${Props.customer._id}`)}
        >
            <TableCell>
                <Typography>
                    {" "}
                    {Props?.customer?.company[0]?.company_name}
                </Typography>
                <span>ID: MDI3547</span>
            </TableCell>
            <TableCell className={classes.AvatarContainer} style={{ height: "84px" }}>
                {Props?.customer?.user[0]?.profile_pic?.filename ?
                    <Avatar
                        alt="Remy Sharp"
                        sx={{ width: 36 }}
                        src={`${process.env.REACT_APP_IMAGE_URL}/${Props?.customer?.user[0]?.profile_pic?.destination}${Props?.customer?.user[0]?.profile_pic?.filename}`}
                    />
                    :
                    <Avatar
                        {...stringAvatar(`${Props?.customer?.first_name.trim()} ${Props?.customer?.last_name.trim()}`)}
                        sx={{
                            bgcolor: `${generateColor(`${Props?.customer?.first_name.trim()} ${Props?.customer?.last_name.trim()}`)}`,
                        }}
                        style={{ height: 36, width: 36, }}
                    />
                }
                <Typography className={classes.CustomerNameText}>
                    {" "}
                    {Props?.customer?.user[0]?.name}
                </Typography>
                {/* <img
                    src="assets/images/IM_Profile.png"
                    alt=""
                    className="avatar-xs rounded-circle me-2"
                /> */}
                {/* <a href="#javascript: void(0);" className="text-body align-middle">
                    {Props?.customer?.first_name}
                </a> */}
            </TableCell>
            <TableCell
                style={{ minWidth: "230px" }}
            >{`${Props?.customer?.company[0]?.address_one},${Props?.customer?.company[0]?.address_two}`}</TableCell>
            <TableCell style={{ textTransform: "capitalize" }}>
                {Props?.customer?.company[0]?.industry}
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
                <div className={classes.ChartContainer}>
                    <svg viewBox="0 0 400 400" width="52px">
                        <VictoryPie
                            standalone={false}
                            width={400}
                            height={400}
                            data={data.some(item => item.y !== 0) ? data : [{ x: "Closed", y: 1 }]}
                            innerRadius={200}
                            colorScale={data.some(item => item.y !== 0) ? ["#227DC3", "#240051", "#C52A38"] : ["#d3e7fb"]}
                            labels={() => null} // Hide labels
                        />
                        <VictoryLabel
                            textAnchor="middle"
                            verticalAnchor="middle"
                            x={200}
                            y={200}
                            text={[Props?.customer?.SubscriptionData.total]}
                            style={{ fontSize: 100 }}
                        />
                    </svg>
                    <div className={classes.hoverDiv}>
                        <Typography className={classes.displayFlex}>
                            <Avatar
                                sx={{
                                    bgcolor: `#C52A38`,
                                    width: "8px",
                                    height: "8px",
                                }}
                            >
                                {" "}
                            </Avatar>
                            <span className={classes.pieChartSubHEading}>Expired:</span>
                            <b>{Props?.customer?.SubscriptionData.expired}</b>
                        </Typography>
                        <Typography className={classes.displayFlex}>
                            <Avatar
                                sx={{
                                    bgcolor: `#240051`,
                                    width: "8px",
                                    height: "8px",
                                }}
                            >
                                {" "}
                            </Avatar>
                            <span className={classes.pieChartSubHEading}>Expiring Soon:</span>
                            <b>{Props?.customer?.SubscriptionData.expiry_soon}</b>
                        </Typography>
                        <Typography className={classes.displayFlex}>
                            <Avatar
                                sx={{
                                    bgcolor: `#227DC3`,
                                    width: "8px",
                                    height: "8px",
                                }}
                            >
                                {" "}
                            </Avatar>
                            <span className={classes.pieChartSubHEading}>Active :</span>
                            <b>{Props?.customer?.SubscriptionData.active}</b>
                        </Typography>
                    </div>
                </div>

            </TableCell>
            <TableCell style={{ textTransform: "capitalize" }}>
                {Props?.customer?.designation}
            </TableCell>
            <TableCell>{Props?.customer?.mobile_number}</TableCell>
            <TableCell>{Props?.customer?.email}</TableCell>
            <TableCell>{Props?.customer?.createdAt}</TableCell>
            <TableCell>
                <Chip
                    label={Props?.customer?.is_active ? "Active" : "Inactive"}
                    size="small"
                    style={{
                        backgroundColor: Props?.customer?.is_active ? "#E9F7EC" : "#FCE3E5",
                        color: Props?.customer?.is_active ? "#317D41" : "#BA1221",
                        padding: "10px",
                    }}
                />
            </TableCell>
        </TableRow>
    );
}

export default CustomerListItem;
