import {
  FETCH_SUBSCRIPTIONS_REQUEST,
  FETCH_SUBSCRIPTIONS_SUCCESS,
  FETCH_SUBSCRIPTIONS_FAILURE,
  CREATE_SUBSCRIPTION_REQUEST,
  CREATE_SUBSCRIPTION_SUCCESS,
  CREATE_SUBSCRIPTION_FAILURE,
  ACTIVATE_SUBSCRIPTION_REQUEST,
  ACTIVATE_SUBSCRIPTION_SUCCESS,
  ACTIVATE_SUBSCRIPTION_FAILURE,
  DEACTIVATE_SUBSCRIPTION_REQUEST,
  DEACTIVATE_SUBSCRIPTION_SUCCESS,
  DEACTIVATE_SUBSCRIPTION_FAILURE,
  SELECTED_SUBSCRIPTION,
  REMOVE_SUBSCRIPTION_REQUEST,
  REMOVE_SUBSCRIPTION_SUCCESS,
  REMOVE_SUBSCRIPTION_FAILURE,
} from "../constants/subscriptionConstants";

export const subscriptionsReducer = (
  state = { loading: true, subscriptions: [], subscription: [] },
  action
) => {
  switch (action.type) {
    case FETCH_SUBSCRIPTIONS_REQUEST:
      return { loading: true, subscriptions: [] };
    case FETCH_SUBSCRIPTIONS_SUCCESS:
      return { loading: false, subscriptions: action.payload };
    case SELECTED_SUBSCRIPTION:
      return { ...state, loading: false, subscription: action.payload };
    case FETCH_SUBSCRIPTIONS_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const createSubscriptionReducer = (
  state = { loading: false, success: false, error: null },
  action
) => {
  switch (action.type) {
    case CREATE_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case CREATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
      };
    case CREATE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case REMOVE_SUBSCRIPTION_REQUEST:
      return { loading: true };
    case REMOVE_SUBSCRIPTION_SUCCESS:
      return {
        loading: false,
        success: true,
        error: null,
        subscription: action.payload,
      };
    case REMOVE_SUBSCRIPTION_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const subscriptionActivatingReducer = (state = {}, action) => {
  switch (action.type) {
    case ACTIVATE_SUBSCRIPTION_REQUEST:
      return { loading: true };
    case ACTIVATE_SUBSCRIPTION_SUCCESS:
      return { loading: false, success: true, subscription: action.payload };
    case ACTIVATE_SUBSCRIPTION_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const subscriptionDeActivatingReducer = (state = {}, action) => {
  switch (action.type) {
    case DEACTIVATE_SUBSCRIPTION_REQUEST:
      return { loading: true };
    case DEACTIVATE_SUBSCRIPTION_SUCCESS:
      return { loading: false, success: true, subscription: action.payload };
    case DEACTIVATE_SUBSCRIPTION_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
