import React, { useState, useEffect } from "react";
import classes from "../customer.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  MenuItem,
  TextField,
} from "@mui/material";
import {
  fetchTeams,
  getOneTeamDetails,
} from "../../../../Redux/actions/customerActions";
import TeamsListItem from "./TeamsListItem";
import {
  OppositeSortArrawBtnIcons,
  SortArrawBtnIcons,
} from "../../../../Components/Icons/Icons";
import { handleSortClick } from "../../../../Utilities/commonfunctions";
import InvoiceFilter from "../../../../Components/InvoiceFilters";

const TeamList = (props) => {
  const dispatch = useDispatch();
  const [team, setTeam] = useState("select");

  const { sortBy, invoice } = useSelector((state) => state.filter);

  useEffect(() => {
    dispatch(fetchTeams(props.id));
  }, [props.id, dispatch]);

  useEffect(() => {
    dispatch(getOneTeamDetails(team, sortBy, invoice));
  }, [team, sortBy, invoice, dispatch]);

  const teamsData = useSelector((state) => state.customerData);

  const { loading, errorteams, teams, oneTeamData } = teamsData || {};

  useEffect(() => {
    console.log(oneTeamData);
  }, [oneTeamData]);

  return (
    <div className={classes.tableContainer}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <TextField
          sx={{
            "& legend": { display: "none" },
            "& fieldset": { top: 0 },
            width: 168,
          }}
          select
          fullWidth
          className={classes.inputs}
          value={team}
          onChange={(e) => {
            setTeam(e.target.value);
          }}
          required={true}
          size="small"
        >
          <MenuItem value="select">Select Team</MenuItem>
          {teams?.map((item, index) => (
            <MenuItem key={index} value={item._id}>
              {item?.team_name}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <Table className={classes.MainTable}>
        <TableHead>
          <TableRow className={classes.tableHeadRow}>
            <TableCell className={classes.tableHeadCell}>
              <div className={classes.tableHEadercelldiv}>
                <span> Team Member </span>{" "}
                {/* <FilterMenu option={Industries} name={CustomerIndustyFilter} />{" "} */}
                <span onClick={() => handleSortClick(dispatch, sortBy, "name")}>
                  {sortBy?.includes("desc") && sortBy?.includes("name") ? (
                    <OppositeSortArrawBtnIcons />
                  ) : (
                    <SortArrawBtnIcons />
                  )}
                </span>
              </div>
            </TableCell>
            <TableCell className={classes.tableHeadCell}>
              <span> Mobile Number </span>
            </TableCell>
            <TableCell className={classes.tableHeadCell}>
              <span> Registered On </span>
              <span
                onClick={() => handleSortClick(dispatch, sortBy, "createdAt")}
              >
                {sortBy?.includes("desc") && sortBy?.includes("createdAt") ? (
                  <OppositeSortArrawBtnIcons />
                ) : (
                  <SortArrawBtnIcons />
                )}
              </span>
            </TableCell>
            <TableCell className={classes.tableHeadCell}>
              Locations
              {/* <span onClick={() => handleSortClick(dispatch, sortBy, "name")}><SortArrawBtnIcons /></span> */}
            </TableCell>
            <TableCell className={classes.tableHeadCell}>
              <div className={classes.tableHEadercelldiv}>
                <span> Status </span> <InvoiceFilter />{" "}
                <span
                  onClick={() => handleSortClick(dispatch, sortBy, "is_active")}
                >
                  {sortBy?.includes("desc") && sortBy?.includes("is_active") ? (
                    <OppositeSortArrawBtnIcons />
                  ) : (
                    <SortArrawBtnIcons />
                  )}
                </span>
              </div>
            </TableCell>
            <TableCell
              className={classes.tableHeadCell}
              style={{ minWidth: 100 }}
            >
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <td colSpan="9" className="text-center">
                Loading...
              </td>
            </TableRow>
          ) : errorteams ? (
            <TableRow>
              <td colSpan="9" className="text-center">
                {errorteams}
              </td>
            </TableRow>
          ) : team === "select" ? (
            <TableRow>
              <td colSpan="9" className="text-center">
                Please Select team To view list
              </td>
            </TableRow>
          ) : oneTeamData &&
            oneTeamData.members &&
            oneTeamData.members.length > 0 ? (
            oneTeamData.members?.map((item, index) => (
              <TeamsListItem key={index} employee={item} team_id={team} />
            ))
          ) : (
            <TableRow>
              <td colSpan="9" className="text-center">
                No Data Found
              </td>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default TeamList;
