import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "../customer.module.css";
import { Typography, Card } from "@mui/material";
import { fetchTimeLine } from "../../../../Redux/actions/customerActions";
import moment from "moment";

const TimeLine = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTimeLine(props.id));
  }, [props.id, dispatch]);

  const ColoursArray = ["#FFEEEA", "#FFEAFF", "#FCFDD5", "#EDE7FF"];

  const timeLineData = useSelector((state) => state.customerData);

  const { loading, error, timeLine } = timeLineData || {};

  useEffect(() => {
    console.log(timeLine);
  }, [timeLine]);

  return (
    <div style={{ textAlign: "center" }}>
      {loading ? (
        <Typography>Loading...</Typography>
      ) : error ? (
        <Typography>{error}</Typography>
      ) : timeLine && timeLine.length > 0 ? (
        <Card style={{ padding: "20px", paddingBottom: "40px" }}>
          <div className={classes.stepper}>
            {timeLine.map((item, index) => {
              return (
                <div key={index} style={{ position: "relative" }}>
                  <div
                    key={index}
                    className={`${classes.step} ${
                      item.status === "reached" ? classes.completed : ""
                    }`}
                  >
                    <span className={classes.sub_step_label}>
                      {moment(item.createdAt).format("DD MMM YYYY")}
                    </span>
                    <span className={classes.step_number}></span>
                    <span
                      className={classes.step_label}
                      style={{ background: ColoursArray[index % 4] }}
                    >
                      {item.message}
                    </span>
                  </div>
                  {index < timeLine.length - 1 && (
                    <div
                      className={`${classes.RouteLine} ${
                        item.status === "reached" ? classes.Completed : ""
                      }`}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </Card>
      ) : (
        <Typography>No Data Found</Typography>
      )}
    </div>
  );
};

export default TimeLine;
