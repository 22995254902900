import {
  MY_PROFILE_DATA,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  GENERATE_EMAIL_OTP_REQUEST,
  GENERATE_EMAIL_OTP_SUCCESS,
  GENERATE_EMAIL_OTP_FAILURE,
  VERIFY_EMAIL_OTP_REQUEST,
  VERIFY_EMAIL_OTP_SUCCESS,
  VERIFY_EMAIL_OTP_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  TOGGLE_SIDEBAR,
  USERS_MANAGEMENT_LIST_REQUEST,
  USERS_MANAGEMENT_LIST_SUCCESS,
  USERS_MANAGEMENT_LIST_FAIL,
} from "../constants/userConstants";
import { SET_HEADER_NAME } from "../constants/userConstants";

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };
    case MY_PROFILE_DATA:
      return { ...state, loading: false, loggedUser: action.payload };
    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    case GENERATE_EMAIL_OTP_REQUEST:
      return { loading: true };
    case GENERATE_EMAIL_OTP_SUCCESS:
      return { ...state, loading: false, otp: action.payload };
    case GENERATE_EMAIL_OTP_FAILURE:
      return { loading: false, error: action.payload };
    case VERIFY_EMAIL_OTP_REQUEST:
      return { loading: true };
    case VERIFY_EMAIL_OTP_SUCCESS:
      return { ...state, loading: false, otp: action.payload };
    case VERIFY_EMAIL_OTP_FAILURE:
      return { loading: false, error: action.payload };
    case RESET_PASSWORD_REQUEST:
      return { loading: true };
    case RESET_PASSWORD_SUCCESS:
      return { ...state, loading: false, resetpassword: action.payload };
    case RESET_PASSWORD_FAILURE:
      return { loading: false, error: action.payload };
    case SET_HEADER_NAME:
      return { ...state, loading: false, headerName: action.payload };
    case TOGGLE_SIDEBAR:
      return { ...state, loading: false, togglesidebar: action.payload };
    case USERS_MANAGEMENT_LIST_REQUEST:
      return { ...state, loading: true };
    case USERS_MANAGEMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        admins: action.payload.admins,
        totalCount: action.payload.totalCount,
      };
    case USERS_MANAGEMENT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
