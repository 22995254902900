import React, { useEffect } from 'react'
import classes from "./contactUs.module.css"
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import {
    OppositeSortArrawBtnIcons,
    SortArrawBtnIcons,
} from "../../../Components/Icons/Icons";
import Pagination from "../../../Components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import FilterMenu from "../../../Components/customerPageFilter";
import { CustomerIndustyFilter } from "../../../Redux/actions/filterActions";
import { Industries } from "../../../Redux/constants/filterConstatns";
import ContactUsListItem from './contactUsListItem';
import { getContactUsList } from '../../../Redux/actions/contactUsAction';
import { handleSortClick } from '../../../Utilities/commonfunctions';

const TeamLeaderContactUs = ({ user_type }) => {
    const dispatch = useDispatch();
    const PaginationData = useSelector(
        (state) => state.paginationData.Pagination
    );
    const { rowCount, currentPage } = PaginationData || {};
    const feedbackList = useSelector((state) => state.contactUsData);
    const { loading, error, contactUsList, totalCount } = feedbackList || {};
    const { sortBy } = useSelector((state) => state.filter);

    useEffect(() => {
        dispatch(getContactUsList(currentPage, rowCount, sortBy));
    }, [
        dispatch,
        currentPage,
        rowCount,
        sortBy
    ]);

    useEffect(() => {
        console.log(contactUsList);
    }, [contactUsList])


    return (
        <div><div className={classes.TableContainer}>
            <Table className={classes.MainTable}>
                <TableHead>
                    <TableRow className={classes.tableHeadRow}>
                        <TableCell className={classes.tableHeadCell} style={{ width: "20%" }}>
                            <div className={classes.tableHEadercelldiv}>
                                <span> Contact Person Name </span>{" "}
                                {/* <FilterMenu
                                    option={Industries}
                                    name={CustomerIndustyFilter}
                                />{" "} */}
                                <span onClick={() => handleSortClick(dispatch, sortBy, "userInfo.name")}>
                                    {sortBy?.includes("desc") && sortBy?.includes("userInfo.name") ? (
                                        <OppositeSortArrawBtnIcons />
                                    ) : (
                                        <SortArrawBtnIcons />
                                    )}
                                </span>
                            </div>
                        </TableCell>
                        <TableCell className={classes.tableHeadCell} style={{ width: "37%" }}>
                            <div className={classes.tableHEadercelldiv}>
                                <span> Contact Details </span>{" "}
                                {/* <FilterMenu
                                    option={Industries}
                                    name={CustomerIndustyFilter}
                                />{" "} */}
                                <span onClick={() => handleSortClick(dispatch, sortBy, "subject")}>
                                    {sortBy?.includes("desc") && sortBy?.includes("subject") ? (
                                        <OppositeSortArrawBtnIcons />
                                    ) : (
                                        <SortArrawBtnIcons />
                                    )}
                                </span>
                            </div>
                        </TableCell>
                        <TableCell className={classes.tableHeadCell} style={{ width: "26%" }}>
                            <div className={classes.tableHEadercelldiv}>
                                <span> Documents </span>{" "}
                                {/* <FilterMenu
                                    option={Industries}
                                    name={CustomerIndustyFilter}
                                />{" "} */}
                            </div>
                        </TableCell>
                        <TableCell className={classes.tableHeadCell} style={{ width: "17%" }}>
                            <div className={classes.tableHEadercelldiv}>
                                <span> Date & Time </span>{" "}
                                <span onClick={() => handleSortClick(dispatch, sortBy, "createdAt")}>
                                    {sortBy?.includes("desc") && sortBy?.includes("createdAt") ? (
                                        <OppositeSortArrawBtnIcons />
                                    ) : (
                                        <SortArrawBtnIcons />
                                    )}
                                </span>
                            </div>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? (
                        <TableRow>
                            <td colSpan="9" className="text-center">
                                Loading...
                            </td>
                        </TableRow>
                    ) : error ? (
                        <TableRow>
                            <td colSpan="9" className="text-center">
                                {error}
                            </td>
                        </TableRow>
                    ) : contactUsList && contactUsList.length > 0 ? (
                        contactUsList.map((item, index) => (
                            <ContactUsListItem key={index} item={item} />
                        ))
                    ) : (
                        <TableRow>
                            <td colSpan="9" className="text-center">
                                No Data Found
                            </td>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>
            <Pagination total={totalCount} /></div>
    )
}

export default TeamLeaderContactUs;