export const CUSTOMER_REQUESTS = "CUSTOMER_LIST_REQUESTS";
export const CUSTOMER_LIST_SUCCESS = "CUSTOMER_LIST_SUCCESS";
export const CUSTOMER_LIST_FAIL = "CUSTOMER_LIST_FAIL";
export const CUSTOMER_ADD = "CUSTOMER_ADD";
export const CUSTOMER_SELECTED = "CUSTOMER_SELECTED";
export const SUBSCRIPTION_LIST = "SUBSCRIPTION_LIST";
export const TEAMS_LIST = "TEAMS_LIST";
export const TIMELINE_LIST = "TIMELINE_LIST";
export const AUDIT_TRIAL_LIST = "AUDIT_TRIAL_LIST";
export const CUSTOMER_TEAMS_FAIL = "CUSTOMER_TEAMS_FAIL";
export const CUSTOMER_UPDATED = "CUSTOMER_UPDATED";
export const CUSTOMER_DELETED = "CUSTOMER_DELETED";
export const CUSTOMER_LIST_PAGINATION = "CUSTOMER_LIST_PAGINATION";

export const CUSTOMER_PAGINATION_REQUESTS = "CUSTOMER_PAGINATION_REQUESTS";
export const CUSTOMER_PAGINATION_FAIL = "CUSTOMER_PAGINATION_FAIL";

export const CUSTOMER_LEADS_LIST_SUCCESS = "CUSTOMER_LEADS_LIST_SUCCESS";
export const REFERAL_REQUEST_FAIL = "REFERAL_REQUEST_FAIL";
export const REFERAL_REQUEST_SUCCESS = "REFERAL_REQUEST_SUCCESS";
export const REFERAL_REQUEST = "REFERAL_REQUEST";
export const INVOICE_REQUEST = "INVOICE_REQUEST";
export const INVOICE_SUCCESS = "INVOICE_SUCCESS";
export const INVOICE_FAIL = "INVOICE_FAIL";
export const INVOICE_ONE_SUCCESS = "INVOICE_ONE_SUCCESS";
export const INVOICE_ONE_FAIL = "INVOICE_ONE_FAIL";

export const COMPANY_LIST_REQUESTS = "COMPANY_LIST_REQUESTS";
export const COMPANY_LIST_SUCCESS = "COMPANY_LIST_SUCCESS";
export const COMPANY_LIST_FAIL = "COMPANY_LIST_FAIL";

export const ONE_TEAM_DETAILS_REQUEST = "ONE_TEAM_DETAILS_REQUEST";
export const ONE_TEAM_DETAILS_SUCCESS = "ONE_TEAM_DETAILS_SUCCESS";
export const ONE_TEAM_DETAILS_FAIL = "ONE_TEAM_DETAILS_FAIL";

export const DOWNLOAD_CUSTOMERS_LIST_REQUEST =
  "DOWNLOAD_CUSTOMERS_LIST_REQUEST";
export const DOWNLOAD_CUSTOMERS_LIST_SUCCESS =
  "DOWNLOAD_CUSTOMERS_LIST_SUCCESS";
export const DOWNLOAD_CUSTOMERS_LIST_FAIL = "DOWNLOAD_CUSTOMERS_LIST_FAIL";
