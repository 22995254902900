import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { paginationEdit } from "../Redux/actions/customerActions";
import { Button } from "react-bootstrap";
import classes from "./index.module.css";
import {
  DoubleNextRight,
  DoublePrevLeft,
  SingleNextRight,
  SinglePrevLeft,
} from "./Icons/Icons";

function Pagination(Props) {
  const PaginationData = useSelector(
    (state) => state.paginationData.Pagination
  );
  const { rowCount, currentPage } = PaginationData || {};

  const dispatch = useDispatch();
  const [page, setPage] = useState(currentPage ? currentPage : 1);
  const [rowCountEdit, setRowCountEdit] = useState(rowCount ? rowCount : 10);

  let maxPage = Math.ceil(Props.total / rowCountEdit);

  let currentEntryStart = page * rowCountEdit - rowCountEdit + 1;

  let currentEntryEnd = page * rowCountEdit;

  if (currentEntryEnd > Props.total) {
    currentEntryEnd = Props.total;
  }

  const onNext = () => {
    if (page < maxPage) {
      setPage(page + 1);
    }
  };

  const onBack = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    dispatch(paginationEdit(rowCountEdit, page));
  }, [rowCountEdit, page, dispatch]);

  return (
    <div className="d-flex justify-content-end mt-2">
      <div className="pagination-wrap hstack gap-2">
        {Props.from !== "supportTicket" ?
          <Typography className="mb-0 text-muted text">
            Showing <b> {currentEntryStart} </b> to <b>{currentEntryEnd}</b> of{" "}
            <b>{Props.total}</b> entries
          </Typography> : ""}
        <Button
          onClick={() => setPage(1)}
          className={`page-item pagination-prev ${classes.paginationbtn}`}
        >
          <span>
            <DoublePrevLeft />
          </span>
        </Button>
        <Button
          onClick={() => onBack()}
          className={`page-item pagination-prev ${classes.paginationbtn}`}
        >
          <span>
            <SinglePrevLeft />
          </span>
        </Button>
        {/* <a
          className="page-item pagination-prev "
          href="#"
          onClick={() => setPage(1)}
        >
          <i className="bi bi-chevron-double-left" />
        </a>
        <a
          className="page-item pagination-prev "
          href="#"
          onClick={() => onBack()}
        >
          <i className="bi bi-chevron-compact-left" />
        </a> */}

        <Typography className="page" href="#">
          {page}
        </Typography>
        <Button
          onClick={() => onNext()}
          className={`page-item pagination-next ${classes.paginationbtn}`}
        >
          <span>
            <SingleNextRight />
          </span>
        </Button>
        <Button
          onClick={() => onNext()}
          className={`page-item pagination-next ${classes.paginationbtn}`}
        >
          <span>
            <DoubleNextRight />
          </span>
        </Button>
        {/* <a
          className="page-item pagination-next"
          href="#"
          onClick={() => setPage(maxPage)}
        >
          <i className="bi bi-chevron-compact-right" />
        </a>
        <a
          className="page-item pagination-next"
          href="#"
          onClick={() => setPage(maxPage)}
        >
          <i className="bi bi-chevron-double-right" />
        </a> */}
        <span className="page-item ">
          <select
            className="form-select bg-white"
            aria-label=" select example"
            defaultValue={rowCount ? rowCount : 10}
            onChange={(e) => setRowCountEdit(e.target.value)}
            onClick={(e) => e.preventDefault()}
          >
            <option value={10}>10</option>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
          </select>
        </span>
      </div>
    </div>
  );
}

export default Pagination;
