import {
  CUSTOMER_LIST_SUCCESS,
  CUSTOMER_REQUESTS,
  CUSTOMER_LIST_FAIL,
  CUSTOMER_SELECTED,
  CUSTOMER_UPDATED,
  SUBSCRIPTION_LIST,
  AUDIT_TRIAL_LIST,
  TEAMS_LIST,
  TIMELINE_LIST,
  CUSTOMER_LEADS_LIST_SUCCESS,
  CUSTOMER_TEAMS_FAIL,
  REFERAL_REQUEST_FAIL,
  REFERAL_REQUEST_SUCCESS,
  INVOICE_SUCCESS,
  INVOICE_FAIL,
  INVOICE_ONE_SUCCESS,
  INVOICE_ONE_FAIL,
  COMPANY_LIST_REQUESTS,
  COMPANY_LIST_SUCCESS,
  COMPANY_LIST_FAIL,
  ONE_TEAM_DETAILS_REQUEST,
  ONE_TEAM_DETAILS_SUCCESS,
  ONE_TEAM_DETAILS_FAIL,
  DOWNLOAD_CUSTOMERS_LIST_REQUEST,
  DOWNLOAD_CUSTOMERS_LIST_SUCCESS,
  DOWNLOAD_CUSTOMERS_LIST_FAIL,
} from "../constants/customerConstants";
import { USERS_MANAGEMENT_LIST_FAIL, USERS_MANAGEMENT_LIST_SUCCESS } from "../constants/userConstants";

const initialState = {
  loading: true,
  customers: [],
  customer: {},
  subscriptions: [],
  teams: [],
  timeLine: [],
  auditTrials: [],
  leads: [],
  referals: null,
  invoicesList: [],
  users: [],
  companiesList: [],
  error: null,
  errorteams: null,
  oneInvoice: null,
  oneTeamData: null,
  errorUsers: null,
  totalCount: 0,
  totalCountLeads: 0,
};

export const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMER_REQUESTS:
      return { ...state, loading: true };

    case CUSTOMER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        customers: action.payload.customers,
        totalCount: action.payload.totalCount,
        error: null,
      };
    case CUSTOMER_LEADS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        leads: action.payload.customers,
        totalCountLeads: action.payload.totalCount,
        error: null,
      };
    case CUSTOMER_SELECTED:
      return {
        ...state,
        loading: false,
        customer: action.payload,
      };
    case CUSTOMER_UPDATED:
      return {
        ...state,
        loading: false,
        customer: action.payload.customer,
      };
    case SUBSCRIPTION_LIST:
      return {
        ...state,
        loading: false,
        subscriptions: action.payload,
      };
    case TEAMS_LIST:
      return {
        ...state,
        loading: false,
        teams: action.payload,
      };
    case AUDIT_TRIAL_LIST:
      return {
        ...state,
        loading: false,
        auditTrials: action.payload,
      };
    case TIMELINE_LIST:
      return {
        ...state,
        loading: false,
        timeLine: action.payload,
      };
    case CUSTOMER_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CUSTOMER_TEAMS_FAIL:
      return {
        ...state,
        loading: false,
        errorteams: action.payload,
      };
    case REFERAL_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        referals: action.payload.customers,
        error: null,
        totalCount: action.payload.totalCount,
      };
    case REFERAL_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        invoicesList: action.payload.invoices,
        totalCount: action.payload.totalCount,
        error: null,
      };
    case INVOICE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case INVOICE_ONE_SUCCESS:
      return {
        loading: false,
        oneInvoice: action.payload,
      };
    case INVOICE_ONE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case USERS_MANAGEMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        customers: action.payload.users,
        totalCount: action.payload.totalCount,
        error: null,
      };
    case USERS_MANAGEMENT_LIST_FAIL:
      return {
        ...state,
        loading: false,
        errorUsers: action.payload,
      };
    case COMPANY_LIST_REQUESTS:
      return { ...state, loading: true };

    case COMPANY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        companiesList: action.payload,
        error: null,
      };
    case COMPANY_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ONE_TEAM_DETAILS_REQUEST:
      return { ...state, loading: true };
    case ONE_TEAM_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        oneTeamData: action.payload,
        error: null,
      }
    case ONE_TEAM_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case DOWNLOAD_CUSTOMERS_LIST_REQUEST:
      return { ...state, loading: true };
    case DOWNLOAD_CUSTOMERS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      }
    case DOWNLOAD_CUSTOMERS_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state;
  }
};
