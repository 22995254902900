import React, { useEffect } from 'react';
import classes from "./blogs.module.css";
import { Paper, Typography, Button, MenuItem, Select, Chip } from '@mui/material';
import { MdClose } from 'react-icons/md';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BrudCrumbBackIcon, BrudCrumbNextIcon, RoundAddIcon } from '../../../Components/Icons/Icons';
import { useState } from 'react';
import { createBlogs, getOneBlog, getTagsList, updateBlogs } from '../../../Redux/actions/blogsActions';
import Basic from './DragAndDrop';
import { authImageApi, getAuthorizationHeader } from '../../../api/api';
import { UPLOAD_IMAGE } from '../../../api/api-urls';

const CreateBlogsPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [addTag, setAddTag] = useState(false);
    const [heading, setHeading] = useState("");
    const [subject, setSubject] = useState("");
    const [details, setDetails] = useState("");
    const [newChip, setNewChip] = useState("");
    const [images, setImages] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const { tagsList, oneBlog } = useSelector((state) => state.blogsData);

    const handleChange = (event) => {
        const newSelected = event.target.value.filter((value) => value !== undefined && value !== "");
        console.log(newSelected);
        setSelectedItems(newSelected);
    };

    useEffect(() => {
        setHeading(oneBlog?.heading);
        setSubject(oneBlog?.subject);
        setDetails(oneBlog?.message);
        if (oneBlog?.tags && oneBlog?.tags.length > 0) {
            setSelectedItems(oneBlog?.tags);
        }
        if (oneBlog?.images && oneBlog?.images.length > 0) {
            console.log(oneBlog?.images);
            setImages(oneBlog?.images);
            setSelectedFiles(oneBlog?.images);
        }
        // setSelectedFiles(oneBlog?.images);
    }, [oneBlog]);

    const handleChipDelete = (e, itemToDelete) => {
        const array = selectedItems.filter((item) => item !== itemToDelete)
        console.log(array);
        setSelectedItems(array);
    };

    const CreateimageArray = async (items) => {
        const filterArray1 = await items?.filter(
            (item) => !item.destination && !item.filename
        );
        const filterArray2 = await items?.filter(
            (item) => item.destination && item.filename
        ) || [];
        const array = filterArray1?.map(async (file) => {
            try {
                let formData = new FormData();
                formData.append("file", file);
                const { data } = await authImageApi.post(UPLOAD_IMAGE, formData, {
                    headers: { Authorization: getAuthorizationHeader() },
                });
                return data.data;
            }
            catch (err) {
                console.log("error ====> ", err);
            }
        });
        if (array && array.length > 0) {
            const results = await Promise.all(array);
            const finalArray = [...filterArray2, ...results];
            return finalArray;
        }
        else {
            return [...filterArray2];
        }
        //return results;
        // setFiles([...files, ...results]);
        // dispatch(saveImageList([...files, ...results]));
    };

    const handleSubmit = async () => {
        var Buildimages = selectedFiles && selectedFiles.length > 0 ? await CreateimageArray(selectedFiles) : [];
        const userEntry = {
            heading: heading,
            subject: subject,
            message: details,
            images: Buildimages,
            tags: selectedItems,
        }
        console.log(userEntry);
        if (oneBlog) {
            dispatch(updateBlogs(userEntry, oneBlog._id));
        } else {
            dispatch(createBlogs(userEntry));
        }
        navigate("/blogs");
    }

    const onDropFunction = (acceptedFiles) => {
        setSelectedFiles(acceptedFiles);
    };

    useEffect(() => {
        dispatch(getTagsList());
    }, [dispatch])

    useEffect(() => {
        if (window.location.pathname.includes("/blogs/edit-blog")) {
            const path = window.location.pathname;
            const arr = path.split("/");
            dispatch(getOneBlog(arr[arr.length - 1]));
        }
    }, [dispatch])

    const handleAddChip = () => {
        const array = [...selectedItems, newChip];
        console.log(array);
        setSelectedItems(array);
        setAddTag(false);
        setNewChip("");
    }
    return (
        <div>
            <div className={classes.brudCrumb}>
                <span onClick={() => navigate(-1)}><BrudCrumbBackIcon /></span>
                <span onClick={() => navigate("/blogs")}>Blogs</span>
                <span><BrudCrumbNextIcon /></span>
                <span>{window.location.pathname.includes("/blogs/edit-blog") ? "Edit Blog" : "Create Blog"}</span>
            </div>
            <Paper style={{ padding: "20px", marginTop: "20px" }}>
                <div>
                    <Typography className={`mb-4 ${classes.FormHEader}`}>{window.location.pathname.includes("/blogs/edit-blog") ? "Edit Blogs" : "Create Blogs"}</Typography>
                    <ValidatorForm
                        useref="form"
                        onSubmit={handleSubmit}
                        style={{ width: "100%" }}
                        className={classes.InputForm}
                    >
                        <div>
                            <Typography className={classes.formLable}>Heading</Typography>
                            <TextValidator
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                    marginBottom: "15px",
                                    width: "100%",
                                }}
                                id="emailidInput"
                                value={heading}
                                onChange={(e) => {
                                    setHeading(e.target.value);
                                }}
                                size="small"
                                validators={["required"]}
                                errorMessages={["This field is required"]}
                            />
                        </div>
                        <div>
                            <Typography className={classes.formLable}>Tags</Typography>
                            <Select
                                sx={{ marginBottom: "15px", }}
                                multiple
                                fullWidth
                                size="small"
                                value={selectedItems}
                                onChange={handleChange}
                                MenuProps={{ classes: { paper: classes.menuPaper } }}
                                renderValue={() => (
                                    <div className={classes.chips_container}>
                                        {selectedItems && selectedItems.length > 0 && selectedItems.map((item, index) => (
                                            <Chip
                                                key={index}
                                                label={item?.company_name ? item.company_name : item?.replace("_", " ")}
                                                deleteIcon={
                                                    <MdClose
                                                        onMouseDown={(event) => event.stopPropagation()}
                                                    />
                                                }
                                                onDelete={(e) => handleChipDelete(e, item)}
                                                className={classes.chipBtn}
                                            />
                                        ))}
                                    </div>
                                )}
                            >
                                {addTag ?
                                    <MenuItem key={"add"} className={classes.newchipAdderContainer} value={newChip}>
                                        <TextValidator
                                            sx={{
                                                "& legend": { display: "none" },
                                                "& fieldset": { top: 0 },
                                            }}
                                            id="emailidInput"
                                            fullWidth
                                            value={newChip}
                                            onChange={(e) => {
                                                setNewChip(e.target.value);
                                            }}
                                            size="small"
                                        />
                                        <Button variant='contained' onClick={() => handleAddChip()}>
                                            Add
                                        </Button>
                                    </MenuItem>
                                    :
                                    <MenuItem key={"Add1"} onClick={(e) => { e.stopPropagation(); setAddTag(true) }} className={classes.AddDepMainBtn}>
                                        <span><RoundAddIcon /></span>
                                        <span>Add New Tag</span>
                                    </MenuItem>
                                }
                                {/* <div className={classes.CommunicationDropDownmain}> */}
                                {tagsList && tagsList.length > 0 && tagsList.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option.replace("_", " ")}
                                    </MenuItem>
                                ))}
                                {/* </div> */}
                            </Select>
                        </div>
                        <div>
                            <Typography className={classes.formLable}>Sub Heading</Typography>
                            <TextValidator
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                    marginBottom: "15px",
                                    width: "100%",
                                }}
                                id="emailidInput"
                                value={subject}
                                onChange={(e) => {
                                    setSubject(e.target.value);
                                }}
                                size="small"
                                validators={["required"]}
                                errorMessages={["This field is required"]}
                            />
                        </div>
                        <div>
                            <Typography className={classes.formLable}>Details</Typography>
                            <TextValidator
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                    marginBottom: "15px",
                                }}
                                id="emailidInput"
                                fullWidth
                                multiline
                                rows={4}
                                value={details}
                                onChange={(e) => {
                                    setDetails(e.target.value);
                                }}
                                size="small"
                                validators={["required"]}
                                errorMessages={["This field is required"]}
                            />
                        </div>
                        <div>
                            <div className={classes.DropZoneContainer}>
                                {window.location.pathname.includes("/blogs/edit-blogs") ? (
                                    <div>
                                        {images && images.length > 0 ? (
                                            <Basic
                                                images={images}
                                                onDropFunction={onDropFunction}
                                            />
                                        ) : <Basic onDropFunction={onDropFunction} />}
                                    </div>
                                ) : (
                                    <Basic onDropFunction={onDropFunction} />
                                )}
                                {/* <input
                    type="file"
                    onChange={(e) => console.log(e.target.files[0])}
                  /> */}
                            </div>
                        </div>
                        <div className={`mt-5 ${classes.AddUSerBtnSection}`}>
                            <Button variant="outlined" className={classes.AdduserMainBtn}>
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                type="submit"
                                className={classes.AdduserMainBtn}
                            >
                                Submit
                            </Button>
                        </div>

                    </ValidatorForm>
                </div>
            </Paper>
        </div>
    )
}

export default CreateBlogsPage