import React from "react";
import classes from "./referral.module.css";
import Box from "@mui/material/Box";
import moment from "moment";
import {
  Collapse,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const ReferralsListItems = ({ referral }) => {

  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        className={`${classes.table_bg1}`}
        style={{
          borderBottom: !open ? "20px solid  #f4f5f570" : "none",
        }}
        onClick={() => setOpen(!open)}
      >
        <TableCell style={{ minWidth: "0px", padding: 0, width: 10 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <span>
            {referral.company_details.company_name}
          </span>
          <br />
          <span className={classes.someSmallText}>
            ID:{referral.company_details._id?.slice(0, 6)}
          </span>
        </TableCell>
        <TableCell>{`${referral.first_name} ${referral.last_name}`}</TableCell>
        <TableCell>{referral.my_referral_code}</TableCell>
        <TableCell>{referral.mobile_number}</TableCell>
        <TableCell>{referral.children?.length || 0}</TableCell>
        <TableCell>
          <Chip
            label={"Active"}
            size="small"
            style={{
              backgroundColor: "#E9F7EC",
              color: "#317D41",
              padding: "10px",
            }}
          />
        </TableCell>
      </TableRow>
      <TableRow
        className={`${classes.table_bg1} ${classes.childRow} mb-3`}
        style={{
          borderBottom: open ? "20px solid  #f4f5f570" : "none",
          display: open ? "table-row" : "none",
        }}
      >
        <TableCell colSpan={7} className={classes.ColspanMainTd}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table
                size="small"
                aria-label="purchases"
                className={classes.SubTable}
              >
                <TableHead>
                  <TableRow className={`${classes.TableSubRow}`}>
                    <TableCell>Subscribing Date</TableCell>
                    <TableCell>Invited Contact</TableCell>
                    <TableCell>Company Name</TableCell>
                    <TableCell>Mobile number</TableCell>
                    <TableCell>Plan Subscribed</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {referral.children &&
                    referral.children.length > 0 &&
                    referral.children.map((child, index) => (
                      <TableRow
                        key={index}
                        className={`${classes.TableSubRow}`}
                      >
                        <TableCell className="fw-medium">
                          <span>
                            {moment(
                              child?.latest_subscription?.createdAt
                            ).format("DD MMM YYYY")}
                          </span>
                        </TableCell>
                        <TableCell>
                          <span>{`${child.first_name} ${child.last_name}`}</span>
                        </TableCell>
                        <TableCell>
                          <span>{child?.company_details?.company_name}</span>
                          <br />{" "}
                          <span className={classes.someSmallText}>
                            ID: {child?.company_details?._id?.slice(0, 6)}
                          </span>
                        </TableCell>
                        <TableCell>
                          <span>{child.mobile_number}</span>
                        </TableCell>
                        <TableCell>
                          <span>
                            {child && child.latest_subscription
                              ? child.subscription_details?.packageName
                              : "not subscribed"}
                            {/* {child?.latest_subscription?.subscription_id} */}
                          </span>
                          <br />
                          <span className={classes.someSmallText} style={{ textTransform: "capitalize" }}>
                            {`${child?.latest_subscription?.duration_details?.duration} Subscription`}
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default ReferralsListItems;
