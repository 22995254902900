import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import classes from "../index.module.css";
import "../../Assets/css/nonModule.css";
import { Navigate } from "react-router-dom";
import SideNavBar from "../Sidebar";
import Header from "../Header";
import mqtt from "precompiled-mqtt";
import Cookies from "js-cookie";

// use redux provider to get the state
import { useSelector } from "react-redux";

const DefaultLayout = () => {
  const navigate = useNavigate();
  const togglesidebar = useSelector((state) => state.userLogin.togglesidebar);
  const currentUser = JSON.parse(sessionStorage.getItem("userInfo"));
  let client;
  // const currentUser = JSON.parse(sessionStorage.getItem("userInfo"));
  // if (!currentUser || !currentUser.token) {
  //   return <Navigate to="/login" />;
  // }

  useEffect(() => {
    // const currentUser = JSON.parse(sessionStorage.getItem("userInfo"));
    if (!currentUser || !currentUser.token) {
      return <Navigate to="/login" />;
    }
    getMqttData();
  }, [currentUser]);

  const getMqttData = () => {
    const clientId = `clientId-${Math.random().toString(16).substr(2, 8)}`;
    if (
      !client?.connected ||
      !client ||
      client === undefined ||
      client === null
    ) {
      client = new mqtt.connect(
        "wss://61fb18464c2a4b33b326201681de805e.s1.eu.hivemq.cloud:8884/mqtt",
        {
          clientId: clientId,
          username: "teamplayer",
          password: "Admin@123",
        }
      );
    }
    client.on("connect", () => {
      console.log("Connected to MQTT broker");
      try {
        client.subscribe(`App/user/Deleted/${currentUser._id}`);
      } catch (err) {
        console.log(err);
      }
    });

    client.on("message", (topic, message) => {
      try {
        console.log("It is working, Message received: ", message.toString());
        sessionStorage.removeItem("userInfo");
        Cookies.remove("userToken");
        navigate("/login");
      } catch (err) {
        console.log(err, message.toString());
      }
    });
  };

  useEffect(() => {
    const timer = setInterval(() => {
      // console.log("It is working");
      // checkJwt(dispatch, currentUser._id);
    }, 10000); // 30 minutes in milliseconds

    return () => clearInterval(timer);
  }, [currentUser]);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Header />
      </div>
      <div className={classes.mainLayout}>
        <div className={togglesidebar ? classes.sidebar : classes.smallSidebar}>
          <SideNavBar />
        </div>
        <div className={classes.content}>
          <div className={classes.scrollableOutlet}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultLayout;
