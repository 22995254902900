import { ACTIVE_CUSTOMER_PIECHART_FAIL, ACTIVE_CUSTOMER_PIECHART_REQUEST, ACTIVE_CUSTOMER_PIECHART_SUCCESS, SUBSCRIPTION_PIECHART_DETAILS, SUPPORT_TICKET_PIECHART_DETAILS } from "../constants/piechartConstants";


const initialState = {
    loading: true,
    ativeCustomer: null,
    subscriptionpiechart: null,
    supportTicket: null,
    error: null,
};

export const PiechartReducers = (state = initialState, action) => {
    switch (action.type) {
        case ACTIVE_CUSTOMER_PIECHART_REQUEST:
            return { ...state, loading: true };

        case ACTIVE_CUSTOMER_PIECHART_SUCCESS:
            return {
                ...state,
                loading: false,
                ativeCustomer: action.payload,
                error: null,
            };
        case ACTIVE_CUSTOMER_PIECHART_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case SUBSCRIPTION_PIECHART_DETAILS:
            return {
                ...state,
                loading: false,
                subscriptionpiechart: action.payload,
                error: null,
            };
        case SUPPORT_TICKET_PIECHART_DETAILS:
            return {
                ...state,
                loading: false,
                supportTicket: action.payload,
                error: null,
            };
        default:
            return state;
    }
};