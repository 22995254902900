import React, { useState } from 'react'
import successimage from "../../../Assets/images/icon2.png";
import classes from "./customer.module.css";
import { MdClose } from "react-icons/md";
import { Modal, Box, Button, Typography } from "@mui/material";

const SuccessModal = (props) => {
    const rootRef = React.useRef(null);
    const [modalToggle, setModalToggle] = useState(true);
    const modalClose = () => {
        props.modalClose(false);
        setModalToggle(false);
    };

    return (
        <div>
            <Modal
                open={modalToggle}
                onClose={() => modalClose()}
                aria-labelledby="server-modal-title"
                data-testid="register_modal"
                aria-describedby="server-modal-description"
                sx={{
                    display: "flex",
                    height: 480,
                    marginTop: 10,
                    justifyContent: "center",
                }}
                container={() => rootRef.current}
            >
                <Box
                    sx={{
                        position: "relative",
                        bgcolor: "background.paper",
                        borderRadius: 1,
                        boxShadow: (theme) => theme.shadows[5],
                    }}
                    style={{ width: "500px", height: "max-content" }}
                    className={classes.loginModalWidth}
                >
                    <Button
                        variant="text"
                        sx={{ minWidth: "unset" }}
                        className={classes.closebtn}
                        onClick={() => modalClose()}
                    >
                        <MdClose />
                    </Button>
                    <div>
                        <div className={classes.successPageMainDiv}>
                            <img
                                src={successimage}
                                alt="Success logo"
                                width={"auto"}
                                height={"auto"}
                            />
                            <Typography className={classes.imagePageText}>
                                Congratulations!
                            </Typography>
                            <Typography className={classes.successPageSubText}>
                                An SMS has been sent to customer’s registered mobile number to complete the registration process.
                            </Typography>
                            <Button
                                variant='contained'
                                onClick={() => modalClose()}
                                className={classes.AdduserMainBtn}
                                style={{ marginTop: "15px", width: "100%" }}
                            >
                                Okay
                            </Button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default SuccessModal;