import { authApi, getAuthorizationHeader } from "../../api/api";
import { ACTIVE_CUSTOMER_PIECHART_FAIL, ACTIVE_CUSTOMER_PIECHART_REQUEST, ACTIVE_CUSTOMER_PIECHART_SUCCESS, SUBSCRIPTION_PIECHART_DETAILS, SUPPORT_TICKET_PIECHART_DETAILS } from "../constants/piechartConstants";

export const getCustomerPieChartDetails =
    (startDate, endDate) => async (dispatch) => {
        try {
            dispatch({
                type: ACTIVE_CUSTOMER_PIECHART_REQUEST,
            });
            console.log(startDate, endDate);
            const { data } = await authApi.get(
                `/piechart/active-customer?start_date=${startDate}&end_date=${endDate}`,
                {
                    headers: { Authorization: getAuthorizationHeader() },
                }
            );
            // console.log(data);
            dispatch({
                type: ACTIVE_CUSTOMER_PIECHART_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: ACTIVE_CUSTOMER_PIECHART_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
        }
    };

export const getSubscriptionPieChartDetails =
    (startDate, endDate) => async (dispatch) => {
        try {
            dispatch({
                type: ACTIVE_CUSTOMER_PIECHART_REQUEST,
            });

            const { data } = await authApi.get(
                `/piechart/subscription?start_date=${startDate}&end_date=${endDate}`,
                {
                    headers: { Authorization: getAuthorizationHeader() },
                }
            );
            // console.log(data);
            dispatch({
                type: SUBSCRIPTION_PIECHART_DETAILS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: ACTIVE_CUSTOMER_PIECHART_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
        }
    };

export const getSupportTicketPieChartDetails =
    (startDate, endDate) => async (dispatch) => {
        try {
            dispatch({
                type: ACTIVE_CUSTOMER_PIECHART_REQUEST,
            });

            const { data } = await authApi.get(
                `/piechart/support-ticket?start_date=${startDate}&end_date=${endDate}`,
                {
                    headers: { Authorization: getAuthorizationHeader() },
                }
            );
            // console.log(data);
            dispatch({
                type: SUPPORT_TICKET_PIECHART_DETAILS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: ACTIVE_CUSTOMER_PIECHART_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
        }
    };