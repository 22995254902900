import { authApi, getAuthorizationHeader } from "../../api/api";
import { ACTIVE_CUSTOMERS_REPORT_LIST_FAIL, ACTIVE_CUSTOMERS_REPORT_LIST_REQUEST, ACTIVE_CUSTOMERS_REPORT_LIST_SUCCESS } from "../constants/reportConstants";

export const fetchActiveCustomersReport =
    (page, limit, start_date, end_date, sort) => async (dispatch) => {
        try {
            dispatch({
                type: ACTIVE_CUSTOMERS_REPORT_LIST_REQUEST,
            });

            const { data } = await authApi.get(
                `/report/get-active-customer?page=${page}&limit=${limit}&start_date=${start_date}&end_date=${end_date}&sort=${sort}`,
                {
                    headers: { Authorization: getAuthorizationHeader() },
                }
            );

            dispatch({
                type: ACTIVE_CUSTOMERS_REPORT_LIST_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: ACTIVE_CUSTOMERS_REPORT_LIST_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
        }
    };


export const DownloadActiveCustomersReport = (startDate, endDate, type) => async (dispatch) => {
    try {
        const { data } = await authApi.get(
            `report/download-active-customer?start_date=${startDate}&end_date=${endDate}&type=${type}`,
            {
                headers: { Authorization: getAuthorizationHeader() },
            }
        );
        // console.log(data);
        const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        console.log(blob);

        // Create a URL for the blob
        const backendUrl = `${process.env.REACT_APP_API_URL}/report/download-active-customer?start_date=${startDate}&end_date=${endDate}&type=${type}`;

        // Create a link element
        const a = document.createElement("a");
        a.href = backendUrl;
        a.download = "customersReport.xlsx"; // Optional: Set the desired file name
        document.body.appendChild(a);

        // Trigger a click event on the link to initiate the download
        a.click();
    } catch (error) {
        console.log(error);
    }
};