import React from "react";
import classes from "./customer.module.css"
import {
  TableCell,
  TableRow,
  Avatar,
} from "@mui/material";
import { generateColor, stringAvatar } from "../../../Utilities/commonfunctions";

const LeadListItem = (Props) => {
  return (
    <TableRow className={`${classes.table_bg}`}>
      <TableCell>
        <p className="mb-0"></p>
        {Props.leads.companyInfo.company_name}
      </TableCell>
      <TableCell>
        <div className="d-flex align-items-center" style={{ columnGap: '10px' }}>
          <Avatar
            {...stringAvatar(`${Props.leads.first_name.trim().toUpperCase()} ${Props.leads.last_name.trim().toUpperCase()}`)}
            sx={{
              bgcolor: `${generateColor(`${Props.leads.first_name} ${Props.leads.last_name}`)}`,
            }}
          />
          <span
            className="text-body align-middle"
            style={{ textDecoration: "none", textTransform: "capitalize" }}
          >{`${Props.leads.first_name} ${Props.leads.last_name}`}</span>
        </div>
      </TableCell>
      <TableCell>{Props.leads.designation}</TableCell>
      <TableCell>{Props.leads.mobile_number}</TableCell>
      <td>{Props.leads.email}</td>
    </TableRow>
  );
};

export default LeadListItem;
