import React, { useState } from "react";
import classes from "./blogs.module.css";
import {
    TableCell,
    TableRow,
    Typography,
    Avatar,
    Chip,
    Button,
    Menu,
    MenuItem,
} from "@mui/material";
import { BsThreeDotsVertical } from "react-icons/bs";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DeleteselectedBlog } from "../../../Redux/actions/blogsActions";

export const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
        <Typography className={classes.SomeOtheText}>
            {isReadMore ? text.slice(0, 150) : text}
            {text.length > 150 ? (
                <span
                    onClick={toggleReadMore}
                    className={classes.ReadMoreToggleButton}
                    style={{ marginLeft: "10px" }}
                >
                    {isReadMore ? "View More" : " Show less"}
                </span>
            ) : null}
        </Typography>
    );
};

function BlogsListItem(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuclick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = (id) => {
        dispatch(DeleteselectedBlog(id));
        handleClose();
    };

    return (
        <TableRow className={`${classes.table_bg1}`}>
            <TableCell style={{ verticalAlign: "top" }}>
                <div className={classes.displayFlex}>
                    <Avatar
                        src={`${process.env.REACT_APP_IMAGE_URL}/${props.item.userInfo?.profile_pic?.destination}/${props.item.userInfo?.profile_pic?.filename}`}
                        alt=""
                        className="avatar-xs rounded-circle me-2"
                    />
                    <div>
                        <Typography
                            href="#javascript: void(0);"
                            className={classes.TableCellMainText}
                        >
                            {props.item.userInfo.name}
                        </Typography>
                    </div>
                </div>
            </TableCell>
            <TableCell style={{ verticalAlign: "top" }}>
                {props.item.tags && props.item.tags.length > 0 && props.item.tags.map((tag, index) => (
                    <Chip
                        key={index}
                        label={tag.replace("_", " ")}
                        className={classes.chipBtn}
                    />
                ))}
            </TableCell>
            <TableCell>
                <div>
                    {/* <div className={`mb-3 ${classes.feedbackEmojiContainer}`}>
                        <img
                            src={poor}
                            alt="Poor"
                            width={16}
                            height={16}
                            className={props.item.rating === 1 ? "" : classes.feedbackEmoji}
                        />
                        <img
                            src={unhappy}
                            alt="unhappy"
                            width={16}
                            height={16}
                            className={props.item.rating === 2 ? "" : classes.feedbackEmoji}
                        />
                        <img
                            src={apathy}
                            alt="apathy"
                            width={16}
                            height={16}
                            className={props.item.rating === 3 ? "" : classes.feedbackEmoji}
                        />
                        <img
                            src={happy}
                            alt="happy"
                            width={16}
                            height={16}
                            className={props.item.rating === 4 ? "" : classes.feedbackEmoji}
                        />
                        <img
                            src={love}
                            alt="love"
                            width={16}
                            height={16}
                            className={props.item.rating === 5 ? "" : classes.feedbackEmoji}
                        />
                    </div> */}
                    <Typography className={`mb-2 ${classes.TableCellMainText} ${classes.TableCellblogheading}`} >
                        {props.item.heading}
                    </Typography>
                    <Typography className={`mb-2 ${classes.TableCellMainText}`}>
                        {props.item.subject}
                    </Typography>
                    <ReadMore>{props.item.message}</ReadMore>
                    {/* <Typography className={classes.SomeOtheText}>{props.item.message}</Typography> */}
                </div>
            </TableCell>
            <TableCell style={{ verticalAlign: "top" }}>
                <div>
                    {props.item.images?.length > 0 && props.item.images?.length > 3 && (
                        <div className={classes.documentImagesContiner}>
                            <img
                                key={0}
                                src={`${process.env.REACT_APP_IMAGE_URL}/${props.item.images[0]?.destination}/${props.item.images[0]?.filename}`}
                                alt="blogImage1"
                            />
                            <img
                                key={1}
                                src={`${process.env.REACT_APP_IMAGE_URL}/${props.item.images[1]?.destination}/${props.item.images[1]?.filename}`}
                                alt="blogImage2"
                            />
                            <Typography className={classes.TableCellMainText1}>
                                +{props.item.images?.length - 2}
                            </Typography>
                        </div>
                    )}
                    {props.item.images?.length > 0 && props.item.images?.length < 4 && (
                        <div className={classes.documentImagesContiner}>
                            {props.item.images.map((item, index) => (
                                <img
                                    key={index}
                                    src={`${process.env.REACT_APP_IMAGE_URL}/${item?.destination}/${item?.filename}`}
                                    alt="blogImage3"
                                />
                            ))}
                        </div>
                    )}
                </div>
            </TableCell>
            <TableCell style={{ verticalAlign: "top" }}>
                <div>
                    <Typography
                        href="#javascript: void(0);"
                        className={classes.TableCellMainText}
                    >
                        {moment(props.item.createdAt).format("DD MMM YYYY")}
                    </Typography>
                    <span className={classes.SomeOtheText}>
                        {moment(props.item.createdAt).format("hh:mm A")}
                    </span>
                </div>
            </TableCell>
            <TableCell style={{ verticalAlign: "top" }}>
                <Button
                    variant="text"
                    size="small"
                    style={{ minWidth: 0 }}
                    onClick={(e) => handleMenuclick(e)}
                >
                    <BsThreeDotsVertical />
                </Button>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    className={classes.menuListHolder}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    <MenuItem
                        key={`view${props.item._id}`}
                        className={classes.menuItems}
                        onClick={() => navigate(`/blogs/edit-blogs/${props.item._id}`)}
                    >
                        <span>edit</span>
                    </MenuItem>
                    <MenuItem
                        key={`delete${props.item._id}`}
                        className={classes.menuItems}
                        onClick={() => handleDelete(props.item._id)}
                    >
                        <span>Delete</span>
                    </MenuItem>
                </Menu>
            </TableCell>
        </TableRow>
    );
}

export default BlogsListItem;
