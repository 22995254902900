import React, { useEffect, useState } from "react";
import classes from "./communication.module.css";
import {
    Typography,
    Paper,
    Select,
    MenuItem,
    Chip,
    Button,
    FormControl
} from "@mui/material";
import { MdClose } from "react-icons/md";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { BrudCrumbBackIcon, BrudCrumbNextIcon } from "../../../Components/Icons/Icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCompaniesList } from "../../../Redux/actions/customerActions";
import { UpdateCommunicationNotification, createCommunicationNotification, getOneCommunicationDetails } from "../../../Redux/actions/communicationActions";
import { ONE_COMMUNICATION_SUCCESS } from "../../../Redux/constants/communicationConstants";

const CreateNotificationPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [subject, setSubject] = useState("");
    const [details, setDetails] = useState("");
    const [selectError, setSelectError] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const Companies = useSelector((state) => state.customerData);
    const { companiesList } = Companies || {};
    const CreateData = useSelector((state) => state.communicationDate);
    const { createError, oneCommunication } = CreateData || {};


    const handleChange = (event) => {
        console.log(event.target.value);
        setSelectedItems(event.target.value);
        setSelectError(false);
    };

    useEffect(() => {

    }, [oneCommunication])

    const handleChipDelete = (e, itemToDelete) => {
        const array = selectedItems.filter((item) => item !== itemToDelete)
        console.log(array);
        setSelectedItems(array);
    };

    useEffect(() => {
        if (window.location.pathname.includes("/communication/edit-notification")) {
            const path = window.location.pathname;
            const arr = path.split("/");
            console.log(arr);
            dispatch(getOneCommunicationDetails(arr[arr.length - 1]));
        } else {
            dispatch({
                type: ONE_COMMUNICATION_SUCCESS,
                payload: null,
            });
            setSelectedItems([]);
        }
    }, [dispatch])


    useEffect(() => {
        dispatch(getCompaniesList());
    }, [dispatch])

    useEffect(() => {
        setSubject(oneCommunication?.subject);
        setDetails(oneCommunication?.message);
        if (oneCommunication?.members?.groups.length > 0 || oneCommunication?.members?.companies.length > 0) {
            setSelectedItems([...oneCommunication?.members?.groups, ...oneCommunication?.members?.companies]);
        }
    }, [oneCommunication])

    const getNewObject = async (array) => {
        let companies = [];
        let groups = [];
        let members = {};
        await array.map((item) => {
            if (item._id) {
                companies.push(item._id);
            }
            else {
                groups.push(item);
            }
        })
        members = {
            companies: companies,
            groups: groups
        }
        return members
    }

    const handleSubmit = async () => {
        if (selectedItems.length > 0) {
            const members = await getNewObject(selectedItems);
            const userEntry = {
                members: members,
                subject: subject,
                message: details
            }
            console.log(userEntry);
            if (oneCommunication) {
                dispatch(UpdateCommunicationNotification(userEntry, oneCommunication._id));
            } else {
                dispatch(createCommunicationNotification(userEntry));
            }
            if (!createError) {
                navigate("/communication");
            }
        } else {
            setSelectError(true);
        }
    };

    return (
        <div>
            <div className={classes.brudCrumb}>
                <span onClick={() => navigate(-1)}><BrudCrumbBackIcon /></span>
                <span onClick={() => navigate("/communication")}>Communication</span>
                <span><BrudCrumbNextIcon /></span>
                <span>{window.location.pathname.includes("/communication/edit-notification") ? "Edit Notification" : "Create Notification"}</span>
            </div>
            <Paper style={{ padding: "20px", marginTop: "20px" }}>
                <div>
                    <Typography className={`mb-4 ${classes.FormHEader}`}>{window.location.pathname.includes("/communication/edit-notification") ? "Edit Notification" : "Create Notification"}</Typography>
                    <ValidatorForm
                        useref="form"
                        onSubmit={handleSubmit}
                        style={{ width: "100%" }}
                        className={classes.InputForm}
                    >
                        <FormControl fullWidth error={selectError}>
                            <Typography className={classes.formLable}>To</Typography>
                            <Select
                                multiple
                                fullWidth
                                size="small"
                                value={selectedItems}
                                onChange={handleChange}
                                MenuProps={{ classes: { paper: classes.menuPaper } }}
                                renderValue={(selected) => (
                                    <div className={classes.chips_container}>
                                        {selected.map((item, index) => (
                                            <Chip
                                                key={index}
                                                label={item.company_name ? item.company_name : item.replace("_", " ")}
                                                deleteIcon={
                                                    <MdClose
                                                        onMouseDown={(event) => event.stopPropagation()}
                                                    />
                                                }
                                                onDelete={(e) => handleChipDelete(e, item)}
                                                className={classes.chipBtn}
                                            />
                                        ))}
                                    </div>
                                )}
                            >
                                {/* <div className={classes.CommunicationDropDownmain}> */}
                                {['teams', 'team_leader', 'team_player'].map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option.replace("_", " ")}
                                    </MenuItem>
                                ))}
                                {companiesList && companiesList.length > 0 && companiesList.map((company) => (
                                    <MenuItem key={company._id} value={company}>
                                        {company.company_name}
                                    </MenuItem>
                                ))}
                                {/* </div> */}
                            </Select>
                            {selectError && <Typography className={classes.errorText}>{"Please select atleast one member"}</Typography>}
                        </FormControl>
                        <Typography className={`mb-3 mt-3 ${classes.FormSubHEader}`}>Notification</Typography>
                        <div>
                            <Typography className={classes.formLable}>Subject</Typography>
                            <TextValidator
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                    marginBottom: "15px",
                                    width: "70%",
                                }}
                                id="emailidInput"
                                value={subject}
                                onChange={(e) => {
                                    setSubject(e.target.value);
                                }}
                                size="small"
                                validators={["required"]}
                                errorMessages={["Subject is required"]}
                            />
                        </div>
                        <div>
                            <Typography className={classes.formLable}>Details</Typography>
                            <TextValidator
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                    marginBottom: "0px",

                                }}
                                id="emailidInput"
                                fullWidth
                                multiline
                                rows={4}
                                inputProps={{
                                    maxLength: 500,
                                }}
                                value={details}
                                onChange={(e) => {
                                    setDetails(e.target.value);
                                }}
                                size="small"
                                validators={["required"]}
                                errorMessages={["Details is required"]}
                            />
                            <Typography className={classes.SomeOtheText} style={{ marginLeft: "5px" }}>{details?.length ? details.length : 0}/500</Typography>
                        </div>
                        <div className={`mt-5 ${classes.AddUSerBtnSection}`}>
                            <Button variant="outlined" className={classes.AdduserMainBtn} onClick={() => navigate(-1)}>
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                type="submit"
                                className={classes.AdduserMainBtn}
                            >
                                Submit
                            </Button>
                        </div>
                    </ValidatorForm>
                </div>
            </Paper>
        </div>
    );
};

export default CreateNotificationPage;
