import { FEEDBACK_LIST_FAIL, FEEDBACK_LIST_SUCCESS, FEEDBACK_REQUEST } from "../constants/feedbackConstants";

const initialState = {
    loading: true,
    feedback: [],
    error: null,
    totalCount: 0,
};

export const feedbackReducer = (state = initialState, action) => {
    switch (action.type) {
        case FEEDBACK_REQUEST:
            return { ...state, loading: true };

        case FEEDBACK_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                feedback: action.payload.feedback,
                totalCount: action.payload.totalCount,
                error: null,
            };
        case FEEDBACK_LIST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};