import { authApi, getAuthorizationHeader } from "../../api/api";
import { CREATE_PROMOCODE_FAIL, CREATE_PROMOCODE_REQUEST, CREATE_PROMOCODE_SUCCESS, ONE_PROMOCODE_FAIL, ONE_PROMOCODE_REQUEST, ONE_PROMOCODE_SUCCESS, PROMOCODE_LIST_FAIL, PROMOCODE_LIST_REQUEST, PROMOCODE_LIST_SUCCESS } from "../constants/promoCodeConstants";

export const createPromoCodeFunction = (userEntry) => async (dispatch) => {
    try {
        dispatch({
            type: CREATE_PROMOCODE_REQUEST,
        });

        const { data } = await authApi.post(`/promo-code/create`, userEntry, {
            headers: { Authorization: getAuthorizationHeader() },
        });
        console.log(data);
        dispatch({
            type: CREATE_PROMOCODE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: CREATE_PROMOCODE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}

export const getPromoCodeDetails = (limit, page, sort, userEntry) => async (dispatch) => {
    try {
        dispatch({
            type: PROMOCODE_LIST_REQUEST,
        });

        const { data } = await authApi.post(`/promo-code/list?page=${page}&limit=${limit}&sort=${sort}`, userEntry, {
            headers: { Authorization: getAuthorizationHeader() },
        });

        dispatch({
            type: PROMOCODE_LIST_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: PROMOCODE_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const updatePromoCodeFunction = (userEntry, id) => async (dispatch) => {
    try {
        dispatch({
            type: CREATE_PROMOCODE_REQUEST,
        });

        console.log(userEntry);

        const { data } = await authApi.put(`/promo-code/update/${id}`, userEntry, {
            headers: { Authorization: getAuthorizationHeader() },
        });

        console.log(data);
    } catch (error) {
        dispatch({
            type: CREATE_PROMOCODE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}

export const getOnePromoCode = (id) => async (dispatch) => {
    try {
        dispatch({
            type: ONE_PROMOCODE_REQUEST,
        });
        const { data } = await authApi.get(`/promo-code/${id}`, {
            headers: { Authorization: getAuthorizationHeader() },
        });

        dispatch({
            type: ONE_PROMOCODE_SUCCESS,
            payload: data,
        });
    }
    catch (error) {
        dispatch({
            type: ONE_PROMOCODE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};