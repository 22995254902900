module.exports = {
  // ImageUrl: `http://localhost:8080`,
  //   ImageUrl: `https://api.teamplayer.apps.org.in`,
  GENERATE_FORGET_PASSWORD_OTP: `/users/generateOtp`,
  RESET_PASSWORD: `/users/resetpassword`,
  VERIFY_LOGIN_OTP: `/users/verifyotp`,
  CHANGE_PASSWORD: `/users/changepassword`,
  GET_USER: `/users/user`,
  UPLOAD_IMAGE: "/locations/upload/image",
};
