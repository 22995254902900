import { authApi, getAuthorizationHeader } from "../../api/api";
import {
  BLOGS_CREATED_FAIL,
  BLOGS_CREATED_REQUEST,
  BLOGS_CREATED_SUCCESS,
  BLOGS_ONE_SUCCESS,
  BLOGS_TAGS_LIST_FAIL,
  BLOGS_TAGS_LIST_REQUEST,
  BLOGS_TAGS_LIST_SUCCESS,
  LIST_BLOGS_FAIL,
  LIST_BLOGS_REQUEST,
  LIST_BLOGS_SUCCESS,
} from "../constants/blogsConstants";

export const getTagsList = () => async (dispatch) => {
  try {
    dispatch({
      type: BLOGS_TAGS_LIST_REQUEST,
    });

    const { data } = await authApi.get(`/blogs/taglist`, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    console.log(data);
    dispatch({
      type: BLOGS_TAGS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BLOGS_TAGS_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createBlogs = (userEntry) => async (dispatch) => {
  try {
    dispatch({
      type: BLOGS_CREATED_REQUEST,
    });

    const { data } = await authApi.post(`/blogs/create`, userEntry, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    console.log(data);
    dispatch({
      type: BLOGS_CREATED_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BLOGS_CREATED_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getBlogsList = (currentPage, rowCount, sort) => async (dispatch) => {
  try {
    dispatch({
      type: LIST_BLOGS_REQUEST,
    });

    const { data } = await authApi.get(
      `/blogs/list?page=${currentPage}&limit=${rowCount}&sort=${sort}`,
      {
        headers: { Authorization: getAuthorizationHeader() },
      }
    );
    console.log(data);
    dispatch({
      type: LIST_BLOGS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LIST_BLOGS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getOneBlog = (id) => async (dispatch) => {
  try {
    dispatch({
      type: LIST_BLOGS_REQUEST,
    });

    const { data } = await authApi.get(`/blogs/view/${id}`, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    console.log(data);
    dispatch({
      type: BLOGS_ONE_SUCCESS,
      payload: data[0],
    });
  } catch (error) {
    dispatch({
      type: LIST_BLOGS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateBlogs = (userEntry, id) => async (dispatch) => {
  try {
    dispatch({
      type: BLOGS_CREATED_REQUEST,
    });

    const { data } = await authApi.put(`/blogs/update/${id}`, userEntry, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    console.log(data);
    dispatch({
      type: BLOGS_CREATED_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BLOGS_CREATED_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const DeleteselectedBlog = (id) => async (dispatch) => {
  try {
    dispatch({
      type: BLOGS_CREATED_REQUEST,
    });

    const { data } = await authApi.delete(`/blogs/delete/${id}`, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    console.log(data);
    const data1 = await authApi.get(`/blogs/list`, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    console.log(data1.data);
    dispatch({
      type: LIST_BLOGS_SUCCESS,
      payload: data1.data,
    });
  } catch (error) {
    dispatch({
      type: BLOGS_CREATED_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
