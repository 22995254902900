import React, { useEffect, useState } from "react";
import { Card, Box, Typography } from "@mui/material";
import logo from "../../../Assets/images/logo-sm.png";
import moment from "moment";
import classes from "./invoice.module.css";

const Invoicepdf = (props) => {
  const [tax, setTax] = useState();
  const [subTotal, setSubTotal] = useState();
  const [total, setTotal] = useState();

  const getCalculations = async () => {
    let sb = 0;
    let t = 0;
    let tx = 0;

    await props.oneInvoice.invoice_items.map((item) => {
      sb += item.item_price;
    });
    if (sb > 0) {
      setSubTotal(sb);
      tx = sb * 0.09;
      setTax(tx);
      t = sb + tx + tx;
      setTotal(t);
    }
  };

  useEffect(() => {
    if (props.oneInvoice) {
      getCalculations();
    }
  }, [props.oneInvoice]);
  return (
    <div
      id={`pdf-content-${props.oneInvoice?._id}`}
      style={{ width: "70%", margin: "0 auto" }}
    >
      <Card
        style={{ width: "100%", margin: "0 auto" }}
        className={classes.CardMain}
      >
        <Box>
          <div>
            <div className={`${classes.PageImage} ${classes.imageBackGround}`}>
              <div className={classes.imageContainer}>
                <span className="logo-sm">
                  <img src={logo} alt="" height={125} />
                </span>
                <Typography className={classes.InvoicePdfMainHEader}>
                  Invoice
                </Typography>
              </div>
              <div>
                <Typography
                  className={classes.InvoicePdfSubHEader}
                  style={{ textAlign: "right" }}
                >
                  Team Player
                </Typography>
                <div className={classes.AddressDetails}>
                  <Typography className={classes.invoiceCommonText}>
                    Address:{" "}
                  </Typography>
                  <Typography
                    className={`${classes.invoiceCommonText} ${classes.invoiceImpText}`}
                  >
                    16, Sicva Lane
                  </Typography>
                  <Typography className={classes.invoiceCommonText}>
                    City:{" "}
                  </Typography>
                  <Typography
                    className={`${classes.invoiceCommonText} ${classes.invoiceImpText}`}
                  >
                    Bangalore
                  </Typography>
                  <Typography className={classes.invoiceCommonText}>
                    Country:{" "}
                  </Typography>
                  <Typography
                    className={`${classes.invoiceCommonText} ${classes.invoiceImpText}`}
                  >
                    India
                  </Typography>
                  <Typography className={classes.invoiceCommonText}>
                    Postal Code:{" "}
                  </Typography>
                  <Typography
                    className={`${classes.invoiceCommonText} ${classes.invoiceImpText}`}
                  >
                    675467
                  </Typography>
                  <Typography className={classes.invoiceCommonText}>
                    GST No:{" "}
                  </Typography>
                  <Typography
                    className={`${classes.invoiceCommonText} ${classes.invoiceImpText}`}
                  >
                    164FSDS485643
                  </Typography>
                </div>
              </div>
            </div>
            <div className={`${classes.PageImage} mt-4`}>
              <div>
                <Typography
                  className={`${classes.invoiceCommonText} ${classes.invoiceImpText}`}
                >
                  Bill To:{" "}
                </Typography>
                <Typography className={`${classes.InvoicePdfSubHEader1} mt-3`}>
                  {props.oneInvoice?.company_id?.company_name}
                </Typography>
                <Typography className={classes.invoiceCommonText}>
                  {props.oneInvoice?.company_id?.address_one},{" "}
                  {props.oneInvoice?.company_id?.address_two}
                </Typography>
                <Typography className={classes.invoiceCommonText}>
                  Pincode : {props.oneInvoice?.company_id?.pin}
                </Typography>
                <Typography className={`mt-2 ${classes.form_lable}`}>
                  GST :{" "}
                  <span
                    className={classes.invoiceCommonText}
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    {props.oneInvoice?.company_id?.gst_number}
                  </span>
                </Typography>
                <Typography className={`mt-1 ${classes.form_lable}`}>
                  Email ID:{" "}
                  <span
                    className={classes.invoiceCommonText}
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    {props.oneInvoice?.user_id?.email}
                  </span>
                </Typography>
                <Typography className={`mt-1 ${classes.form_lable}`}>
                  Mobile Number:{" "}
                  <span
                    className={`${classes.invoiceCommonText}`}
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    {props.oneInvoice?.user_id?.phone}
                  </span>
                </Typography>
              </div>
              <div style={{ textAlign: "right" }}>
                <Typography className={`${classes.form_lable} mb-1`}>
                  Invoice
                </Typography>
                <Typography className={`${classes.invoiceCommonText} mb-4`}>
                  {props.oneInvoice?.invoice_number}
                </Typography>
                <Typography className={`${classes.form_lable} mb-1`}>
                  Date
                </Typography>
                <Typography className={classes.invoiceCommonText}>
                  {moment(props.oneInvoice?.createdAt).format("DD MMM YYYY")}
                </Typography>
              </div>
            </div>
          </div>
        </Box>
        {props.oneInvoice &&
        props.oneInvoice.invoice_items &&
        props.oneInvoice.invoice_items.length > 0 ? (
          <>
            <div className={classes.TransParentHeader}>
              <Typography className={classes.InvoicePdfSubHEader}>
                Description
              </Typography>
              <Typography className={classes.InvoicePdfSubHEader}>
                Amount
              </Typography>
            </div>
            <Box
              style={{
                width: "calc(100% - 70px)",
                margin: "0 auto",
                padding: "20px 0",
              }}
            >
              {props.oneInvoice.invoice_items.map((item) => {
                return (
                  <div className={`${classes.AmountContainer}`}>
                    <Typography className={classes.form_lable}>
                      {item.item_name}
                    </Typography>
                    <Typography className={`${classes.invoiceCommonText}`}>
                      &#8377; {item.item_price}
                    </Typography>
                  </div>
                );
              })}
              <hr className={classes.invoicePageLine} />
              <div className={`${classes.AmountContainer}`}>
                <Typography className={classes.form_lable}>
                  Sub Total
                </Typography>
                <Typography className={`${classes.invoiceCommonText}`}>
                  &#8377; {subTotal}
                </Typography>
              </div>
              <hr className={classes.invoicePageLine} />
              <div className={`${classes.AmountContainer}`}>
                <Typography className={`mb-2 ${classes.form_lable}`}>
                  CGST (9%)
                </Typography>
                <Typography className={`mb-2 ${classes.invoiceCommonText}`}>
                  &#8377; {tax}
                </Typography>
                <Typography className={classes.form_lable}>
                  SGST (9%)
                </Typography>
                <Typography className={`${classes.invoiceCommonText}`}>
                  &#8377; {tax}
                </Typography>
              </div>
              <hr className={classes.invoicePageLine} />
              <div className={`${classes.AmountContainer}`}>
                <Typography className={classes.InvoicePdfMainHEader}>
                  Total
                </Typography>
                <Typography className={classes.InvoicePdfMainHEader}>
                  &#8377; {total}
                </Typography>
              </div>
            </Box>
          </>
        ) : null}
      </Card>
    </div>
  );
};

export default Invoicepdf;
