export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";
export const MY_PROFILE_REQUEST = "MY_PROFILE_REQUEST";
export const MY_PROFILE_DATA = "MY_PROFILE_DATA";
export const USER_UPDATE = "USER_UPDATE";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";
export const GENERATE_PHONE_OTP_REQUEST = "GENERATE_PHONE_OTP_REQUEST";
export const GENERATE_PHONE_OTP_SUCCESS = "GENERATE_PHONE_OTP_SUCCESS";
export const GENERATE_PHONE_OTP_FAILURE = "GENERATE_PHONE_OTP_FAILURE";
export const GENERATE_EMAIL_OTP_REQUEST = "GENERATE_EMAIL_OTP_REQUEST";
export const GENERATE_EMAIL_OTP_SUCCESS = "GENERATE_EMAIL_OTP_SUCCESS";
export const GENERATE_EMAIL_OTP_FAILURE = "GENERATE_EMAIL_OTP_FAILURE";
export const VERIFY_EMAIL_OTP_REQUEST = "VERIFY_EMAIL_OTP_REQUEST";
export const VERIFY_EMAIL_OTP_SUCCESS = "VERIFY_EMAIL_OTP_SUCCESS";
export const VERIFY_EMAIL_OTP_FAILURE = "VERIFY_EMAIL_OTP_FAILURE";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";
export const SET_HEADER_NAME = "SET_HEADER_NAME";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const USERS_MANAGEMENT_LIST_REQUEST = "USERS_MANAGEMENT_LIST_REQUEST";
export const USERS_MANAGEMENT_LIST_SUCCESS = "USERS_MANAGEMENT_LIST_SUCCESS";
export const USERS_MANAGEMENT_LIST_FAIL = "USERS_MANAGEMENT_LIST_FAIL";
export const USER_MANAGEMENT_CREATE_REQUEST = "USER_MANAGEMENT_CREATE_REQUEST";
export const USER_MANAGEMENT_CREATE_SUCCESS = "USER_MANAGEMENT_CREATE_SUCCESS";
export const USER_MANAGEMENT_CREATE_FAIL = "USER_MANAGEMENT_CREATE_FAIL";
export const USER_REFRESH_TOKEN_REQUEST = "USER_REFRESH_TOKEN_REQUEST";
export const USER_REFRESH_TOKEN_SUCCESS = "USER_REFRESH_TOKEN_SUCCESS";
export const USER_REFRESH_TOKEN_FAIL = "USER_REFRESH_TOKEN_FAIL";
export const DELETE_ADMIN_USER_REQUEST = "DELETE_ADMIN_USER_REQUEST";
export const DELETE_ADMIN_USER_SUCCESS = "DELETE_ADMIN_USER_SUCCESS";
export const DELETE_ADMIN_USER_FAIL = "DELETE_ADMIN_USER_FAIL";
