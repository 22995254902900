import { Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import classes from "./supportTicket.module.css";
import Dropzone from "react-dropzone";
import { useDropzone } from 'react-dropzone';
import FileReturnComponent from "./FileReturnComponent";

const Basic = ({ images, onDropFunction }) => {
  const [files, setFiles] = useState([]);


  const onDrop = (acceptedFiles) => {
    try {
      const newArray = [...files, ...acceptedFiles];
      setFiles(newArray);
      onDropFunction(newArray);
    } catch (error) {
      console.error("Error updating state:", error);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.xlsx, .xls, application/pdf, image/*',
  });

  useEffect(() => {
    if (images && images.length > 0) {
      setFiles([...images]);
    }
  }, [images]);

  const remove = (filename) => {
    try {
      const newList = files.filter(
        (item) => item.filename !== filename && item.name !== filename
      );
      setFiles([...newList]);
      onDropFunction(newList);
    } catch (error) {
      console.error("Error updating state:", error);
    }
  };

  return (
    <div>
      <div className={classes.DropZoneContainer}>
        <Dropzone onDrop={onDrop} multiple >
          {({ getRootProps }) => (
            <div {...getRootProps({ className: classes.DropZone })}>
              <input type="file"
                style={{ opacity: 0, position: "absolute", zIndex: 22, bottom: 0 }}
                accept=".xls, .xlsx, .csv , application/pdf " />
              <Button variant="contained" className={classes.DropzoneBtn}>
                Browse...
              </Button>
              <div>or drop file here</div>
            </div>
          )}
        </Dropzone>
        {window.location.pathname.includes("update") &&
          files &&
          files.length > 0 ? (
          <div className={classes.dropDownimageContainer}>
            {files.map((item, index) => {
              return (
                <FileReturnComponent item={item} key={index} index remove={remove} />
              )
            })}
          </div>
        ) : null}
        {window.location.pathname.includes("create") &&
          files &&
          files.length > 0 ? (
          <div className={classes.dropDownimageContainer}>
            {files.map((item, index) => {
              return (
                <FileReturnComponent item={item} key={index} index remove={remove} type={"new"} />
              )
            })}
          </div>
        ) : null}
      </div>
    </div>

    // <section>
    //   <div {...getRootProps({ className: classes.DropZone })}>
    //     <input
    //       {...getInputProps({
    //         onChange: handleFile,
    //       })}
    //     />
    //     <Button variant="contained" className={classes.DropzoneBtn}>
    //       Browse
    //     </Button>
    //     <div>Or drop file here</div>
    //   </div>
    //   <aside>{files}</aside>
    // </section>
  );
};

export default Basic;
