import React from 'react'
import classes from "./feedback.module.css"
import { Box, Tab, Tabs } from '@mui/material';
import TeamLeaderFeedback from './teamLeaderFeedback';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const FeedBackIndex = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <div>
                <Box>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                        >
                            <Tab
                                label={"Employer Feedback"}
                                {...a11yProps(0)}
                                style={{ textTransform: "capitalize" }}
                            />
                            <Tab
                                label={"Employee Feedback"}
                                {...a11yProps(1)}
                                style={{ textTransform: "capitalize" }}
                            />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0} className={classes.TabPanel}>
                        <TeamLeaderFeedback user_type={"team_leader"} />
                    </TabPanel>
                    <TabPanel value={value} index={1} className={classes.TabPanel}>
                        <TeamLeaderFeedback user_type={"team_player"} />
                    </TabPanel>
                </Box>
            </div>
        </div>
    )
}

export default FeedBackIndex;