import { Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import classes from "./blogs.module.css";
import Dropzone from "react-dropzone";
import { formatBytes } from "../../../Utilities/commonfunctions";
import { DeleteBinIcons } from "../../../Components/Icons/Icons";

const Basic = ({ images, onDropFunction }) => {
  const [files, setFiles] = useState([]);
  // const [currentFiles, setcurrentFiles] = useState([]);
  // const imageList = useSelector((state) => state.reducers.location.imageList);

  const onDrop = (acceptedFiles) => {
    try {
      const newArray = [...files, ...acceptedFiles];
      setFiles(newArray);
      onDropFunction(newArray);
    } catch (error) {
      console.error("Error updating state:", error);
    }
  };

  useEffect(() => {
    if (images && images.length > 0) {
      setFiles([...images]);
    }
  }, [images]);

  const remove = (filename) => {
    try {
      const newList = files.filter(
        (item) => item.filename !== filename && item.name !== filename
      );
      setFiles([...newList]);
      onDropFunction(newList);
    } catch (error) {
      console.error("Error updating state:", error);
    }
  };

  return (
    <div>
      <div className={classes.DropZoneContainer}>
        <Dropzone onDrop={onDrop}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps({ className: classes.DropZone })}>
              <input {...getInputProps()} />
              <Button variant="contained" className={classes.DropzoneBtn}>
                Browse
              </Button>
              <div>or drag and drop</div>
            </div>
          )}
        </Dropzone>
        {window.location.pathname.includes("edit") &&
          files &&
          files.length > 0 ? (
          <div className={classes.imageContainer}>
            {files.map((item) => {
              const image =
                item.filename && item.destination
                  ? `${process.env.REACT_APP_IMAGE_URL}/${item.destination}${item.filename}`
                  : URL.createObjectURL(item);
              return (
                <div className={classes.subImageContainer}>
                  <img
                    src={image}
                    width="250px"
                    height="150px"
                    style={{ maxHeight: "250px" }}
                    alt={item.originalname || item.name}
                  />
                  <div className={classes.imageText}>
                    <Tooltip title={item.originalname || item.name}>
                      <Typography
                        className={`${classes.form_lable} ${classes.detailsText1}`}
                        noWrap
                        style={{
                          overflow: "hidden",
                          width: "150px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.originalname || item.name}
                      </Typography>
                    </Tooltip>

                    <div style={{ display: "flex", columnGap: "10px" }}>
                      {item.size ? (
                        <Typography className={`${classes.form_lable}`}>
                          {formatBytes(item.size)}
                        </Typography>
                      ) : null}
                      <Typography
                        className={`${classes.form_lable} ${classes.ImageDeleteBtn}`}
                        onClick={() => remove(item.filename)}
                      >
                        <DeleteBinIcons />
                      </Typography>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
        {window.location.pathname.includes("create") &&
          files &&
          files.length > 0 ? (
          <div className={classes.imageContainer}>
            {files.map((item) => {
              const image = URL.createObjectURL(item);
              return (
                <div className={classes.subImageContainer}>
                  <img
                    src={image}
                    width="250px"
                    height="150px"
                    alt={item.name}
                  />
                  <div className={classes.imageText}>
                    <Tooltip title={item.name}>
                      <Typography
                        className={`${classes.form_lable} ${classes.detailsText1}`}
                        noWrap
                        style={{
                          overflow: "hidden",
                          width: "150px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.name}
                      </Typography>
                    </Tooltip>

                    <div
                      style={{
                        display: "flex",
                        columnGap: "10px",
                        alignItems: "center",
                        marginTop: "4px",
                      }}
                    >
                      {item.size ? (
                        <Typography
                          className={`${classes.form_lable}`}
                          style={{
                            fontSize: "13px",
                          }}
                        >
                          {formatBytes(item.size)}
                        </Typography>
                      ) : null}
                      <Typography
                        className={`${classes.form_lable} ${classes.ImageDeleteBtn}`}
                        onClick={() => remove(item.name)}
                      >
                        <DeleteBinIcons />
                      </Typography>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    </div>

    // <section>
    //   <div {...getRootProps({ className: classes.DropZone })}>
    //     <input
    //       {...getInputProps({
    //         onChange: handleFile,
    //       })}
    //     />
    //     <Button variant="contained" className={classes.DropzoneBtn}>
    //       Browse
    //     </Button>
    //     <div>Or drop file here</div>
    //   </div>
    //   <aside>{files}</aside>
    // </section>
  );
};

export default Basic;
