import React, { useState, useEffect } from "react";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../Assets/images/mainLogo.png";
import { login } from "../../Redux/actions/userActions";
import classes from "./auth.module.css";
import { Button, Typography, Paper, Snackbar, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CloseEyeIcon, OpenEyeIcon } from "../../Components/Icons/Icons";
import ForgetPassword from "./ForgotPasswordModal";
import AlertBox from "../../Components/AlertBox";

const Login = (location) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showAlert, setShowAlert] = useState();
  const [modalToggle, setModalToggle] = useState();
  const [showPassword, setShowPassword] = React.useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const loggedUser = JSON.parse(sessionStorage.getItem("userInfo"));
  const { userInfo, error } = useSelector((state) => state.userLogin);

  useEffect(() => {
    if (userInfo) {
      console.log(userInfo, error)
    }
    else if (error) {
      setShowAlert(true)
    }
  }, [error, userInfo])

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(email, password, rememberMe));
  };


  useEffect(() => {
    if (loggedUser) {
      navigate("/dashboard")
    }
  }, [loggedUser, navigate])

  const IsEmailCheck = (event) => {
    var x = event.charCode || event.keyCode;
    if ((x > 96 && x < 123) || x === 46 || x === 64 || (x > 47 && x < 58)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  };
  const closeForm = () => {
    setModalToggle(false);
  };

  return (
    <div className={classes.maindivForlogin}>
      <Paper className={classes.LoginPageMaindiv}>
        <div className={`col-lg-12 ${classes.LoinPageImageDiv}`}>
          <div className={`p-lg-5 p-4 ${classes.imageTextContainer}`}>
            <Typography className={classes.ImagePageMainHeader}>
              Welcome to Team Player
            </Typography>
            <Typography className={classes.ImagePageSubHeader}>
              Employee attendance management, location and time tracking
              application
            </Typography>
          </div>
        </div>
        <div className={`${classes.LoginPgeTextdiv}`}>
          <div className="p-lg-5 p-5">
            <div className="text-left">
              <span className="logo-lg mb-5">
                <img src={logo} alt="" height={50} />
              </span>
              <Typography
                className={`${classes.LoginPAgeMainHEader}`}
                style={{ marginTop: "30px" }}
              >
                Login
              </Typography>
              <Typography
                className={`mt-2 ${classes.loginDescription}`}
                style={{ marginTop: "10px" }}
              >
                Login to Team Player with your email ID &amp; password
              </Typography>
            </div>
            <ValidatorForm
              onSubmit={submitHandler}
              useref="form"
              className={classes.LoginForm}
            >
              <div className="mt-4">
                <div>
                  <Typography className={`${classes.form_lable} mb-2`}>
                    Email ID
                  </Typography>
                  <TextValidator
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      marginBottom: "15px",
                    }}
                    id="emailidInput"
                    fullWidth
                    placeholder="Enter Email ID"
                    onKeyPress={(e) => IsEmailCheck(e)}
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    size="small"
                  />
                </div>
                <div className="mt-3">
                  <Typography className={`${classes.form_lable} mb-2`}>
                    Password
                  </Typography>
                  <div className="position-relative auth-pass-inputgroup mb-3">
                    <div className={classes.passwordInputContainer}>
                      <TextValidator
                        sx={{
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                          marginBottom: "15px",
                        }}
                        id="outlined-adornment-password"
                        type={showPassword ? "text" : "password"}
                        fullWidth
                        placeholder="Enter Password"
                        size="small"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <div className={classes.showPassword}>
                        {showPassword ? (
                          <Button
                            variant="text"
                            onClick={() => setShowPassword(false)}
                          >
                            <CloseEyeIcon />
                          </Button>
                        ) : (
                          <Button onClick={() => setShowPassword(true)}>
                            <OpenEyeIcon />
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.loginForgotContainer}>
                  <div className={classes.checkBoxContainer}>
                    <input className="" type="checkbox" id="remember-check" onChange={(e) => setRememberMe(e.target.checked)} />
                    <Typography
                      className={classes.RemeberTextLogin}
                      htmlFor="remember-check"
                    >
                      Remember me
                    </Typography>
                  </div>
                  <div className="">
                    <Button
                      variant="text"
                      className={classes.ForgetPasswordbtn}
                      onClick={() => setModalToggle(true)}
                    >
                      Forgot password?
                    </Button>
                  </div>
                </div>
                <div className="mt-2">
                  <Button
                    variant="contained"
                    fullWidth
                    className={classes.LoginPageMainbtn}
                    type="submit"
                  >
                    Login
                  </Button>
                </div>
              </div>
            </ValidatorForm>
          </div>
          {error && showAlert && (
            <AlertBox type="error" message={error} stateName={setShowAlert} />
          )}
        </div>
        {modalToggle ? <ForgetPassword modalClose={closeForm} /> : null}
      </Paper>
    </div>
  );
};

export default Login;
