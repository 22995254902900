import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSubscriptions,
} from "../../../Redux/actions/subscriptionAction";
// import SubscriptionListItem from "../components/SubscriptionListItem";
import { Button } from "@mui/material"
import classes from "./Subscription.module.css";
import { useNavigate } from "react-router-dom";
import SubscriptionListItem from "./SubscriptionListItem";
import { RoundAddIcon } from "../../../Components/Icons/Icons";
import SuccessModal from "./successModal";

const Subscription = () => {
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();

  const { loading, subscriptions, error } = useSelector(
    (state) => state.subscriptions
  );

  const modalClose = () => {
    setSuccess(false);
    dispatch(fetchSubscriptions(1, 10, "", "packageName"));
    // navigate("/subscription-management");
  };

  useEffect(() => {
    dispatch(fetchSubscriptions(1, 10, "", "packageName"));
  }, [dispatch]);

  console.log(subscriptions);

  const deleteSucess = () => {
    setSuccess(true);
  }

  return (
    <div className="container-fluid">
      {/* start page title */}
      {subscriptions && subscriptions.length > 0 ? (
        <div style={{
          display: "flex",
          justifyContent: "flex-end",
        }}>
          <Button
            variant="contained"
            className={`mb-3 mt-2 ${classes.addButton} ${classes.addSubsButton}`}
            onClick={() => navigate('/subscription-management/add')}
          >
            <RoundAddIcon />
            <span>Add Subscription</span>
          </Button>
        </div>
      ) : null}
      <div className="row">
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>{error}</p>
        ) : subscriptions && subscriptions.length > 0 ? (
          subscriptions.map((item, index) => (
            <SubscriptionListItem key={index} subscription={item} deleteSubs={deleteSucess} />
          ))
        ) : (
          <p>No data found</p>
        )}
      </div>

      {success ? (
        <SuccessModal modalClose={modalClose} type={"delete"} />
      ) : null}
    </div>
  );
};

export default Subscription;
