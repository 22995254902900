import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  updateSubscription,
  fetchSubscription,
} from "../../../Redux/actions/subscriptionAction";
import classes from "./Subscription.module.css";
import {
  BrudCrumbBackIcon,
  BrudCrumbNextIcon,
  DeleteBinIcons,
  RoundAddIcon,
} from "../../../Components/Icons/Icons";
import { Typography, MenuItem, Button } from "@mui/material";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import SuccessModal from "./successModal";
import { Numeric } from "../../../Utilities/commonfunctions";

const EditSubscription = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const { state } = useLocation();
  const { id } = state || {}; // Read values passed on state

  useEffect(() => {
    dispatch(fetchSubscription(id));
  }, [dispatch, id]);

  const [durationList, setDurationList] = useState([]);
  const [featuresList, setFeaturesList] = useState([]);

  const Subscription = useSelector((state) => state.subscriptions.subscription);
  const { packageName, noOfTeamMembers, durations, features, _id } =
    Subscription || {};
  const [editedPackageName, setEditedPackageName] = useState(packageName);
  const [editedNoOfTeamMembers, setEditedNoOfTeamMembers] =
    useState(noOfTeamMembers);

  useEffect(() => {
    const Duration = durations ? durations : [{ duration: "", price: "" }];
    console.log(durations);
    const Features = features
      ? features
      : [{ id: featuresList.length + 1, feature: "" }];
    const PackageName = packageName ? packageName : "";
    const NoOfTeamMembers = noOfTeamMembers ? noOfTeamMembers : "";
    setFeaturesList(Features);
    setDurationList(Duration);
    setEditedPackageName(PackageName);
    setEditedNoOfTeamMembers(NoOfTeamMembers);
  }, [_id, durations, features, packageName, noOfTeamMembers]);

  const handleDelete = (index) => {
    const updatedInputs = [...durationList];
    updatedInputs.splice(index, 1);
    setDurationList(updatedInputs);
  };
  const getDays = (name) => {
    console.log(name);
    if (name === "annually") {
      return 365;
    } else if (name === "half_yearly") {
      return 180;
    } else if (name === "quarterly") {
      return 90;
    } else if (name === "monthly") {
      return 30;
    } else if (name === "7_days_Trial") {
      return 7;
    } else if (name === "14_days_Trial") {
      return 14;
    } else {
      return 0;
    }
  };

  const handleAddFeature = () => {
    const newInput = {
      id: featuresList.length + 1,
      feature: "",
    };

    setFeaturesList([...featuresList, newInput]);
  };

  const handleSubmit = (e) => {
    const durations = durationList.map((item) => {
      return {
        duration: item.duration,
        days: getDays(item.duration),
        price: item.price,
      };
    });
    const subscriptionData = {
      packageName: editedPackageName,
      noOfTeamMembers: editedNoOfTeamMembers,
      durations: durations,
      features: featuresList,
    };
    console.log(subscriptionData);
    //console.log("subscriptionData",subscriptionData)
    dispatch(updateSubscription(id, subscriptionData));
    // navigate("/subscription-management");
    setSuccess(true);
  };
  const handleDeleteFeature = (index) => {
    const updatedInputs = [...featuresList];
    updatedInputs.splice(index, 1);
    setFeaturesList(updatedInputs);
  };

  const modalClose = () => {
    setSuccess(false);
    // dispatch(fetchSubscriptions(1, 10, "", "packageName"));
    navigate("/subscription-management");
  }

  const handleAddDurationPrice = () => {
    setDurationList((prevFields) => [
      ...prevFields,
      { duration: "", price: "" },
    ]);
  };

  return (
    <div>
      <div className={`${classes.brudCrumb} mb-3`}>
        <span onClick={() => navigate(-1)}>
          <BrudCrumbBackIcon />
        </span>
        <span
          className={classes.packageSmallHEading}
          onClick={() => navigate("/subscription-management")}
        >
          Subscription
        </span>
        <BrudCrumbNextIcon />
        <span className={classes.login_form_lable}>{editedPackageName}</span>
      </div>
      <div className="row">
        <div className="col-xxl-12">
          <div className="card p-4">
            <Typography className={classes.addSubscHeading}>
              Edit Subscription
            </Typography>
            <Typography className={`${classes.addSubscSubHeading} mt-4 mb-3`}>
              Package name & no. of team members
            </Typography>
            {/* end card header */}
            <div>
              <ValidatorForm useref="form" onSubmit={handleSubmit}>
                <div className="">
                  <div
                    className={classes.displayFlex}
                    style={{ columnGap: "20px" }}
                  >
                    <div>
                      <Typography
                        htmlFor="firstNameinput"
                        className={`mb-2 ${classes.login_form_lable}`}
                      >
                        Package Name
                      </Typography>
                      <TextValidator
                        sx={{
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                          width: 345,
                        }}
                        fullWidth
                        className={classes.inputs}
                        value={editedPackageName}
                        onChange={(e) => setEditedPackageName(e.target.value)}
                        required={true}
                        size="small"
                      />
                    </div>
                    <div>
                      <Typography
                        htmlFor="firstNameinput"
                        className={`mb-2 ${classes.login_form_lable}`}
                      >
                        No. of Team members
                      </Typography>
                      <TextValidator
                        sx={{
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                          width: 345,
                        }}
                        fullWidth
                        className={classes.inputs}
                        value={editedNoOfTeamMembers}
                        onChange={(e) =>
                          setEditedNoOfTeamMembers(e.target.value)
                        }
                        required={true}
                        size="small"
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="col-md-2"></div>
                  <Typography className={classes.addSubscSubHeading}>
                    Duration
                  </Typography>
                  <div className="mt-4">
                    {durationList.map((input, index) => (
                      <div
                        key={index}
                        className={classes.displayFlex}
                        style={{ columnGap: "20px" }}
                      >
                        <div className="mb-3">
                          <Typography
                            htmlFor={`durationInput-${index}`}
                            className={`mb-2 ${classes.login_form_lable}`}
                          >
                            Duration
                          </Typography>
                          <TextValidator
                            sx={{
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                              width: 345,
                            }}
                            select
                            fullWidth
                            className={classes.inputs}
                            value={input.duration}
                            onChange={(e) => {
                              const updatedInputs = [...durationList];
                              updatedInputs[index].duration = e.target.value;
                              setDurationList(updatedInputs);
                            }}
                            required={true}
                            size="small"
                          >
                            <MenuItem value="">Select</MenuItem>
                            <MenuItem value="annually">Annually</MenuItem>
                            <MenuItem value="half_yearly">Half Yearly</MenuItem>
                            <MenuItem value="quarterly">Quarterly</MenuItem>
                            <MenuItem value="monthly">Monthly</MenuItem>
                            <MenuItem value="14daysTrial">
                              14 Days Trial
                            </MenuItem>
                            <MenuItem value="7daysTrial">7 Days Trial</MenuItem>
                          </TextValidator>
                        </div>
                        <div className="mb-3">
                          <Typography
                            htmlFor={`priceInput-${index}`}
                            className={`mb-2 ${classes.login_form_lable}`}
                          >
                            Price
                          </Typography>
                          <TextValidator
                            sx={{
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                              width: 345,
                            }}
                            fullWidth
                            className={classes.inputs}
                            value={input.price}
                            onKeyPress={(e) => {
                              Numeric(e);
                            }}
                            onChange={(e) => {
                              const updatedInputs = [...durationList];
                              updatedInputs[index].price = e.target.value;
                              setDurationList(updatedInputs);
                            }}
                            required={true}
                            size="small"
                          />
                        </div>
                        <div>
                          {durationList.length > 0 && (
                            <Button
                              onClick={() => handleDelete(index)}
                              className={classes.deleteButton}
                            >
                              <DeleteBinIcons />
                            </Button>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                  <Button
                    variant="text"
                    className={`mb-3 mt-2 ${classes.addButton}`}
                    onClick={(e) => {
                      handleAddDurationPrice();
                      e.preventDefault();
                    }}
                  >
                    <RoundAddIcon />
                    <span>Add duration &amp; price</span>
                  </Button>
                  <div>
                    <Typography
                      className={`mb-4 ${classes.addSubscSubHeading}`}
                    >
                      Features
                    </Typography>
                    <div>
                      {featuresList.map((feature, index) => (
                        <React.Fragment key={index}>
                          <div key={feature.id} className="mb-3">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                htmlFor={`durationInput-${index}`}
                                className={`mb-2 ${classes.login_form_lable}`}
                              >
                                Enter Features {feature.id}
                              </Typography>
                              <Button
                                onClick={() => handleDeleteFeature(index)}
                                className={classes.deleteButton}
                              >
                                <DeleteBinIcons />
                              </Button>
                            </div>
                            <TextValidator
                              sx={{
                                "& legend": { display: "none" },
                                "& fieldset": { top: 0 },
                              }}
                              fullWidth
                              multiline
                              rows={4}
                              placeholder="Enter Features here"
                              value={feature.feature}
                              onChange={(e) => {
                                const updatedInputs = [...featuresList];
                                updatedInputs[index].feature = e.target.value;
                                setFeaturesList(updatedInputs);
                              }}
                            />
                          </div>

                          {/*end col*/}
                        </React.Fragment>
                      ))}
                      <Button
                        variant="text"
                        className={`mb-3 mt-2 ${classes.addButton}`}
                        onClick={(e) => {
                          handleAddFeature();
                          e.preventDefault();
                        }}
                      >
                        <RoundAddIcon />
                        <span>Add Features</span>
                      </Button>
                    </div>
                  </div>
                  {/*end col*/}
                  <div className={classes.AddSubsButtonSection}>
                    <Button
                      variant="outlined"
                      onClick={() => navigate("/subscription-management")}
                    >
                      Cancel
                    </Button>
                    <Button variant="contained" type="submit">
                      Save
                    </Button>
                  </div>
                  {/*end col*/}
                </div>
                {/*end col*/}
              </ValidatorForm>
            </div>
            {/*end row*/}
          </div>
        </div>
      </div>
      {/* end col */}
      {/* end col */}
      {/* <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">© Team player</div>
            <div className="col-sm-6">
              <div className="text-sm-end d-none d-sm-block">
                All Rights Reserved. Powered By .
              </div>
            </div>
          </div>
        </div>
      </footer> */}
      {/* end main content*/}
      {success ? <SuccessModal modalClose={modalClose} type={"edit"} /> : null}
      {/*start back-to-top*/}
      {/* <button
        onclick="topFunction()"
        className="btn btn-info btn-icon"
        id="back-to-top"
      >
        <i className="ri-arrow-up-line" />
      </button> */}
    </div>
  );
};

export default EditSubscription;
