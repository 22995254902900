import React from "react";
import classes from "./reports.module.css";
import {
  TableCell,
  TableRow,
  Typography,
  Avatar,
} from "@mui/material";
import avatar from "../../../Assets/images/avatar-9.jpg";
import moment from "moment";
import { generateColor, stringAvatar } from "../../../Utilities/commonfunctions";

function ReportListItem(props) {
  return (
    <TableRow className={`${classes.table_bg1}`}>
      <TableCell>
        <Typography
          href="#javascript: void(0);"
          className={classes.TableCellMainText}
        >
          {props.item.companyInfo.company_name}
        </Typography>
        <span className={classes.SomeOtheText}>
          Id: {props.item.companyInfo._id.slice(0, 6)}
        </span>
      </TableCell>
      <TableCell>
        <div className={classes.displayFlex}>
          {props?.item?.userInfo?.profile_pic?.filename ?
            <Avatar
              alt="Remy Sharp"
              sx={{ width: 36 }}
              src={`${process.env.REACT_APP_IMAGE_URL}/${props?.item?.userInfo?.profile_pic?.destination}${props?.item?.userInfo?.profile_pic?.filename}`}
            />
            :
            <Avatar
              {...stringAvatar(`${props?.item?.first_name.trim()} ${props?.item?.last_name.trim()}`)}
              sx={{
                bgcolor: `${generateColor(`${props?.item?.first_name.trim()} ${props?.item?.last_name.trim()}`)}`,
              }}
              style={{ height: 36, width: 36, }}
            />
          }
          {/* <Avatar
            src={avatar}
            alt=""
            className="avatar-xs rounded-circle me-2"
          /> */}
          <Typography
            href="#javascript: void(0);"
            className={classes.TableCellMainText}
          >
            {props.item.userInfo.name}
          </Typography>
        </div>
      </TableCell>
      <TableCell>
        <Typography
          href="#javascript: void(0);"
          className={classes.TableCellMainText}
          style={{ textTransform: "capitalize" }}
        >
          {props.item.companyInfo.industry}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          href="#javascript: void(0);"
          className={classes.TableCellMainText}
        >
          {props.item.designation}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          href="#javascript: void(0);"
          className={classes.TableCellMainText}
        >
          {props.item.mobile_number}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          href="#javascript: void(0);"
          className={classes.TableCellMainText}
        >
          {props.item.email}
        </Typography>
      </TableCell>
      <TableCell style={{ minWidth: 0 }}>
        <Typography
          href="#javascript: void(0);"
          className={classes.TableCellMainText}
        >
          {moment(props.item.userInfo.createdAt).format("DD MMM YYYY")}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

export default ReportListItem;
