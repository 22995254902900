import React, { useState } from 'react';
import classes from "./supportTicket.module.css";
import { Card, Typography, Select, MenuItem, Button } from '@mui/material';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Numeric } from '../../../Utilities/commonfunctions';
import Basic from './DragAndDrop';
import { authImageApi, getAuthorizationHeader } from '../../../api/api';
import { UPLOAD_IMAGE } from '../../../api/api-urls';
import { CreateSupportTicketAPI } from '../../../Redux/actions/supportTicketActions';

const CreateSupportTicket = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [customerName, setCustomerName] = useState("");
    const [userType, setUserType] = useState("team_player");
    const [mobileNumber, setMobileNumber] = useState("");
    const [emailId, setEmailId] = useState("");
    const [subject, setSubject] = useState("");
    const [details, setDetails] = useState("");
    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleChange = (event) => {
        setUserType(event.target.value);
    };

    const onDropFunction = (acceptedFiles) => {
        setSelectedFiles(acceptedFiles);
    };

    const CreateimageArray = async (items) => {
        console.log(items)
        const array = items.map(async (file) => {
            let formData = new FormData();
            formData.append("file", file);
            const { data } = await authImageApi.post(UPLOAD_IMAGE, formData, {
                headers: { Authorization: getAuthorizationHeader() },
            });
            return data.data;
        });
        const results = await Promise.all(array);
        const finalArray = [...results];
        return finalArray;
        //return results;
        // setFiles([...files, ...results]);
        // dispatch(saveImageList([...files, ...results]));
    };

    const handleSubmit = async () => {
        var Buildimages = await CreateimageArray(selectedFiles);
        const userEntry = {
            company_name: customerName,
            customer_type: userType,
            phone: mobileNumber,
            email: emailId,
            subject: subject,
            details: details,
            documents: Buildimages,
        };
        try {
            dispatch(CreateSupportTicketAPI(userEntry));
            navigate("/support-tickets")
        }
        catch (err) {
            console.log(err);
        }
    }
    return (
        <div>
            <Card style={{ padding: "20px" }}>
                <Typography className={`mb-4 ${classes.headerText}`}>Create Ticket</Typography>
                <ValidatorForm
                    useref="form"
                    onSubmit={handleSubmit}
                    style={{ width: "100%" }}
                    className={classes.InputForm}
                >
                    <Typography className={`mb-3 ${classes.TableCellMainText1}`}>Customer Details</Typography>
                    <div className={classes.InputGridContainer}>
                        <div>
                            <Typography className={classes.formLable}>Customer Name</Typography>
                            <TextValidator
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                    marginBottom: "15px",
                                }}
                                id="companyNameInput"
                                fullWidth
                                value={customerName}
                                onChange={(e) => {
                                    setCustomerName(e.target.value);
                                }}
                                size="small"
                            />
                        </div>
                        <div>
                            <Typography className={classes.formLable}>Customer Type</Typography>
                            <Select
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                    width: "100%",
                                    background: "white",
                                }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={userType}
                                size="small"
                                label="Age"
                                onChange={handleChange}
                            >
                                <MenuItem value={"team_leader"}>Team Leader</MenuItem>
                                <MenuItem value={"team_player"}>Team Player</MenuItem>
                            </Select>
                        </div>
                    </div>
                    <Typography className={`mb-3 ${classes.TableCellMainText1}`}>Contact Details</Typography>
                    <div className={`mb-4 ${classes.InputGridContainer}`}>
                        <div>
                            <Typography className={classes.formLable}>Mobile Number</Typography>
                            <TextValidator
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                    marginBottom: "15px",
                                }}
                                id="MobilenumberInput"
                                fullWidth
                                onKeyPress={(e) => Numeric(e)}
                                value={mobileNumber}
                                onChange={(e) => {
                                    setMobileNumber(e.target.value);
                                }}
                                size="small"
                                inputProps={{
                                    maxLength: 10,
                                }}
                                validators={["required", "matchRegexp:[0-9]{10}"]}
                                errorMessages={[
                                    "This field is required",
                                    "Please Enter the 10 digit mobile number",
                                ]}
                            />
                        </div>
                        <div>
                            <Typography className={classes.formLable}>Email ID</Typography>
                            <TextValidator
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                    marginBottom: "15px",
                                }}
                                id="emailidInput"
                                fullWidth
                                value={emailId}
                                onChange={(e) => {
                                    setEmailId(e.target.value);
                                }}
                                size="small"
                                validators={["required", "isEmail"]}
                                errorMessages={[
                                    "Customer Type is required",
                                    "This is not a valid Email",
                                ]}
                            />
                        </div>
                    </div>
                    <Typography className={`mb-3 ${classes.TableCellMainText1}`}>Message</Typography>
                    <div className={`mb-4`}>
                        <div>
                            <Typography className={classes.formLable}>Subject</Typography>
                            <TextValidator
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                    marginBottom: "15px",
                                    width: "66%",
                                }}
                                id="subjectinput"
                                fullWidth
                                value={subject}
                                onChange={(e) => {
                                    setSubject(e.target.value);
                                }}
                                size="small"
                            />
                        </div>
                        <div>
                            <Typography className={classes.formLable}>Enter Details</Typography>
                            <TextValidator
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                    marginBottom: "15px",
                                    width: "100%",
                                }}
                                className={classes.DetailsInputFeils}
                                id="detailsInput"
                                fullWidth
                                inputProps={{
                                    maxLength: 500,
                                }}
                                multiline
                                rows={4}
                                value={details}
                                onChange={(e) => {
                                    setDetails(e.target.value);
                                }}
                                size="small"
                            />
                            <Typography className={classes.SomeOtheText}>{details.length}/500</Typography>
                        </div>
                    </div>
                    <Typography className={`mb-3 ${classes.TableCellMainText1}`}>Documents</Typography>
                    <div className={classes.DropZoneContainer}>
                        <Basic onDropFunction={onDropFunction} />
                    </div>
                    <div className={`mt-4 ${classes.createTicketBtnsection}`}>
                        <Button
                            variant="outlined"
                            className={classes.AddNotificationMainBtn}
                            onClick={() => {
                                navigate("/support-tickets");
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            type="submit"
                            className={classes.AddNotificationMainBtn}
                        >
                            Save
                        </Button>
                    </div>
                </ValidatorForm>
            </Card>
        </div>
    )
}

export default CreateSupportTicket;