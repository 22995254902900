import * as React from "react";
import Tabs from "@mui/material/Tabs";
import classes from "./customer.module.css";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Customers from "./Customers";
import Leads from "./Leads";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CustomerIndex() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            className={classes.HeadTabButton}
            aria-label="basic tabs example"
          >
            <Tab
              label={"Customers"}
              {...a11yProps(0)}
              style={{ textTransform: "capitalize" }}
            />
            <Tab
              label={"Leads"}
              {...a11yProps(1)}
              style={{ textTransform: "capitalize" }}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0} className={classes.TabPanel}>
          <Customers />
        </TabPanel>
        <TabPanel value={value} index={1} className={classes.TabPanel}>
          <Leads />
        </TabPanel>
      </Box>
    </div>
  );
}
