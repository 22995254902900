import React, { useState } from "react";
import classes from "../customer.module.css";
import {
  TableCell,
  TableRow,
  Typography,
  Avatar,
  Chip,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import avatar from "../../../../Assets/images/avatar-9.jpg";
import { BsThreeDotsVertical } from "react-icons/bs";
import { VictoryPie } from "victory";
import { VictoryLabel } from "victory-core";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { activeEmployee, inactiveEmployee } from "../../../../Redux/actions/customerActions";
import { generateColor, stringAvatar } from "../../../../Utilities/commonfunctions";

function TeamsListItem({ key, employee, team_id }) {
  const dispatch = useDispatch();
  const [openMenu, setOpenMenu] = useState();
  const { sortBy, invoice } = useSelector((state) => state.filter);
  const data = [
    { x: "Expired", y: employee?.localFields?.rejected },
    { x: "Expiring", y: employee?.localFields?.pending },
    { x: "Active", y: employee?.localFields?.completed },
  ];

  const handleMenuclick = (event) => {
    setOpenMenu(event.currentTarget);
  };

  const handleClose = () => {
    setOpenMenu(null);
  }

  const handleEmployeeActive = (name) => {
    if (name === "active") {
      dispatch(activeEmployee(team_id, sortBy, invoice, employee?._id));
    }
    else if (name === "inactive") {
      dispatch(inactiveEmployee(team_id, sortBy, invoice, employee?._id));
    }
    handleClose();
  }

  return (
    <TableRow className={`${classes.table_bg1}`} key={key}>
      <TableCell>
        <div className={classes.displayFlex}>
          {employee?.user_id?.profile_pic?.filename ?
            <Avatar
              alt="Remy Sharp"
              sx={{ width: 36 }}
              src={`${process.env.REACT_APP_IMAGE_URL}/${employee?.user_id?.profile_pic?.destination}${employee?.user_id?.profile_pic?.filename}`}
            />
            :
            <Avatar
              {...stringAvatar(`${employee?.name.trim()}`)}
              sx={{
                bgcolor: `${generateColor(`${employee?.name.trim()}`)}`,
              }}
              style={{ height: 36, width: 36, }}
            />
          }
          <Typography
            href="#javascript: void(0);"
            className="text-body align-middle fw-medium"
            style={{ textTransform: "capitalize" }}
          >
            {employee.name}
          </Typography>
        </div>
      </TableCell>
      <TableCell>{employee.phone}</TableCell>
      <TableCell>{moment(employee.created_at).format("DD MMM YYYY")}</TableCell>
      <TableCell>
        <div className={classes.displayFlex}>
          <svg viewBox="0 0 400 400" width="52px" height="auto">
            <VictoryPie
              standalone={false}
              width={400}
              height={400}
              data={data}
              innerRadius={100}
              colorScale={["#BA1221", "#FBC23F", "#3A974C"]}
              labels={() => null} // Hide labels
            />
            <VictoryLabel
              textAnchor="middle"
              verticalAnchor="middle"
              x={200}
              y={200}
              text={[employee?.localFields?.total]}
              style={{ fontSize: 100 }}
            />
          </svg>
          <div>
            <Typography className={classes.displayFlex}>
              <Avatar
                sx={{
                  bgcolor: `#3A974C`,
                  width: "6px",
                  height: "6px",
                }}
              >
                {" "}
              </Avatar>
              <span className={classes.someSmallText}>Reached:{employee?.localFields?.completed}</span>
            </Typography>
            <Typography className={classes.displayFlex}>
              <Avatar
                sx={{
                  bgcolor: `#FBC23F`,
                  width: "6px",
                  height: "6px",
                }}
              >
                {" "}
              </Avatar>
              <span className={classes.someSmallText}>Pending:{employee?.localFields?.pending}</span>
            </Typography>
            <Typography className={classes.displayFlex}>
              <Avatar
                sx={{
                  bgcolor: `#BA1221`,
                  width: "6px",
                  height: "6px",
                }}
              >
                {" "}
              </Avatar>
              <span className={classes.someSmallText}>Rejected:{employee?.localFields?.rejected}</span>
            </Typography>
          </div>
        </div>
      </TableCell>
      <TableCell>
        <span className="badge badge-soft-success p-2">
          {" "}
          <Chip
            label={employee?.is_active ? "Active" : "Inactive"}
            size="small"
            style={{
              backgroundColor: employee?.is_active ? "#E9F7EC" : "#FCE3E5",
              color: employee?.is_active ? "#317D41" : "#BA1221",
              padding: "10px",
            }}
          />
        </span>
      </TableCell>
      <TableCell style={{ minWidth: 0 }}>
        <Button
          variant="text"
          size="small"
          style={{ minWidth: 0 }}
          onClick={(e) => handleMenuclick(e)}
        >
          <BsThreeDotsVertical />
        </Button>
        <Menu
          anchorEl={openMenu}
          open={Boolean(openMenu)}
          onClose={handleClose}
          className={classes.InvoiceMenuOptions}
        >
          <MenuItem
            key={`active`}
            onClick={() => handleEmployeeActive("active")}
          >
            Activate
          </MenuItem>
          <MenuItem
            key={`inactive`}
            onClick={() => handleEmployeeActive("inactive")}
          >
            Deactivate
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
}

export default TeamsListItem;
