module.exports = {
  CREATE_PROMOCODE_REQUEST: "CREATE_PROMOCODE_REQUEST",
  CREATE_PROMOCODE_SUCCESS: "CREATE_PROMOCODE_SUCCESS",
  CREATE_PROMOCODE_FAIL: "CREATE_PROMOCODE_FAIL",
  PROMOCODE_LIST_REQUEST: "PROMOCODE_LIST_REQUEST",
  PROMOCODE_LIST_SUCCESS: "PROMOCODE_LIST_SUCCESS",
  PROMOCODE_LIST_FAIL: "PROMOCODE_LIST_FAIL",
  ONE_PROMOCODE_REQUEST: "ONE_PROMOCODE_REQUEST",
  ONE_PROMOCODE_SUCCESS: "ONE_PROMOCODE_SUCCESS",
  ONE_PROMOCODE_FAIL: "ONE_PROMOCODE_FAIL",
};
