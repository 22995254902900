import React, { useState } from "react";
import successimage from "../../../Assets/images/ILL_Sucess.png";
import classes from "./userManagement.module.css";
import { MdClose } from "react-icons/md";
import { Modal, Box, Button, Typography } from "@mui/material";

const ConfirmationModal = (props) => {
  const rootRef = React.useRef(null);
  const [modalToggle, setModalToggle] = useState(true);
  const modalClose = () => {
    props.modalClose(false);
    setModalToggle(false);
  };

  return (
    <div>
      <Modal
        open={modalToggle}
        onClose={() => modalClose()}
        aria-labelledby="server-modal-title"
        data-testid="register_modal"
        aria-describedby="server-modal-description"
        sx={{
          display: "flex",
          height: 480,
          marginTop: 10,
          justifyContent: "center",
        }}
        container={() => rootRef.current}
      >
        <Box
          sx={{
            position: "relative",
            bgcolor: "background.paper",
            borderRadius: 1,
            boxShadow: (theme) => theme.shadows[5],
          }}
          style={{ minWidth: "500px", height: "max-content", padding: "20px" }}
          className={classes.loginModalWidth}
        >
          <Button
            variant="text"
            sx={{ minWidth: "unset" }}
            className={classes.closebtn}
            onClick={() => modalClose()}
          >
            <MdClose />
          </Button>
          <div>
            {props?.deleteUser && props?.deleteUser === "delete" ? (
              <div>
                {props?.deleteStatus && (
                  <img
                    src={successimage}
                    alt="Success logo"
                    width={"auto"}
                    height={"auto"}
                  />
                )}
                <Typography className={classes.imagePageText}>
                  {props?.deleteStatus
                    ? "Yay! User has been Deleted Successfully"
                    : "Delete Confirmation"}
                </Typography>
                <Typography className={`${classes.successPageSubText} mt-2`}>
                  {props.deleteStatus
                    ? `${props.name} has been deleted successfully`
                    : `Are you sure you want to delete ${props.name}`}
                </Typography>
                {!props.deleteStatus && (
                  <div className={classes.addMemberModalbtnSection}>
                    <Button variant="outlined" onClick={() => modalClose()}>
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => props.callBack()}
                    >
                      Delete
                    </Button>
                  </div>
                )}
              </div>
            ) : (
              <div className={classes.successPageMainDiv}>
                <img
                  src={successimage}
                  alt="Success logo"
                  width={"auto"}
                  height={"auto"}
                />
                <Typography className={classes.imagePageText}>
                  {props.resetPassword
                    ? "Reset Password"
                    : "Yay! User has been Added"}
                </Typography>
                <Typography className={classes.successPageSubText}>
                  {props.resetPassword
                    ? "Email has been sent to the user to update reset password"
                    : "Email has been sent to the user to update about the same"}
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => modalClose()}
                  className={classes.AdduserMainBtn}
                  style={{ marginTop: "15px" }}
                >
                  Okay
                </Button>
              </div>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;
