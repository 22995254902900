import React, { useState } from "react";
import classes from "./userManagement.module.css";
import DatePicker from "react-datepicker";
import {
  Typography,
  Paper,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Button,
} from "@mui/material";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import ConfirmationModal from "./confirmationModal";
import { BrudCrumbBackIcon, BrudCrumbNextIcon, DatePickerCalendarIcon } from "../../../Components/Icons/Icons";
import { useNavigate } from "react-router-dom";
import { CreatenewAdminUser } from "../../../Redux/actions/userActions";
import { useDispatch } from "react-redux";
import { Numeric } from "../../../Utilities/commonfunctions";

const AddUserPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [Name, setName] = useState();
  const [empCode, setEmpCode] = useState();
  const [email, setEmail] = useState();
  const [mobile, setMobile] = useState();
  const [role, setRole] = useState();
  const [joiningDate, setJoiningDate] = useState();
  const [selectedValues, setSelectedValues] = useState([]);
  const [moddelToggle, setModdelToggle] = useState(false);

  const options = [
    { label: "Select All", value: "selectAll" },
    { label: "Dashboard", value: "dashboard" },
    { label: "Customers", value: "customers" },
    { label: "Subscription Management", value: "subscriptionManagement" },
    { label: "Referrals", value: "referrals" },
    { label: "Invoicing", value: "invoicing" },
    { label: "User Management", value: "userManagement" },
    { label: "Audit Trail", value: "auditTrail" },
    { label: "Reports", value: "reports" },
    { label: "Feedback", value: "feedback" },
    { label: "Support Tickers", value: "supportTickets" },
    { label: "Communication", value: "communication" },
  ];

  const handleSubmit = () => {
    if (selectedValues.length > 0) {
      const userEntry = {
        name: Name,
        empCode: empCode,
        email: email,
        phone: mobile,
        user_type: role,
        joiningDate: new Date(joiningDate),
        permissions: selectedValues,
      }
      dispatch(CreatenewAdminUser(userEntry));
      setModdelToggle(true);
    }
  };

  const handleCheckboxChange = (value) => {
    if (value === "selectAll") {
      if (selectedValues.includes(value)) {
        // Unselect all checkboxes
        setSelectedValues([]);
      } else {
        // Select all checkboxes
        setSelectedValues(options.map((option) => option.value));
      }
    } else {
      // Toggle the selection of individual checkboxes
      let newSelectedValues = [];

      if (selectedValues.includes(value)) {
        newSelectedValues = selectedValues.filter((v) => v !== value);
      } else {
        newSelectedValues = [...selectedValues, value];
      }
      setSelectedValues(newSelectedValues);

      // Check the "All" checkbox if all others are selected
      const areAllSelected = options
        .filter((item) => item.value !== "selectAll")
        .every((item) => newSelectedValues.includes(item.value));

      if (areAllSelected) {
        setSelectedValues([...newSelectedValues, "selectAll"]);
      } else {
        // Uncheck the "All" checkbox if any other checkbox is unselected
        setSelectedValues(
          newSelectedValues.filter((item) => item !== "selectAll")
        );
      }
    }
  };

  const closeModal = () => {
    setModdelToggle(false);
    navigate("/user-management");
  };

  return (
    <div>
      <div className={classes.brudCrumb}>
        <span onClick={() => navigate(-1)}><BrudCrumbBackIcon /></span>
        <span onClick={() => navigate("/user-management")}>Subscription</span>
        <span><BrudCrumbNextIcon /></span>
        <span>{'Add User'}</span>
      </div>
      <Paper style={{ padding: "20px", marginTop: "20px" }}>
        <div>
          <Typography className={classes.FormHEader}>Add User</Typography>
          <ValidatorForm
            useref="form"
            onSubmit={handleSubmit}
            style={{ width: "100%" }}
            className={classes.InputForm}
          >
            <div className={classes.InputFormGridContainer}>
              <div>
                <Typography className={classes.formLable}>Name*</Typography>
                <TextValidator
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    marginBottom: "15px",
                  }}
                  id="emailidInput"
                  fullWidth
                  value={Name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  size="small"
                  validators={["required"]}
                  errorMessages={["Name is required"]}
                />
              </div>
              <div>
                <Typography className={classes.formLable}>
                  Employee Code
                </Typography>
                <TextValidator
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    marginBottom: "15px",
                  }}
                  id="emailidInput"
                  fullWidth
                  value={empCode}
                  onChange={(e) => {
                    setEmpCode(e.target.value);
                  }}
                  size="small"
                />
              </div>
              <div>
                <Typography className={classes.formLable}>Email ID</Typography>
                <TextValidator
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    marginBottom: "15px",
                  }}
                  id="emailidInput"
                  fullWidth
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  size="small"
                  validators={["required", "isEmail"]}
                  errorMessages={["Email Id is required", "Email is not valid"]}
                />
              </div>
              <div>
                <Typography className={classes.formLable}>
                  Mobile Number
                </Typography>
                <TextValidator
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    marginBottom: "15px",
                  }}
                  id="mobileInput"
                  fullWidth
                  value={mobile}
                  inputProps={{
                    maxLength: 10,
                  }}
                  onKeyPress={(e) => {
                    Numeric(e)
                  }}
                  onChange={(e) => {
                    setMobile(e.target.value);
                  }}
                  size="small"
                  validators={["required"]}
                  errorMessages={["Name is required"]}
                />
              </div>
              <div>
                <Typography className={classes.formLable}>Role*</Typography>
                <Select
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  className={classes.selectMenuDepartment}
                  fullWidth
                  required
                  MenuProps={{
                    style: {
                      height: "250px",
                    },
                  }}
                  size="small"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                >
                  <MenuItem
                    className={classes.AddMemberDepMenuItem}
                    value={"admin"}
                  >
                    Admin
                  </MenuItem>
                </Select>
              </div>
              <div>
                <Typography className={classes.formLable}>
                  Joining Date
                </Typography>
                <div className={classes.datePicker1}>
                  <DatePicker
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      width: "100%",
                    }}
                    size="small"
                    selected={joiningDate}
                    fullWidth
                    onChange={(date) => setJoiningDate(date)}
                    dateFormat="yyyy-MM-dd"
                    maxDate={new Date()}
                    required
                  />
                  <span
                    className={classes.datePickerCalendarIcon}
                    style={{ top: "7px" }}
                  >
                    <DatePickerCalendarIcon />
                  </span>
                </div>
              </div>
            </div>
            <Typography className={classes.FormHEader}>Permissions</Typography>
            <div
              style={{ display: "grid", marginTop: "10px", width: "max-content" }}
            >
              {options.map((option) => (
                <FormControlLabel
                  key={option.value}
                  control={
                    <Checkbox
                      style={{ color: "black", borderRadius: "5px" }}
                      checked={selectedValues.includes(option.value)}
                      onChange={() => handleCheckboxChange(option.value)}
                    />
                  }
                  label={
                    <span className={classes.custom_label}>{option.label}</span>
                  }
                />
              ))}
            </div>
            <div className={classes.AddUSerBtnSection}>
              <Button variant="outlined" className={classes.AdduserMainBtn} onClick={() => navigate("/user-management")}>
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                className={classes.AdduserMainBtn}
              >
                Submit
              </Button>
            </div>
          </ValidatorForm>
        </div>

        {moddelToggle ? <ConfirmationModal modalClose={closeModal} /> : null}
      </Paper>
    </div>
  );
};

export default AddUserPage;
