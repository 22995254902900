import React, { useState } from "react";
import classes from "./index.module.css";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch } from "react-redux";
import { FilterFunnelIcon } from "./Icons/Icons";

function FilterMenu(props) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null); // State to anchor the menu
  const [selectedValues, setSelectedValues] = useState([]); // State to store selected values

  const handleCheckboxChange = (value) => {
    const isSelected = selectedValues.includes(value);

    if (isSelected) {
      setSelectedValues(selectedValues.filter((v) => v !== value));
      dispatch(props.name(selectedValues.filter((v) => v !== value)))
    } else {
      setSelectedValues([...selectedValues, value]);
      dispatch(props.name([...selectedValues, value]))
    }
  };

  const handleFilterIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <span onClick={handleFilterIconClick}><FilterFunnelIcon /></span>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {props.clearAll && <MenuItem key={"claer_all"} onClick={() => setSelectedValues([])} className={classes.clearAll}>Clear All</MenuItem>}
        {props.option.map((item, index) => {
          return (
            < MenuItem key={index} style={{ padding: "6px 25px 6px 0", textTransform: "capitalize" }}>
              <Checkbox
                checked={selectedValues.includes(item)}
                onChange={() => handleCheckboxChange(item)}
              />
              {item?.replaceAll("_", " ")}
            </MenuItem>
          );
        })}
      </Menu>
    </div >
  );
}

export default FilterMenu;
