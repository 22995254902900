import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  createSubscription,
  fetchSubscriptions,
} from "../../../Redux/actions/subscriptionAction";
import { useNavigate } from "react-router-dom";
import classes from "./Subscription.module.css";
import {
  BrudCrumbBackIcon,
  BrudCrumbNextIcon,
  DeleteBinIcons,
  RoundAddIcon,
} from "../../../Components/Icons/Icons";
import { Typography, MenuItem, Button } from "@mui/material";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import SuccessModal from "./successModal";
import { Numeric } from "../../../Utilities/commonfunctions";

const AddSubscription = () => {
  const navigate = useNavigate();
  const [features, setFeatures] = useState("");
  const [featuresList, setFeaturesList] = useState([]);
  const [durationList, setDurationList] = useState([]);
  const [success, setSuccess] = useState(false);
  const [duration, setDuration] = useState("");
  const [price, setprice] = useState("");
  const [packageName, setPackageName] = useState("");
  const [noOfTeamMembers, setNoOfTeamMembers] = useState("");
  const dispatch = useDispatch();

  // const handleAddFeature = () => {
  //   setFeatures([...features, { id: features.length + 1, feature: "" }]);
  // };
  // const handleDeleteFeature = () => {
  //   setFeatures(features.slice(0, features.length - 1));
  // };

  // const handleFeatureChange = (id, feature) => {
  //   const updatedFeatures = features.map((feature_1) =>
  //     feature_1.id === id ? { ...feature_1, feature } : feature_1
  //   );
  //   setFeatures(updatedFeatures);
  // };

  // const renderFeatures = () => {
  //   return features.map((feature) => (
  //     <div key={feature.id}>
  //       <label
  //         htmlFor={`featureInput${feature.id}`}
  //         className="form-label my-2"
  //       >
  //         Enter Features {feature.id}
  //       </label>
  //       <textarea
  //         className="form-control"
  //         placeholder="Enter Features here"
  //         id={`featureInput${feature.id}`}
  //         rows={4}
  //         value={feature.value}
  //         onChange={(e) => handleFeatureChange(feature.id, e.target.value)}
  //       />
  //     </div>
  //   ));
  // };
  const handleDelete = (index) => {
    const updatedInputs = [...durationList];
    updatedInputs.splice(index, 1);
    setDurationList(updatedInputs);
  };

  const handleDeleteFeature = (index) => {
    const updatedInputs = [...featuresList];
    updatedInputs.splice(index, 1);
    setFeaturesList(updatedInputs);
  };

  const handleAddDurationPrice = () => {
    const newInput = {
      duration: duration,
      price: price,
    };

    setDurationList([...durationList, newInput]);
    setDuration("");
    setprice("");
  };
  const handleAddFeature = () => {
    const newInput = {
      id: featuresList.length + 1,
      feature: features,
    };

    setFeaturesList([...featuresList, newInput]);
    setFeatures("");
  };

  const getDays = (name) => {
    console.log(name);
    if (name === "annually") {
      return 365;
    } else if (name === "half_yearly") {
      return 180;
    } else if (name === "quarterly") {
      return 90;
    } else if (name === "monthly") {
      return 30;
    } else if (name === "7_days_Trial") {
      return 7;
    } else if (name === "14_days_Trial") {
      return 14;
    } else {
      return 0;
    }
  };

  // const [subscriptionData, setSubscriptionData] = useState({
  //   packageName: '',
  //   noOfTeamMembers: '',
  //   durations: [{ duration: '', price: '' }],
  //   features: [],
  // });

  // const dispatch = useDispatch();
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   dispatch(createSubscription(subscriptionData));
  // };

  const modalClose = () => {
    setSuccess(false);
    dispatch(fetchSubscriptions(1, 10, "", "packageName"));
    navigate("/subscription-management");
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (durationList && durationList.length > 0 && featuresList && featuresList.length > 0) {
      const durations = durationList.map((item) => {
        return {
          duration: item.duration,
          days: getDays(item.duration),
          price: item.price,
        };
      });
      const subscriptionData = {
        packageName,
        noOfTeamMembers,
        durations,
        features: featuresList,
      };
      console.log(subscriptionData);
      dispatch(createSubscription(subscriptionData));
      dispatch(fetchSubscriptions(1, 10, "", "packageName"));
      setSuccess(true);
      // navigate("/subscription-management");

    } else {
      alert("Please check you have entered durations and featues");
    }
  };

  return (
    <div>
      <div className={`${classes.brudCrumb} mb-3`}>
        <span onClick={() => navigate(-1)}>
          <BrudCrumbBackIcon />
        </span>
        <span
          className={classes.packageSmallHEading}
          onClick={() => navigate("/subscription-management")}
        >
          Subscription
        </span>
        <BrudCrumbNextIcon />
        <span className={classes.login_form_lable}>Add Subscription</span>
      </div>
      <div className="row">
        <div className="col-xxl-12">
          <div className="card p-4">
            <Typography className={classes.addSubscHeading}>
              Add Subscription
            </Typography>
            <Typography className={`${classes.addSubscSubHeading} mt-4 mb-3`}>
              Package name & no. of team members
            </Typography>
            {/* end card header */}
            <div>
              <ValidatorForm useref="form" onSubmit={handleSubmit}>
                <div className="">
                  <div
                    className={classes.displayFlex}
                    style={{ columnGap: "20px" }}
                  >
                    <div>
                      <Typography
                        htmlFor="firstNameinput"
                        className={`mb-2 ${classes.login_form_lable}`}
                      >
                        Package Name
                      </Typography>
                      <TextValidator
                        sx={{
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                          width: 345,
                        }}
                        fullWidth
                        className={classes.inputs}
                        value={packageName}
                        onChange={(e) => setPackageName(e.target.value)}
                        required={true}
                        size="small"
                      />
                    </div>
                    <div>
                      <Typography
                        htmlFor="firstNameinput"
                        className={`mb-2 ${classes.login_form_lable}`}
                      >
                        No. of Team members
                      </Typography>
                      <TextValidator
                        sx={{
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                          width: 345,
                        }}
                        fullWidth
                        className={classes.inputs}
                        value={noOfTeamMembers}
                        onChange={(e) =>
                          setNoOfTeamMembers(e.target.value)
                        }
                        required={true}
                        size="small"
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="col-md-2"></div>
                  <Typography className={classes.addSubscSubHeading}>
                    Duration
                  </Typography>
                  <div className="mt-4">
                    {durationList.map((input, index) => (
                      <div
                        key={index}
                        className={classes.displayFlex}
                        style={{ columnGap: "20px" }}
                      >
                        <div className="mb-3">
                          <Typography
                            htmlFor={`durationInput-${index}`}
                            className={`mb-2 ${classes.login_form_lable}`}
                          >
                            Duration
                          </Typography>
                          <TextValidator
                            sx={{
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                              width: 345,
                            }}
                            select
                            fullWidth
                            className={classes.inputs}
                            value={input.duration}
                            onChange={(e) => {
                              const updatedInputs = [...durationList];
                              updatedInputs[index].duration = e.target.value;
                              setDurationList(updatedInputs);
                            }}
                            required={true}
                            size="small"
                          >
                            <MenuItem value="">Select</MenuItem>
                            <MenuItem value="annually">Annually</MenuItem>
                            <MenuItem value="half_yearly">Half Yearly</MenuItem>
                            <MenuItem value="quarterly">Quarterly</MenuItem>
                            <MenuItem value="monthly">Monthly</MenuItem>
                            <MenuItem value="14_days_Trial">
                              14 Days Trial
                            </MenuItem>
                            <MenuItem value="7_days_Trial">7 Days Trial</MenuItem>
                          </TextValidator>
                        </div>
                        <div className="mb-3">
                          <Typography
                            htmlFor={`priceInput-${index}`}
                            className={`mb-2 ${classes.login_form_lable}`}
                          >
                            Price
                          </Typography>
                          <TextValidator
                            sx={{
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                              width: 345,
                            }}
                            fullWidth
                            className={classes.inputs}
                            value={input.price}
                            onChange={(e) => {
                              const updatedInputs = [...durationList];
                              updatedInputs[index].price = e.target.value;
                              setDurationList(updatedInputs);
                            }}
                            onKeyPress={(e) => {
                              Numeric(e);
                            }}
                            required={true}
                            size="small"
                          />
                        </div>
                        <div>
                          {durationList.length > 0 && (
                            <Button
                              onClick={() => handleDelete(index)}
                              className={classes.deleteButton}
                            >
                              <DeleteBinIcons />
                            </Button>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                  <Button
                    variant="text"
                    className={`mb-3 mt-2 ${classes.addButton}`}
                    onClick={(e) => {
                      handleAddDurationPrice();
                      e.preventDefault();
                    }}
                  >
                    <RoundAddIcon />
                    <span>Add duration &amp; price</span>
                  </Button>
                  <div>
                    <Typography
                      className={`mb-4 ${classes.addSubscSubHeading}`}
                    >
                      Features
                    </Typography>
                    <div>
                      {featuresList.map((feature, index) => (
                        <React.Fragment key={index}>
                          <div key={feature.id} className="mb-3">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                htmlFor={`durationInput-${index}`}
                                className={`mb-2 ${classes.login_form_lable}`}
                              >
                                Enter Features {feature.id}
                              </Typography>
                              <Button
                                onClick={() => handleDeleteFeature(index)}
                                className={classes.deleteButton}
                              >
                                <DeleteBinIcons />
                              </Button>
                            </div>
                            <TextValidator
                              sx={{
                                "& legend": { display: "none" },
                                "& fieldset": { top: 0 },
                              }}
                              fullWidth
                              multiline
                              rows={4}
                              placeholder="Enter Features here"
                              value={feature.feature}
                              onChange={(e) => {
                                const updatedInputs = [...featuresList];
                                updatedInputs[index].feature = e.target.value;
                                setFeaturesList(updatedInputs);
                              }}
                            />
                            <Typography className={classes.SomeOtheText}>{feature.feature.length}/500</Typography>
                          </div>

                          {/*end col*/}
                        </React.Fragment>
                      ))}
                      <Button
                        variant="text"
                        className={`mb-3 mt-2 ${classes.addButton}`}
                        onClick={(e) => {
                          handleAddFeature();
                          e.preventDefault();
                        }}
                      >
                        <RoundAddIcon />
                        <span>Add Features</span>
                      </Button>
                    </div>
                  </div>
                  {/*end col*/}
                  <div className={classes.AddSubsButtonSection}>
                    <Button
                      variant="outlined"
                      onClick={() => navigate("/subscription-management")}
                    >
                      Cancel
                    </Button>
                    <Button variant="contained" type="submit">
                      Save
                    </Button>
                  </div>
                  {/*end col*/}
                </div>
                {/*end col*/}
              </ValidatorForm>
            </div>
            {/*end row*/}
          </div>
        </div>
      </div>
      {/* end col */}
      {/* end col */}
      {/* <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">© Team player</div>
            <div className="col-sm-6">
              <div className="text-sm-end d-none d-sm-block">
                All Rights Reserved. Powered By .
              </div>
            </div>
          </div>
        </div>
      </footer> */}
      {/* end main content*/}
      {success ? <SuccessModal modalClose={modalClose} type={"add"} /> : null}
      {/*start back-to-top*/}
      {/* <button
        onclick="topFunction()"
        className="btn btn-info btn-icon"
        id="back-to-top"
      >
        <i className="ri-arrow-up-line" />
      </button> */}
    </div>
  );
};

export default AddSubscription;
