import React, { useState } from 'react'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import { useDispatch } from 'react-redux';
import { InvoiceFilterAction } from '../Redux/actions/filterActions';
import { FilterFunnelIcon } from './Icons/Icons';

function InvoiceFilter({ Communication }) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null); // State to anchor the menu
  const [selectedValues, setSelectedValues] = useState([]); // State to store selected values

  const handleCheckboxChange = (value) => {
    const isSelected = selectedValues.includes(value);

    if (isSelected) {
      setSelectedValues(selectedValues.filter((v) => v !== value));
      console.log(selectedValues.filter((v) => v !== value))
      dispatch(InvoiceFilterAction(selectedValues.filter((v) => v !== value)))
    } else {
      setSelectedValues([value]);
      console.log([value])
      dispatch(InvoiceFilterAction([value]))
    }

  };

  const handleFilterIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <span onClick={handleFilterIconClick}><FilterFunnelIcon /></span>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem style={{ padding: "6px 25px 6px 0" }}>
          <Checkbox
            checked={selectedValues.includes(true)}
            onChange={() => handleCheckboxChange(true)}
          />
          {Communication ? "Delivered" : "Active"}
        </MenuItem>
        <MenuItem style={{ padding: "6px 25px 6px 0" }}>
          <Checkbox
            checked={selectedValues.includes(false)}
            onChange={() => handleCheckboxChange(false)}
          />
          {Communication ? "Not Delivered" : "Inactive"}
        </MenuItem>
      </Menu>
    </div>
  );
}

export default InvoiceFilter;
