import React, { useEffect } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import classes from "./referral.module.css";
import { OppositeSortArrawBtnIcons, SortArrawBtnIcons } from "../../../Components/Icons/Icons";
import InvoiceFilter from "../../../Components/InvoiceFilters";
import Pagination from "../../../Components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { getPromoCodeDetails } from "../../../Redux/actions/promoCodeActions";
import PromoCodeListItems from "./promoCodeListItems";
import { handleSortClick } from "../../../Utilities/commonfunctions";

const Promocodes = () => {
  const dispatch = useDispatch();
  const PaginationData = useSelector(
    (state) => state.paginationData.Pagination
  );
  const { rowCount, currentPage } = PaginationData || {};
  const { sortBy, invoice } = useSelector((state) => state.filter);
  const { promocodeList, listError, loading, totalCount } = useSelector((state) => state.promoCodeData)

  useEffect(() => {
    const filterArray = {
      status: invoice,
    }
    dispatch(getPromoCodeDetails(rowCount, currentPage, sortBy, filterArray));
  }, [rowCount, currentPage, sortBy, invoice, dispatch]);

  return (
    <div>
      <div className={classes.tableContainer}>
        <Table
          style={{
            marginTop: "20px",
          }}
          className={classes.MainTable}
        >
          <TableHead>
            <TableRow className={classes.tableHeadRow}>
              <TableCell />
              <TableCell className={classes.tableHeadCell}>
                <div className={classes.tableHEadercelldiv}>
                  <span> Referral Code </span>{" "}
                  {/* <FilterMenu
                    option={Industries}
                    name={CustomerIndustyFilter}
                  />{" "} */}
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "referral_code")
                    }
                  >
                    {sortBy?.includes("desc") &&
                      sortBy?.includes("referral_code") ? (
                      <OppositeSortArrawBtnIcons />
                    ) : (
                      <SortArrawBtnIcons />
                    )}
                  </span>
                </div>
              </TableCell>{" "}
              <TableCell className={classes.tableHeadCell}>
                <div className={classes.tableHEadercelldiv}>
                  <span> Referral Name </span>{" "}
                  {/* <FilterMenu
                    option={Industries}
                    name={CustomerIndustyFilter}
                  />{" "} */}
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "referral_name")
                    }
                  >
                    {sortBy?.includes("desc") &&
                      sortBy?.includes("referral_name") ? (
                      <OppositeSortArrawBtnIcons />
                    ) : (
                      <SortArrawBtnIcons />
                    )}
                  </span>
                </div>
              </TableCell>{" "}
              <TableCell className={classes.tableHeadCell}>
                <div className={classes.tableHEadercelldiv}>
                  <span> Discount </span>{" "}
                  {/* <FilterMenu
                    option={Industries}
                    name={CustomerIndustyFilter}
                  />{" "} */}
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "discount")
                    }
                  >
                    {sortBy?.includes("desc") &&
                      sortBy?.includes("discount") ? (
                      <OppositeSortArrawBtnIcons />
                    ) : (
                      <SortArrawBtnIcons />
                    )}
                  </span>
                </div>
              </TableCell>{" "}
              <TableCell className={classes.tableHeadCell}>
                <div className={classes.tableHEadercelldiv}>
                  <span> Start Date </span>{" "}
                  {/* <FilterMenu
                    option={Industries}
                    name={CustomerIndustyFilter}
                  />{" "} */}
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "start_date")
                    }
                  >
                    {sortBy?.includes("desc") &&
                      sortBy?.includes("start_date") ? (
                      <OppositeSortArrawBtnIcons />
                    ) : (
                      <SortArrawBtnIcons />
                    )}
                  </span>
                </div>
              </TableCell>{" "}
              <TableCell className={classes.tableHeadCell}>
                <div className={classes.tableHEadercelldiv}>
                  <span> End Date </span>{" "}
                  {/* <FilterMenu
                    option={Industries}
                    name={CustomerIndustyFilter}
                  />{" "} */}
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "end_date")
                    }
                  >
                    {sortBy?.includes("desc") &&
                      sortBy?.includes("end_date") ? (
                      <OppositeSortArrawBtnIcons />
                    ) : (
                      <SortArrawBtnIcons />
                    )}
                  </span>
                </div>
              </TableCell>{" "}
              <TableCell
                className={classes.tableHeadCell}
                style={{
                  minWidth: "205px",
                }}
              >
                <div className={classes.tableHEadercelldiv}>
                  <span> Total Subscription </span>{" "}
                  {/* <FilterMenu
                    option={Industries}
                    name={CustomerIndustyFilter}
                  />{" "} */}
                </div>
              </TableCell>{" "}
              <TableCell className={classes.tableHeadCell}>
                <div className={classes.tableHEadercelldiv}>
                  <span> Status </span>
                  <InvoiceFilter />
                </div>
              </TableCell>{" "}
            </TableRow>{" "}
          </TableHead>{" "}
        </Table>
        <Table>
          <TableBody className={`list form-check-all ${classes.MainTable}`}>
            {loading ? (
              <TableRow>
                <TableCell colSpan="9" className="text-center">
                  Loading...
                </TableCell>{" "}
              </TableRow>
            ) : listError ? (
              <TableRow>
                <TableCell colSpan="9" className="text-center">
                  {" "}
                  {listError}{" "}
                </TableCell>{" "}
              </TableRow>
            ) : promocodeList && promocodeList.length > 0 ? (
              promocodeList
                .map((item, index) => (
                  <PromoCodeListItems key={index} promoCode={item} />
                ))
            ) : (
              <TableRow>
                <TableCell colSpan="9" className="text-center">
                  No Data Found{" "}
                </TableCell>{" "}
              </TableRow>
            )}{" "}
          </TableBody>{" "}
        </Table>{" "}
      </div>
      <Pagination total={totalCount} />
    </div>
  );
};

export default Promocodes;
