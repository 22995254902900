import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { userLoginReducer } from "./reducers/userReducers";
import { customerReducer } from "./reducers/customerReducers";
import { paginationReducer } from "./reducers/paginationReducer";
import {
  subscriptionsReducer,
  createSubscriptionReducer,
  subscriptionActivatingReducer,
} from "./reducers/subscriptionReducer";
import { filterReducer } from "./reducers/filterReducer";
import { feedbackReducer } from "./reducers/feedbackReducers";
import { supportTicketReducer } from "./reducers/supportTicketReducers";
import { auditTrialReducers } from "./reducers/auditTrialReducers";
import { reportReducers } from "./reducers/reportReducers";
import { communicationReducers } from "./reducers/communicationReducers";
import { promoCodeReducer } from "./reducers/promoCodeReducer";
import { PiechartReducers } from "./reducers/piechartReducers";
import { ContactUsReducers } from "./reducers/contactUsReducers";
import { BlogsReducers } from "./reducers/blogsReducers";
import { notificationReducers } from "./reducers/notificationReducers";

const reducer = combineReducers({
  userLogin: userLoginReducer,
  customerData: customerReducer,
  paginationData: paginationReducer,
  subscriptions: subscriptionsReducer,
  createsubscription: createSubscriptionReducer,
  activatesubscription: subscriptionActivatingReducer,
  filter: filterReducer,
  feedbackData: feedbackReducer,
  supportTicketData: supportTicketReducer,
  auditTrialDatas: auditTrialReducers,
  reportData: reportReducers,
  communicationDate: communicationReducers,
  promoCodeData: promoCodeReducer,
  piechartData: PiechartReducers,
  contactUsData: ContactUsReducers,
  blogsData: BlogsReducers,
  notificationDate: notificationReducers,
});
const userInfoFromStorage = sessionStorage.getItem("userInfo")
  ? JSON.parse(sessionStorage.getItem("userInfo"))
  : null;
const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};
const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
