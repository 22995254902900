import React from 'react'
import classes from "./supportTicket.module.css"
import { useNavigate } from 'react-router-dom';
import { Button, Box, Tab, Tabs } from '@mui/material';
import { RoundAddIcon } from '../../../Components/Icons/Icons';
import TeamLeaderPage from './teamLeaderPage';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const SuportTicketIndex = () => {
    const navigate = useNavigate();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                    onClick={() => navigate("/support-tickets/create-ticket")}
                    variant="contained"
                    color="primary"
                    className={classes.AddNotificationMainBtn}
                >
                    <span>
                        <RoundAddIcon />
                    </span>
                    Create Ticket
                </Button>
            </div>
            <div>
                <Box>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                        >
                            <Tab
                                label={"Team Leader"}
                                {...a11yProps(0)}
                                style={{ textTransform: "capitalize" }}
                            />
                            <Tab
                                label={"Team Member"}
                                {...a11yProps(1)}
                                style={{ textTransform: "capitalize" }}
                            />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0} className={classes.TabPanel}>
                        <TeamLeaderPage user_type={"team_leader"} />
                    </TabPanel>
                    <TabPanel value={value} index={1} className={classes.TabPanel}>
                        <TeamLeaderPage user_type={"team_player"} />
                    </TabPanel>
                </Box>
            </div>
        </div>
    )
}

export default SuportTicketIndex;