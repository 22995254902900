import React, { useState } from "react";
import { Modal, Box, Button, Typography } from "@mui/material";
import image from "../../../Assets/images/ILL_Sucess.png";
import classes from "./profile.module.css";
import { MdClose } from "react-icons/md";

const SuccessPopup = (props) => {
    const rootRef = React.useRef(null);
    const [modalToggle, setModalToggle] = useState(true);

    const modalClose = (data) => {
        props.modalClose(false);
        setModalToggle(false);
    };


    return (
        <div>
            <Modal
                open={modalToggle}
                onClose={() => modalClose()}
                aria-labelledby="server-modal-title"
                data-testid="register_modal"
                aria-describedby="server-modal-description"
                sx={{
                    display: "flex",
                    height: 400,
                    marginTop: 10,
                    justifyContent: "center",
                }}
                container={() => rootRef.current}
            >
                <Box
                    sx={{
                        position: "relative",
                        bgcolor: "background.paper",
                        borderRadius: 1,
                        boxShadow: (theme) => theme.shadows[5],
                    }}
                    style={{
                        width: "500px",
                        height: "max-content",
                        top: "20%",
                    }}
                    className={classes.loginModalWidth}
                >
                    <Button
                        variant="text"
                        sx={{ minWidth: "unset" }}
                        className={classes.closebtn}
                        onClick={() => modalClose()}
                    >
                        <MdClose />
                    </Button>
                    <div className={classes.addMembersuccess}>
                        <img src={image} alt="success" width="auto" />
                        <Typography className={classes.imagePageText}>
                            Profile Update
                        </Typography>
                        <Typography className={classes.successPageSubText}>
                            Your profile has been updated successfully
                        </Typography>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default SuccessPopup;
