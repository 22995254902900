import React, { useState } from "react";
import { Modal, Button } from "@mui/material";
import { MdClose } from "react-icons/md";
import successimage from "../../../Assets/images/ILL_Sucess.png";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import classes from "./customer.module.css";
import {
  editCompany,
  fetchCustomer,
} from "../../../Redux/actions/customerActions";

const AddDepartmentModal = (props) => {
  const dispatch = useDispatch();
  const { modalClose, values } = props;
  const {
    company_name,
    gst_number,
    address_one,
    industry,
    pin,
    address_two,
    user_id,
    _id,
  } = values || {};
  const [modalToggle, setModalToggle] = useState(true);
  const [success, setSuccess] = useState();
  const rootRef = React.useRef(null);
  const [companyName, setCompanyName] = useState(company_name);
  const [address, setAddress] = useState(address_one);
  const [pinCode, setPinCode] = useState(pin);
  const [GST, setGST] = useState(gst_number);
  const [addressTwo, setAddressTwo] = useState(address_two);
  const [industryType, setIndustryType] = useState(industry);

  function closeModal(data) {
    modalClose(false);
    setModalToggle(false);
    getCustomer();
  }

  const getCustomer = () => {
    const path = window.location.pathname;
    const arr = path.split("/");
    console.log(arr);
    dispatch(fetchCustomer(arr[arr.length - 1]));
  };

  const handleSubmit = () => {
    let editedCompany = {
      company_name: companyName,
      pin: pinCode,
      address_one: address,
      address_two: addressTwo,
      industry: industryType,
      gst_number: GST,
    };
    console.log(editedCompany);
    dispatch(editCompany(props.id, editedCompany, props._id));
    setSuccess(true);
  };

  return (
    <div>
      <Modal
        open={modalToggle}
        onClose={() => closeModal()}
        aria-labelledby="server-modal-title"
        data-testid="register_modal"
        aria-describedby="server-modal-description"
        sx={{
          display: "flex",
          height: "max-content",
          marginTop: 10,
          justifyContent: "center",
        }}
        container={() => rootRef.current}
      >
        <Box
          sx={{
            position: "relative",
            bgcolor: "background.paper",
            borderRadius: 1,
            boxShadow: (theme) => theme.shadows[5],
          }}
          style={{ width: "420px", height: "auto" }}
        >
          <Button
            variant="text"
            sx={{ minWidth: "unset" }}
            className={classes.closebtn}
            onClick={() => closeModal()}
          >
            <MdClose />
          </Button>
          {!success && (
            <div className={classes.AddMemberHeading}>
              <Typography>Edit Company</Typography>
            </div>
          )}
          {!success ? (
            <ValidatorForm
              useref="form"
              onSubmit={handleSubmit}
              className={classes.formContainer}
            >
              <div className={classes.depFormContainer}>
                <div>
                  <div className="mt-3 mb-3">
                    <Typography className={classes.form_lable}>
                      Company Name*
                    </Typography>
                    <TextValidator
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      placeholder=""
                      id="address1ControlTextarea"
                      value={companyName}
                      fullWidth
                      onChange={(e) => setCompanyName(e.target.value)}
                      size="small"
                      validators={["required"]}
                      errorMessages={["This Feild is required"]}
                    />
                  </div>
                </div>
                <div>
                  <div className="mb-3">
                    <Typography className={classes.form_lable}>
                      GST Number*
                    </Typography>
                    <TextValidator
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      placeholder=""
                      id="address1ControlTextarea"
                      value={GST}
                      onChange={(e) => setGST(e.target.value)}
                      fullWidth
                      size="small"
                      validators={[
                        "required",
                        "matchRegexp:^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$",
                      ]}
                      errorMessages={[
                        "GST Number is required",
                        "This is not a valid GST Number",
                      ]}
                    />
                  </div>
                </div>
                <div>
                  <div className="mb-3">
                    <Typography className={classes.form_lable}>
                      Pin Number*
                    </Typography>
                    <TextValidator
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      placeholder=""
                      id="address1ControlTextarea"
                      value={pinCode}
                      fullWidth
                      onChange={(e) => setPinCode(e.target.value)}
                      size="small"
                      validators={["required"]}
                      errorMessages={["Pin Number is required"]}
                    />
                  </div>
                </div>
                <div>
                  <div className="mb-3">
                    <Typography className={classes.form_lable}>
                      Address*
                    </Typography>
                    <TextValidator
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      placeholder=""
                      id="address1ControlTextarea"
                      value={address}
                      fullWidth
                      onChange={(e) => setAddress(e.target.value)}
                      size="small"
                      validators={["required"]}
                      errorMessages={["Address is required"]}
                    />
                    <TextValidator
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        marginTop: "10px ",
                      }}
                      placeholder=""
                      id="address1ControlTextarea"
                      value={addressTwo}
                      fullWidth
                      onChange={(e) => setAddressTwo(e.target.value)}
                      size="small"
                      // validators={["required"]}
                      // errorMessages={["Department Name is required"]}
                    />
                  </div>
                </div>
                <div>
                  <div className="mb-3">
                    <Typography className={classes.form_lable}>
                      Industry Type*
                    </Typography>
                    <TextValidator
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      placeholder=""
                      id="address1ControlTextarea"
                      value={industryType}
                      fullWidth
                      onChange={(e) => setIndustryType(e.target.value)}
                      size="small"
                      validators={["required"]}
                      errorMessages={["Industry Type is required"]}
                    />
                  </div>
                </div>
                <div className={"addMemberModalbtnSection"}>
                  <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                    className="mb-4"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </ValidatorForm>
          ) : (
            <div className={classes.addMembersuccess}>
              <img
                src={successimage}
                alt="Success logo"
                width={200}
                height={200}
              />
              <Typography className={classes.successMainText}>
                Company Edited Successfully
              </Typography>
              <Typography className={classes.successSubText}>
                Your Changes to the Company Has been Updated
              </Typography>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default AddDepartmentModal;
// import { useNavigate } from 'react-router-dom';
// import Modal from 'react-bootstrap/Modal';
// import { useDispatch,useSelector } from 'react-redux'
// import { editCompany } from '../../actions/customerActions'

// function EditCompanyModal(Props) {

//     const navigate = useNavigate();
//     const dispatch = useDispatch();

//     const companyId = useSelector((state) => state.customerData.customer);
//     const { _id, user_id} = companyId || {};

//   const {show, handleClose, values} = Props;

//   useEffect(() => {  }, [values]);
//   //console.log("Values",values);
//   const {  company_name, gst_number, address_one, industry, pin, address_two} = values || {};

//   const [isvisble,setIsVisble] = useState(true)
//   const [companyName,setCompanyName] = useState(company_name)
//   const [address,setAddress] = useState(address_one)
//   const [pinCode, setPinCode] = useState(pin);
//   const [addressTwo, setAddressTwo] = useState(address_two);
//   const [industryType,setIndustryType] = useState(industry)

//   const handleSubmit = ( ) => {
//     let editedCompany = {
//         company_name: companyName,
//         pin: pinCode,
//         address_one: address,
//         address_two: addressTwo,
//         industry: industryType
//       }
//     console.log(editedCompany);
//     dispatch(editCompany(user_id._id, editedCompany, _id));
//     setIsVisble(false);
//     navigate(`/customer/profile/${_id}`)
//  }

// /* handleSubmit Will be passed on later here*/
//   return (
//     <div>
//      <Modal show={show && isvisble} onHide={handleClose} >
//         <Modal.Header closeButton>
//         <h5 className="modal-title mx-0" id="exampleModalLabel">Edit Company</h5>
//         </Modal.Header>
//         <Modal.Body  className="modal-body text-center" >
//         <form className="tablelist-form" >
//               <div className="modal-body">
//                 <div className="mb-3" id="modal-id" >
//                   <label htmlFor="id-field" className="form-label">Company Name</label>
//                   <input type="text" id="id-field" className="form-control" defaultValue={companyName} onChange={(e) => setCompanyName(e.target.value)}/>
//                 </div>
//                 <div className="mb-3" id="modal-id" >
//                   <label htmlFor="id-field" className="form-label">GST Number</label>
//                   <input type="text" id="id-field" className="form-control" defaultValue={gst_number} readOnly/>
//                 </div>
//                 <div className="mb-3" id="modal-id" >
//                   <label htmlFor="id-field" className="form-label">Pin Number</label>
//                   <input type="text" id="id-field" className="form-control" defaultValue={pinCode} onChange={(e) => setPinCode(e.target.value)}/>
//                 </div>
//                 <div className="mb-3" id="modal-id" >
//                   <label htmlFor="id-field" className="form-label">Address</label>
//                   <input type="text" id="id-field" className="form-control" defaultValue={address}  onChange={(e) => setAddress(e.target.value)} />
//                   <input type="text" id="id-field" className="form-control" style={{marginTop:"25px"}} defaultValue={addressTwo}  onChange={(e) => setAddressTwo(e.target.value)} />
//                 </div>
//                 <div className="mb-3" id="modal-id" >
//                   <label htmlFor="id-field" className="form-label">Industry Type</label>
//                   <input type="text" id="id-field" className="form-control" defaultValue={industryType} onChange={(e) => setIndustryType(e.target.value)}/>
//                 </div>
//                 <div className="mt-4">
//                   <button className="btn btn-primary w-100" type="button" onClick={handleSubmit}>Send</button>
//                 </div>
//               </div>
//             </form>
//         </Modal.Body>
//       </Modal>
//     </div>
//   )
// }

// export default EditCompanyModal
