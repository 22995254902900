import React, { useState } from "react";
import classes from "./referral.module.css";
import copy from "copy-to-clipboard";
import Box from "@mui/material/Box";
import moment from "moment";
import {
    Collapse,
    Chip,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    Menu,
    MenuItem
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useNavigate } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";
import { getPromoCodeDetails, updatePromoCodeFunction } from "../../../Redux/actions/promoCodeActions";
import { useDispatch, useSelector } from "react-redux";

const PromoCodeListItems = ({ promoCode }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const { sortBy } = useSelector((state) => state.filter);
    const handleMenuclick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleActiveClick = (e, id, status) => {
        console.log(id);
        e.stopPropagation();
        const userEntry = {
            is_active: status
        }
        dispatch(updatePromoCodeFunction(userEntry, id));
        dispatch(getPromoCodeDetails(10, 1, sortBy));
        handleClose();
    }

    const handleEditClick = (e, id) => {
        e.stopPropagation();
        navigate(`/referrals/edit-promocode/${id}`);
        handleClose();
    }

    const handleCopyText = (e, code) => {
        e.stopPropagation();
        copy(code);
        handleClose();
    }

    return (
        <React.Fragment>
            <TableRow
                sx={{ "& > *": { borderBottom: "unset" } }}
                className={`${classes.table_bg1}`}
                style={{
                    borderBottom: !open ? "20px solid  #f4f5f570" : "none",
                }}
                onClick={() => setOpen(!open)}
            >
                <TableCell style={{ minWidth: "0px", padding: 0, width: 10 }}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    <span>
                        {promoCode.referral_code}
                    </span>
                </TableCell>
                <TableCell>{promoCode.referral_name}</TableCell>
                <TableCell>{promoCode.discount}</TableCell>
                <TableCell>{moment(promoCode.start_date).format("DD MMM YYYY")}</TableCell>
                <TableCell>{moment(promoCode.end_date).format("DD MMM YYYY")}</TableCell>
                <TableCell style={{
                    minWidth: "205px",
                }}>{1}</TableCell>
                <TableCell>
                    <div className={classes.promoListStatusContainer}>
                        <Chip
                            label={promoCode.is_active ? "Active" : "Inactive"}
                            size="small"
                            style={{
                                backgroundColor: promoCode.is_active ? "#E9F7EC" : "#FCE3E5",
                                color: promoCode.is_active ? "#317D41" : "#BA1221",
                                padding: "10px",
                                height: "29px",
                            }}
                        />
                        <Button
                            variant="text"
                            size="small"
                            style={{ minWidth: 0 }}
                            onClick={(e) => handleMenuclick(e)}
                            className={classes.ListItemButton}
                        >
                            <BsThreeDotsVertical />
                        </Button>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            className={classes.menuListHolder}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                        >

                            {promoCode.is_active ? (
                                <MenuItem
                                    key={`deactivate${promoCode._id}`}
                                    className={classes.menuItems}
                                    onClick={(e) => handleActiveClick(e, promoCode._id, false)}
                                >
                                    <span>Deactivate</span>
                                </MenuItem>
                            ) : (
                                <MenuItem
                                    key={`active${promoCode._id}`}
                                    className={classes.menuItems}
                                    onClick={(e) => handleActiveClick(e, promoCode._id, true)}
                                >
                                    <span>Activate</span>
                                </MenuItem>
                            )}
                            <MenuItem
                                key={`edit${promoCode._id}`}
                                className={classes.menuItems}
                                onClick={(e) => handleEditClick(e, promoCode._id)}
                            >
                                <span>Edit</span>
                            </MenuItem>
                            <MenuItem
                                key={`copy${promoCode._id}`}
                                className={classes.menuItems}
                                onClick={(e) => handleCopyText(e, promoCode.referral_code)}
                            >
                                <span>Copy</span>
                            </MenuItem>
                        </Menu>
                    </div>
                </TableCell>
            </TableRow>
            <TableRow
                className={`${classes.table_bg1} ${classes.childRow} mb-3`}
                style={{
                    borderBottom: open ? "20px solid  #f4f5f570" : "none",
                    display: open ? "table-row" : "none",
                }}
            >
                <TableCell colSpan={8} className={classes.ColspanMainTd}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Table
                                size="small"
                                aria-label="purchases"
                                className={classes.SubTable}
                            >
                                <TableHead>
                                    <TableRow className={`${classes.TableSubRow}`}>
                                        <TableCell>Subscribing Date</TableCell>
                                        <TableCell>Invited Contact</TableCell>
                                        <TableCell>Company Name</TableCell>
                                        <TableCell>Mobile number</TableCell>
                                        <TableCell>Plan Subscribed</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {promoCode.children &&
                                        promoCode.children.length > 0 &&
                                        promoCode.children.map((child, index) => (
                                            <TableRow
                                                key={index}
                                                className={`${classes.TableSubRow}`}
                                            >
                                                <TableCell className="fw-medium">
                                                    <span>
                                                        {moment(
                                                            child?.latest_subscription?.createdAt
                                                        ).format("DD MMM YYYY")}
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span>{`${child.first_name} ${child.last_name}`}</span>
                                                </TableCell>
                                                <TableCell>
                                                    <span>{child?.company_details?.company_name}</span>
                                                    <br />{" "}
                                                    <span className={classes.someSmallText}>
                                                        ID: {child?.company_details?._id.slice(0, 6)}
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span>{child.mobile_number}</span>
                                                </TableCell>
                                                <TableCell>
                                                    <span>
                                                        {child && child.latest_subscription
                                                            ? child.subscription_details?.packageName
                                                            : "not subscribed"}
                                                        {/* {child?.latest_subscription?.subscription_id} */}
                                                    </span>
                                                    <br />
                                                    <span className={classes.someSmallText} style={{ textTransform: "capitalize" }}>
                                                        {`${child?.latest_subscription?.duration_details?.duration} Subscription`}
                                                    </span>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment >
    );
};

export default PromoCodeListItems;
