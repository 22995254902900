import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import classes from "../customer.module.css";
import FilterMenu from "../../../../Components/customerPageFilter";
import {
  OppositeSortArrawBtnIcons,
  SortArrawBtnIcons,
} from "../../../../Components/Icons/Icons";
import { Industries } from "../../../../Redux/constants/filterConstatns";
import {
  CustomerIndustyFilter,
  durationFilter,
  planNameFilter,
  teamNameFilter,
} from "../../../../Redux/actions/filterActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchSubscription } from "../../../../Redux/actions/customerActions";
import SubscriptionListItem from "./SubscriptionListItem";
import { handleSortClick } from "../../../../Utilities/commonfunctions";
import InvoiceFilter from "../../../../Components/InvoiceFilters";

const Subscription = (props) => {
  const dispatch = useDispatch();
  const { sortBy } = useSelector((state) => state.filter);
  const [teamsList, setTeamsList] = useState();
  const [durationList, setDurationList] = useState();
  const [planNamesList, setPlanNames] = useState();
  const { planNames, durations, teamNames, invoice } = useSelector(
    (state) => state.filter
  );

  useEffect(() => {
    const filters = {
      planNames,
      durations,
      teamNames,
      active: invoice,
    };
    dispatch(fetchSubscription(props.id, sortBy, filters));
  }, [sortBy, planNames, durations, teamNames, dispatch, props.id, invoice]);

  const subscriptionData = useSelector((state) => state.customerData);

  const { loading, error, subscriptions } = subscriptionData;
  useEffect(() => {
    if (subscriptions?.length > 0) {
      const array = subscriptions.map((subscription) => {
        return subscription?.team_info?.team_name;
      });
      const array1 = subscriptions.map((subscription) => {
        return subscription?.duration_details?.duration;
      });
      const array2 = subscriptions.map((subscription) => {
        return subscription?.subscription_info?.packageName;
      });
      const resultArray = array.filter((value, index, self) => {
        return (
          value !== undefined && value !== "" && self.indexOf(value) === index
        );
      });
      const resultArray1 = array1.filter((value, index, self) => {
        return (
          value !== undefined && value !== "" && self.indexOf(value) === index
        );
      });
      const resultArray2 = array2.filter((value, index, self) => {
        return (
          value !== undefined && value !== "" && self.indexOf(value) === index
        );
      });
      console.log(resultArray, resultArray1);
      setTeamsList(resultArray);
      setPlanNames(resultArray2);
      setDurationList(resultArray1);
    }
  }, [subscriptions]);
  return (
    <div className={classes.tableContainer}>
      <Table className={classes.MainTable}>
        <TableHead>
          <TableRow className={classes.tableHeadRow}>
            <TableCell />
            <TableCell className={classes.tableHeadCell}>
              <div className={classes.tableHEadercelldiv}>
                <span> Team </span>{" "}
                {teamsList && teamsList.length > 0 && (
                  <FilterMenu option={teamsList} name={teamNameFilter} />
                )}
                <span
                  onClick={() =>
                    handleSortClick(dispatch, sortBy, "team_info.team_name")
                  }
                >
                  {sortBy?.includes("desc") &&
                  sortBy?.includes("team_info.team_name") ? (
                    <OppositeSortArrawBtnIcons />
                  ) : (
                    <SortArrawBtnIcons />
                  )}
                </span>
              </div>
            </TableCell>
            <TableCell className={classes.tableHeadCell}>
              <div className={classes.tableHEadercelldiv}>
                <span> Plan Name </span>{" "}
                {planNamesList && planNamesList.length > 0 && (
                  <FilterMenu option={planNamesList} name={planNameFilter} />
                )}
                <span
                  onClick={() =>
                    handleSortClick(
                      dispatch,
                      sortBy,
                      "subscription_info.packageName"
                    )
                  }
                >
                  {sortBy?.includes("desc") &&
                  sortBy?.includes("subscription_info.packageName") ? (
                    <OppositeSortArrawBtnIcons />
                  ) : (
                    <SortArrawBtnIcons />
                  )}
                </span>
              </div>
            </TableCell>
            <TableCell className={classes.tableHeadCell}>
              <div className={classes.tableHEadercelldiv}>
                <span> Duration </span>{" "}
                {durationList && durationList.length > 0 && (
                  <FilterMenu option={durationList} name={durationFilter} />
                )}
                <span
                  onClick={() =>
                    handleSortClick(
                      dispatch,
                      sortBy,
                      "duration_details.duration"
                    )
                  }
                >
                  {sortBy?.includes("desc") &&
                  sortBy?.includes("duration_details.duration") ? (
                    <OppositeSortArrawBtnIcons />
                  ) : (
                    <SortArrawBtnIcons />
                  )}
                </span>
              </div>
            </TableCell>
            <TableCell
              className={classes.tableHeadCell}
              style={{ minWidth: 150 }}
            >
              Price
            </TableCell>
            <TableCell
              className={classes.tableHeadCell}
              style={{ minWidth: 200 }}
            >
              Plan Validity
            </TableCell>
            <TableCell className={classes.tableHeadCell}>
              <div className={classes.tableHEadercelldiv}>
                <span> Status </span> <InvoiceFilter />{" "}
                {/* <span
                  onClick={() => handleSortClick(dispatch, sortBy, "status")}
                >
                  {sortBy?.includes("desc") && sortBy?.includes("status") ? (
                    <OppositeSortArrawBtnIcons />
                  ) : (
                    <SortArrawBtnIcons />
                  )}
                </span> */}
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
      <Table>
        <TableBody>
          {loading ? (
            <TableRow>
              <td colSpan="9" className="text-center">
                Loading...
              </td>
            </TableRow>
          ) : error ? (
            <TableRow>
              <td colSpan="9" className="text-center">
                {error}
              </td>
            </TableRow>
          ) : subscriptions && subscriptions.length > 0 ? (
            subscriptions.map((item, index) => (
              <SubscriptionListItem key={index} subscription={item} />
            ))
          ) : (
            <TableRow>
              <td colSpan="9" className="text-center">
                No Data Found
              </td>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default Subscription;
