import { authApi, getAuthorizationHeader } from "../../api/api";
import { NOTIFICATION_LIST_FAIL, NOTIFICATION_LIST_REQUEST, NOTIFICATION_LIST_SUCCESS } from "../constants/notificationConstants";

export const GetNotificationList = (id) => async (dispatch) => {
    try {
        dispatch({
            type: NOTIFICATION_LIST_REQUEST,
        });

        const { data } = await authApi.get(`/notifications/user/${id}`, {
            headers: { Authorization: getAuthorizationHeader() },
        });
        console.log("Notification List ====>", data);
        dispatch({
            type: NOTIFICATION_LIST_SUCCESS,
            payload: data,
        });
    }
    catch (error) {
        dispatch({
            type: NOTIFICATION_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const handleMarkAsRead = (id) => async (dispatch) => {
    try {
        dispatch({
            type: NOTIFICATION_LIST_REQUEST,
        });

        const { data } = authApi.put(
            `notifications/${id}`,
            {},
            {
                headers: { Authorization: getAuthorizationHeader() },
            }
        );
        console.log(data);
        // const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        // GetNotificationList(userInfo._id);
    }
    catch (error) {
        dispatch({
            type: NOTIFICATION_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}

export const DeleteNotification = (id) => async (dispatch) => {
    try {
        dispatch({
            type: NOTIFICATION_LIST_REQUEST,
        });
        const { data } = await authApi.delete(`/notifications/${id}`, {
            headers: { Authorization: getAuthorizationHeader() },
        });
        console.log(data);
    }
    catch (error) {
        dispatch({
            type: NOTIFICATION_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}