import React, { useState, useEffect } from "react";
import classes from "./auditTrial.module.css";
import {
    TextField,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Dialog,
} from "@mui/material";
import {
    FilterFunnelIcon,
    OppositeSortArrawBtnIcons,
    SearchIcon,
    SortArrawBtnIcons,
} from "../../../Components/Icons/Icons";
import { DateRangePicker } from 'react-date-range';
import FilterMenu from "../../../Components/customerPageFilter";
import { CompanyNameFilter, ContactPersonFilter, CustomerIndustyFilter } from "../../../Redux/actions/filterActions";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../Components/Pagination";
import AuditTrialListItem from "./auditTrialListItem";
import { fetchAuditTrialList } from "../../../Redux/actions/auditTrialActions";
import { handleSortClick } from "../../../Utilities/commonfunctions";
import { addDays } from 'date-fns';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';

const AuditTrial = () => {
    const [search, setSearch] = useState("");
    const dispatch = useDispatch();
    const PaginationData = useSelector(
        (state) => state.paginationData.Pagination
    );
    const { rowCount, currentPage } = PaginationData || {};
    const auditTrialList = useSelector((state) => state.auditTrialDatas);
    const { loading, error, audit_trial, totalCount } = auditTrialList || {};
    const { sortBy, companiesList, contactPersons, industries } = useSelector((state) => state.filter);
    const [companyList, setCompanyList] = useState([]);
    const [userNames, setUserNames] = useState([]);
    const [showCalendar, setShowCalendar] = React.useState(false);
    const [state, setState] = useState([
        {
            startDate: addDays(new Date(), -7),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    const toggleCalendar = () => {
        setShowCalendar(!showCalendar);
    };

    useEffect(() => {
        const filterArray = {
            customers: contactPersons,
            companies: companiesList,
            channel: industries,
        }
        dispatch(fetchAuditTrialList(currentPage, rowCount, search, filterArray, sortBy, state));
    }, [
        dispatch,
        currentPage,
        rowCount,
        search,
        sortBy,
        contactPersons,
        companiesList,
        state,
        industries
    ]);

    useEffect(() => {
        if (audit_trial?.length > 0) {
            const companyList = audit_trial.map((item) => {
                return item?.companyInfo?.company_name
            })
            const userNames = audit_trial.map((item) => {
                return item?.userInfo?.name
            })
            const uniqueCompanyList = companyList.filter((value, index, self) => {
                return value !== undefined && value !== "" && self.indexOf(value) === index;
            });
            const uniqueUserNames = userNames.filter((value, index, self) => {
                return value !== undefined && value !== "" && self.indexOf(value) === index;
            })
            console.log(uniqueCompanyList, uniqueUserNames);
            setCompanyList(uniqueCompanyList);
            setUserNames(uniqueUserNames);
        }
    }, [audit_trial])

    const handleSetTimeRange = (item) => {
        console.log(item.selection);
        setState([item.selection])
        // dispatch(saveLoctionRangeFilter([item.selection]));
    }

    return (
        <div>
            <div className={classes.HeaderSearchBar}>
                <TextField
                    sx={{
                        "& legend": {
                            display: "none",
                        },
                        "& fieldset": {
                            top: 0,
                        },
                    }}
                    placeholder="Search"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    className={classes.teamMemberSearch}
                    id="firstNameinput"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    size="small"
                />
            </div>
            <div className={classes.TableContainer}>
                <Table className={classes.MainTable}>
                    <TableHead>
                        <TableRow className={classes.tableHeadRow}>
                            <TableCell className={classes.tableHeadCell}>
                                <div className={classes.tableHEadercelldiv}>
                                    <span> Date & Time </span>{" "}
                                    <span style={{ marginLeft: "5px" }} onClick={toggleCalendar}>
                                        <FilterFunnelIcon />
                                    </span>
                                    <Dialog open={showCalendar} onClose={toggleCalendar} className={classes.CalendarRangeContainer}>
                                        <DateRangePicker
                                            onChange={item => handleSetTimeRange(item)}
                                            showSelectionPreview={true}
                                            moveRangeOnFirstSelection={false}
                                            months={2}
                                            ranges={state}
                                            direction="horizontal"
                                        />
                                    </Dialog>
                                    <span onClick={() => handleSortClick(dispatch, sortBy, "createdAt")}>
                                        {sortBy?.includes("desc") && sortBy?.includes("createdAt") ? (
                                            <OppositeSortArrawBtnIcons />
                                        ) : (
                                            <SortArrawBtnIcons />
                                        )}
                                    </span>
                                </div>
                            </TableCell>
                            <TableCell className={classes.tableHeadCell}>
                                <div className={classes.tableHEadercelldiv}>
                                    <span> Customer </span>{" "}
                                    {companyList && companyList.length > 0 && (
                                        <FilterMenu
                                            option={companyList}
                                            name={CompanyNameFilter}
                                        />
                                    )}
                                    <span onClick={() => handleSortClick(dispatch, sortBy, "companyInfo.company_name")}>
                                        {sortBy?.includes("desc") && sortBy?.includes("companyInfo.company_name") ? (
                                            <OppositeSortArrawBtnIcons />
                                        ) : (
                                            <SortArrawBtnIcons />
                                        )}
                                    </span>
                                </div>
                            </TableCell>
                            <TableCell className={classes.tableHeadCell}>
                                <div className={classes.tableHEadercelldiv}>
                                    <span> Employee </span>{" "}
                                    {userNames && userNames.length > 0 && (
                                        <FilterMenu
                                            option={userNames}
                                            name={ContactPersonFilter}
                                        />
                                    )}
                                    <span onClick={() => handleSortClick(dispatch, sortBy, "userInfo.name")}>
                                        {sortBy?.includes("desc") && sortBy?.includes("userInfo.name") ? (
                                            <OppositeSortArrawBtnIcons />
                                        ) : (
                                            <SortArrawBtnIcons />
                                        )}
                                    </span>
                                </div>
                            </TableCell>
                            <TableCell className={classes.tableHeadCell} style={{ minWidth: 235 }}>
                                <span>Action</span>
                                <span onClick={() => handleSortClick(dispatch, sortBy, "action")}>
                                    {sortBy?.includes("desc") && sortBy?.includes("action") ? (
                                        <OppositeSortArrawBtnIcons />
                                    ) : (
                                        <SortArrawBtnIcons />
                                    )}
                                </span>
                            </TableCell>
                            <TableCell className={classes.tableHeadCell}>
                                <div className={classes.tableHEadercelldiv}>
                                    <span> Channel </span>{" "}
                                    <FilterMenu
                                        option={["Mobile", "Web"]}
                                        name={CustomerIndustyFilter}
                                    />{" "}
                                    <span onClick={() => handleSortClick(dispatch, sortBy, "channel")}>
                                        {sortBy?.includes("desc") && sortBy?.includes("channel") ? (
                                            <OppositeSortArrawBtnIcons />
                                        ) : (
                                            <SortArrawBtnIcons />
                                        )}
                                    </span>
                                </div>
                            </TableCell>
                            <TableCell className={classes.tableHeadCell}>
                                <div className={classes.tableHEadercelldiv}>
                                    <span> IP Address </span>{" "}
                                    {/* <FilterMenu
                                        option={Industries}
                                        name={CustomerIndustyFilter}
                                    />{" "} */}
                                    <span onClick={() => handleSortClick(dispatch, sortBy, "ip_address")}>
                                        {sortBy?.includes("desc") && sortBy?.includes("ip_address") ? (
                                            <OppositeSortArrawBtnIcons />
                                        ) : (
                                            <SortArrawBtnIcons />
                                        )}
                                    </span>
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <td colSpan="9" className="text-center">
                                    Loading...
                                </td>
                            </TableRow>
                        ) : error ? (
                            <TableRow>
                                <td colSpan="9" className="text-center">
                                    {error}
                                </td>
                            </TableRow>
                        ) : audit_trial && audit_trial.length > 0 ? (
                            audit_trial.map((item, index) => (
                                <AuditTrialListItem key={index} item={item} />
                            ))
                        ) : (
                            <TableRow>
                                <td colSpan="9" className="text-center">
                                    No Data Found
                                </td>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
            <Pagination total={totalCount} />
        </div>
    );
};

export default AuditTrial;
