import React from "react";
import classes from "./invoice.module.css";
import {
  Button,
  Table,
  Chip,
  Box,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Menu,
  MenuItem,
  IconButton,
  Collapse,
} from "@mui/material";
import moment from "moment";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  getOneInvoice,
  sendInvoiceToUser,
} from "../../../Redux/actions/customerActions";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import InvoiceSendModel from "../Customer/InvoiceSendModel";

const InvoiceListItems = ({ invoice }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState();
  const [modalToggle, setModalToggle] = useState(false);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState();

  const handleMenuclick = (event, item) => {
    setSelected(item);
    setOpenMenu(event.currentTarget);
  };
  const handleClose = () => {
    setOpenMenu(null);
  };

  const handleMenuItemClick = async (e, name) => {
    if (name === "view") {
      dispatch(getOneInvoice(selected.invoice_number));
      navigate(`/invoices/${selected.invoice_number}`);
    }
    if (name === "download") {
      console.log(selected);
      //handleGeneratePDF(selected);
      const url = `${process.env.REACT_APP_API_URL}/invoices/downloadInvoice/${selected.order_id}/${selected.customer_details?.user_id}`;
      window.open(url, "_blank", "noopener,noreferrer");
    }
    if (name === "send") {
      e.stopPropagation();
      dispatch(sendInvoiceToUser(selected._id, setModalToggle));
      console.log("send");
    }
    setOpenMenu(null);
  };

  const CloseModal = () => {
    setModalToggle(false);
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        className={`${classes.table_bg1}`}
        style={{
          borderBottom: !open ? "20px solid  #f4f5f570" : "none",
        }}
        onClick={() => setOpen(!open)}
      >
        <TableCell style={{ minWidth: "0px", padding: 0, width: 10 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <span>{invoice.company_details?.company_name}</span>
          <br />
          <span className={classes.someSmallText}>
            ID:{invoice.company_details?._id.slice(0, 6)}
          </span>
        </TableCell>
        <TableCell>{`${invoice.customer_details?.first_name} ${invoice.customer_details?.last_name}`}</TableCell>
        <TableCell>{invoice.customer_details?.my_referral_code}</TableCell>
        <TableCell>{invoice.customer_details?.mobile_number}</TableCell>
        <TableCell>{invoice.invoice_items?.length || 0}</TableCell>
        <TableCell>
          <Chip
            label={invoice.is_active ? "Active" : "Inactive"}
            size="small"
            style={{
              backgroundColor: invoice.is_active ? "#E9F7EC" : "#FCE3E5",
              color: invoice.is_active ? "#317D41" : "#BA1221",
              padding: "10px",
            }}
          />
        </TableCell>
      </TableRow>
      <TableRow
        className={`${classes.table_bg1} ${classes.childRow} mb-3`}
        style={{
          borderBottom: open ? "20px solid  #f4f5f570" : "none",
          display: open ? "table-row" : "none",
        }}
      >
        <TableCell colSpan={7} className={classes.ColspanMainTd}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table
                size="small"
                aria-label="purchases"
                className={classes.SubTable}
              >
                <TableHead>
                  <TableRow className={`${classes.TableSubRow}`}>
                    <TableCell>Company Name</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoice.invoice_items &&
                    invoice.invoice_items.length > 0 &&
                    invoice.invoice_items.map((item, index) => (
                      <TableRow
                        key={index}
                        className={`${classes.TableSubRow}`}
                      >
                        <TableCell>
                          <span>{item.item_name}</span>
                          <br />
                          <span
                            className={classes.someSmallText}
                            style={{ textTransform: "capitalize" }}
                          >
                            {`${item.duration_details.duration} Subscription`}
                          </span>
                        </TableCell>
                        <TableCell>
                          <span>
                            {moment(
                              invoice.customer_subscription_details?.start_date
                            ).format("DD MMM YYYY")}
                          </span>
                        </TableCell>
                        <TableCell>
                          <span>
                            {moment(
                              invoice.customer_subscription_details?.end_date
                            ).format("DD MMM YYYY")}
                          </span>
                        </TableCell>
                        <TableCell>
                          <Chip
                            label={"Active"}
                            size="small"
                            style={{
                              backgroundColor: "#E9F7EC",
                              color: "#317D41",
                              padding: "10px",
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="text"
                            size="small"
                            style={{ minWidth: 0 }}
                            onClick={(e) => handleMenuclick(e, invoice)}
                          >
                            <BsThreeDotsVertical />
                          </Button>
                          <Menu
                            anchorEl={openMenu}
                            open={Boolean(openMenu)}
                            onClose={handleClose}
                            className={classes.InvoiceMenuOptions}
                          >
                            <MenuItem
                              key={`view${item._id}`}
                              onClick={(e) => handleMenuItemClick(e, "view")}
                            >
                              View Invoice
                            </MenuItem>
                            <MenuItem
                              key={`download${item._id}`}
                              onClick={(e) =>
                                handleMenuItemClick(e, "download")
                              }
                            >
                              Download
                            </MenuItem>
                            <MenuItem
                              key={`send${item._id}`}
                              onClick={(e) => handleMenuItemClick(e, "send")}
                            >
                              Send Invoice
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {modalToggle && (
        <InvoiceSendModel
          item={invoice}
          modalClose={CloseModal}
          value={modalToggle}
        />
      )}
    </React.Fragment>
  );
};

export default InvoiceListItems;
