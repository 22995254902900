import React from "react";
import classes from "./communication.module.css";
import {
  TableCell,
  TableRow,
  Typography,
  Chip,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import { BsThreeDotsVertical } from "react-icons/bs";
import moment from "moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { DeleteselectedCommunication, ResendNotification } from "../../../Redux/actions/communicationActions";
import { useNavigate } from "react-router-dom";

function CommunicationListItem(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getNames = (item) => {
    if (item?.members?.companies?.length > 0 && item.Companies && item.Companies.length > 0) {
      return item.Companies[0].company_name;
    } else {
      return `${item.members.groups[0]?.replaceAll("_", " ")}s`;
    }
  };

  const getCount = (item) => {
    let count = 0;
    if (item?.members?.companies?.length > 0) {
      count += item.members.companies.length;
    }
    if (item.members?.groups?.length > 0) {
      count += item.members.groups.length;
    }
    if (count - 1 > 0) {
      return `+${count - 1} more`;
    } else {
      return "";
    }
  };

  const handleMenuclick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleResentClick = (id) => {
    dispatch(ResendNotification(id));
    handleClose();
  }

  const handleDelete = (id) => {
    dispatch(DeleteselectedCommunication(id));
    handleClose();
  };

  return (
    <TableRow className={`${classes.table_bg1}`}>
      <TableCell>
        <Typography
          href="#javascript: void(0);"
          className={classes.TableCellMainText}
        >
          <span style={{ textTransform: "capitalize" }}>
            {getNames(props.item)}
          </span>
          {props.item?.members?.groups?.length >= 1 ||
            props.item?.members?.companies?.length >= 1 ? (
            <span className={classes.moreMemberText}>{`\t${getCount(
              props.item
            )}`}</span>
          ) : null}
        </Typography>
      </TableCell>
      <TableCell>
        <div>
          <Typography
            href="#javascript: void(0);"
            className={classes.TableCellMainText}
          >
            {moment(props.item.createdAt).format("DD MMM YYYY")}
          </Typography>
        </div>
      </TableCell>
      <TableCell>
        <Chip
          label={props.item.delivered === true ? "Delivered" : "Not Delivered"}
          size="small"
          style={{
            backgroundColor:
              props.item.delivered === true ? "#E9F7EC" : "#FCE3E5",
            color: props.item.delivered === true ? "#317D41" : "#BA1221",
            padding: "10px",
            height: "30px",
          }}
        />
      </TableCell>
      <TableCell>
        <Button
          variant="text"
          size="small"
          style={{ minWidth: 0 }}
          onClick={(e) => handleMenuclick(e)}
        >
          <BsThreeDotsVertical />
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className={classes.menuListHolder}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            key={`view${props.item._id}`}
            className={classes.menuItems}
            onClick={() => navigate(`/communication/${props.item._id}`)}
          >
            <span>View</span>
          </MenuItem>
          <MenuItem
            key={`edit${props.item._id}`}
            className={classes.menuItems}
            onClick={() => handleResentClick(props.item._id)}
          >
            <span>Resend</span>
          </MenuItem>
          <MenuItem
            key={`delete${props.item._id}`}
            className={classes.menuItems}
            onClick={() => handleDelete(props.item._id)}
          >
            <span>Delete</span>
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
}

export default CommunicationListItem;
