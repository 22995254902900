import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./customer.module.css";
import { Paper, Avatar, Typography, Tooltip } from "@mui/material";
import EditCustomerModal from "./EditCustomerModal";
import { fetchCustomer } from "../../../Redux/actions/customerActions";
import {
  CusCallIcon,
  CusEditPenIcon,
  CusMailIcon,
} from "../../../Components/Icons/Icons";
import { generateColor, stringAvatar } from "../../../Utilities/commonfunctions";

const CustomerCard = (props) => {
  const customerData = useSelector((state) => state.customerData.customer);
  const { email, mobile_number, designation, first_name, last_name, user_id } =
    customerData || {};
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);

  const closeHandler = (props) => {
    getCustomer();
    setShowPopup(false);
  };

  const getCustomer = () => {
    const path = window.location.pathname;
    const arr = path.split("/");
    console.log(arr);
    dispatch(fetchCustomer(arr[arr.length - 1]));
  };

  useEffect(() => {
    const path = window.location.pathname;
    const arr = path.split("/");
    console.log(arr);
    dispatch(fetchCustomer(arr[arr.length - 1]));
  }, [dispatch]);
  return (
    <div>
      <Paper style={{ padding: "20px", background: "#F7F9FC", height: 162 }}>
        <div className={classes.customerCardMainDiv}>
          {user_id?.profile_pic && user_id?.profile_pic?.filename ?
            <Avatar
              alt="Remy Sharp"
              sx={{ width: 114, height: 114 }}
              src={`${process.env.REACT_APP_IMAGE_URL}/${user_id?.profile_pic?.destination}${user_id?.profile_pic?.filename}`}
            />
            :
            <Avatar
              {...stringAvatar(`${user_id?.name}`)}
              sx={{
                bgcolor: `${generateColor(`${user_id?.name}`)}`,
                width: 114,
              }}
              style={{ height: 114 }}
            />
          }
          {/* <Avatar
            src={ProfileImage}
            alt={"Profile Picture"}
            sx={{
              width: 114,
              height: 114,
            }}
          /> */}
          <div>
            <Typography
              className={`${classes.cardHeading} mb-2`}
            >{`${first_name} ${last_name}`}</Typography>
            <Typography className={`${classes.cardSubHeading}`}>
              <b>Designation:</b> {designation}
            </Typography>
            <div className={classes.displayFlex} style={{ columnGap: "20px" }}>
              <Typography className={classes.displayFlex}>
                <CusCallIcon /> <span>{mobile_number}</span>
              </Typography>{" "}
              |
              <Tooltip title={email} arrow >
                <Typography className={classes.displayFlex}>
                  <CusMailIcon /> <span className={classes.emailFeild} >{email}</span>
                </Typography>
              </Tooltip>


            </div>
          </div>
          <Typography
            className={classes.EditButton}
            onClick={() => setShowPopup(true)}
          >
            <CusEditPenIcon /> <span>Edit</span>
          </Typography>
        </div>
      </Paper>
      {showPopup ? (
        <EditCustomerModal
          handleClose={closeHandler}
          /* handleSubmit={SubmitHandler} */ values={customerData}
        />
      ) : null}
    </div>
  );
};

export default CustomerCard;
