import { BLOGS_CREATED_FAIL, BLOGS_CREATED_REQUEST, BLOGS_CREATED_SUCCESS, BLOGS_ONE_SUCCESS, BLOGS_TAGS_LIST_SUCCESS, LIST_BLOGS_FAIL, LIST_BLOGS_REQUEST, LIST_BLOGS_SUCCESS } from "../constants/blogsConstants";

const initialState = {
    loading: true,
    blogsList: [],
    oneBlog: null,
    tagsList: [],
    error: null,
    totalCount: 0,
};

export const BlogsReducers = (state = initialState, action) => {
    switch (action.type) {
        case LIST_BLOGS_REQUEST:
            return { ...state, loading: true };

        case LIST_BLOGS_SUCCESS:
            return {
                ...state,
                loading: false,
                blogsList: action.payload.blogs,
                totalCount: action.payload.totalCount,
                oneBlog: null,
                error: null,
            };
        case BLOGS_TAGS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                tagsList: action.payload,
                error: null,
            };
        case LIST_BLOGS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case BLOGS_ONE_SUCCESS:
            return {
                ...state,
                loading: false,
                oneBlog: action.payload,
                error: null,
            };
        case BLOGS_CREATED_REQUEST:
            return { ...state, loading: true };

        case BLOGS_CREATED_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case BLOGS_CREATED_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};