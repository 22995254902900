import React from "react";
import classes from "./userManagement.module.css";
import {
  TableCell,
  TableRow,
  Typography,
  Avatar,
  Chip,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import { BsThreeDotsVertical } from "react-icons/bs";
import moment from "moment";
import {
  generateColor,
  stringAvatar,
} from "../../../Utilities/commonfunctions";
import { useDispatch, useSelector } from "react-redux";
import {
  activeUser,
  deleteAdminUser,
} from "../../../Redux/actions/userActions";
import { useState } from "react";
import ConfirmationModal from "./confirmationModal";

function UsersListItem(props) {
  const dispatch = useDispatch();
  const { sortBy, invoice } = useSelector((state) => state.filter);
  const [modalToggle, setModalToggle] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const PaginationData = useSelector(
    (state) => state.paginationData.Pagination
  );
  const { rowCount, currentPage } = PaginationData || {};
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleMenuclick = (event, item) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStatusChange = () => {
    const filterArray = {
      active: invoice,
    };
    dispatch(
      activeUser(
        props.user._id,
        currentPage,
        rowCount,
        props.search,
        sortBy,
        filterArray
      )
    );
    handleClose();
  };

  const closeModal = () => {
    setModalToggle(false);
    setDeleteConfirmation(false);
  };

  const handleDelete = () => {
    // dispatch
    dispatch(deleteAdminUser(props.user._id, setDeleteSuccess));
  };

  return (
    <TableRow className={`${classes.table_bg1}`}>
      <TableCell>
        <div className={classes.displayFlex}>
          {props.user &&
          props.user.profile_pic &&
          props.user.profile_pic?.destination ? (
            <Avatar
              alt="Remy Sharp"
              src={`${process.env.REACT_APP_IMAGE_URL}/${props.user?.profile_pic?.destination}${props.user?.profile_pic?.filename}`}
            />
          ) : (
            <Avatar
              {...stringAvatar(`${props.user?.name}`)}
              sx={{
                bgcolor: `${generateColor(`${props.user?.name}`)}`,
              }}
            />
          )}
          {/* <Avatar
            src={avatar}
            alt=""
            className="avatar-xs rounded-circle me-2"
          /> */}
          <div>
            <Typography
              href="#javascript: void(0);"
              className="text-body align-middle fw-medium"
            >
              {props.user.name}
            </Typography>
            <span>Id:{props.user._id.slice(0, 5)}</span>
          </div>
        </div>
      </TableCell>
      <TableCell>{props.user.user_type}</TableCell>
      <TableCell>{props.user.phone}</TableCell>
      <TableCell style={{ minWidth: 235 }}>{props.user.email}</TableCell>
      <TableCell>
        {moment(props.user.joiningDate).format("DD MMM YYYY")}
      </TableCell>
      <TableCell>
        <Chip
          label={props.user.is_active ? "Active" : "Inactive"}
          size="small"
          style={{
            backgroundColor: props.user.is_active ? "#E9F7EC" : "#FBE9E9",
            color: props.user.is_active ? "#317D41" : "#D32F2F",
            padding: "10px",
          }}
        />
      </TableCell>
      <TableCell style={{ minWidth: 0 }}>
        <Button
          variant="text"
          size="small"
          style={{ minWidth: 0 }}
          onClick={(e) => handleMenuclick(e, props.user)}
        >
          <BsThreeDotsVertical />
        </Button>
        <Menu
          anchorEl={anchorEl}
          style={{}}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {props.user.is_active ? (
            <MenuItem
              key={`delete${props.user._id}`}
              onClick={() => handleStatusChange()}
            >
              <span>Deactive</span>
            </MenuItem>
          ) : (
            <MenuItem
              key={`edit${props.user._id}`}
              onClick={() => handleStatusChange()}
            >
              <span>Active</span>
            </MenuItem>
          )}
          <MenuItem
            key={`delete${props.user._id}`}
            onClick={() => setModalToggle(true)}
          >
            <span>Reset password</span>
          </MenuItem>
          <MenuItem
            key={`delete${props.user._id}`}
            onClick={() => setDeleteConfirmation(true)}
          >
            <span>Delete user</span>
          </MenuItem>
        </Menu>
      </TableCell>
      {modalToggle ? (
        <ConfirmationModal
          modalClose={closeModal}
          resetPassword={"resetPassword"}
        />
      ) : null}
      {deleteConfirmation && (
        <ConfirmationModal
          modalClose={closeModal}
          deleteUser={"delete"}
          callBack={handleDelete}
          name={props.user.name}
          deleteStatus={deleteSuccess}
        />
      )}
    </TableRow>
  );
}

export default UsersListItem;
