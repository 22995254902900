import React, { useEffect } from "react";
import avatar from "../../../Assets/images/avatar-9.jpg";
import pdfImage from "../../../Assets/images/pdf-file 1.png";
import excelImage from "../../../Assets/images/Excel File IC.png";
import {
  Paper,
  Avatar,
  Typography,
  Chip,
  TextField,
  Button,
} from "@mui/material";
import moment from "moment";
import classes from "./supportTicket.module.css";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CloseSupportTicketAPI, fetchSupportTickets } from "../../../Redux/actions/supportTicketActions";
import Pagination from "../../../Components/Pagination";
import { formatBytes, generateColor, stringAvatar } from "../../../Utilities/commonfunctions";
import { ImageUrl } from "../../../api/api-urls";
import axios from "axios";

const TeamLeaderPage = ({ user_type }) => {
  const [remarks, setRemarks] = useState("");
  const dispatch = useDispatch();
  const PaginationData = useSelector(
    (state) => state.paginationData.Pagination
  );
  const { rowCount, currentPage } = PaginationData || {};
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const supportTicketList = useSelector((state) => state.supportTicketData);
  const { loading, error, support_tickets, totalCount } = supportTicketList || {};
  const [selectedItem, setSelectedItem] = useState();


  const handleCloseTicket = () => {
    const userEntry = {
      closed_by: userInfo._id,
      remarks: remarks,
    }
    console.log(userEntry);
    try {
      dispatch(CloseSupportTicketAPI(selectedItem._id, userEntry))
      dispatch(fetchSupportTickets(currentPage, rowCount, user_type));
      setSelectedItem(null);
    }
    catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    dispatch(fetchSupportTickets(currentPage, rowCount, user_type));
  }, [
    dispatch,
    currentPage,
    rowCount,
    user_type
  ]);

  useEffect(() => {
    if (support_tickets && support_tickets.length > 0) {
      console.log(support_tickets);
    }
  }, [support_tickets]);

  const getImageURl = (name) => {
    const nameSplit = name.split(".")
    switch (nameSplit[nameSplit.length - 1]) {
      case "pdf":
        return pdfImage;
      case "excel":
        return excelImage;
      case "xlsm":
        return excelImage;
      default:
        return pdfImage;
    }
  }

  const handlePdfClick = async (item) => {
    console.log(item);
    console.log(item);
    const url = `${process.env.REACT_APP_IMAGE_URL}/${item.destination}/${item.filename}`
    axios(url, {
      method: "GET",
      responseType: "blob"
    })
      .then(response => {
        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], {
          type: "application/pdf"
        });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL, "_blank");
      })
      .catch(error => {
        console.log(error);
      });
    // console.log(data);
    // navigate("/pdf-viewer")
    // window.redirect(url, "_black")
  }

  return (
    <div>
      <Paper style={{ minHeight: "100vh" }}>
        {loading ? (
          <Typography>
            <td colSpan="9" className="text-center">
              Loading...
            </td>
          </Typography>
        ) : error ? (
          <Typography>
            {error}
          </Typography>
        ) : support_tickets && support_tickets.length > 0 ? (
          <div className={classes.TicketsPageMaindiv}>
            <div className={classes.ContactListPAge}>
              <div className={classes.ContaxtList}>
                {support_tickets.map((item, index) => {
                  return (
                    <div
                      onClick={() => setSelectedItem(item)}
                      className={classes.ContactListPAgeSubDiv}
                      style={{
                        borderBottom:
                          index === support_tickets.length - 1
                            ? "none"
                            : "1px solid #E5E5E5",
                      }}
                      key={index}
                    >
                      <div className={classes.displayFlex}>
                        {item?.userInfo?.profile_pic?.filename ?
                          <Avatar
                            alt="Remy Sharp"
                            sx={{ width: 36 }}
                            src={`${process.env.REACT_APP_IMAGE_URL}/${item?.userInfo?.profile_pic?.destination}${item?.userInfo?.profile_pic?.filename}`}
                          />
                          :
                          <Avatar
                            {...stringAvatar(`${item?.company_name.trim()}`)}
                            sx={{
                              bgcolor: `${generateColor(`${item?.company_name.trim()}`)}`,
                            }}
                            style={{ height: 36, width: 36, }}
                          />
                        }
                        <div>
                          <Typography
                            href="#javascript: void(0);"
                            className={classes.TableCellMainText1}
                          >
                            {item.company_name}
                          </Typography>
                          <span className={classes.SomeOtheText1}>
                            {item.subject}
                          </span>
                        </div>
                      </div>
                      <div className={classes.StatusChip}>
                        <Chip
                          label={item.status}
                          size="small"
                          style={{
                            backgroundColor:
                              item.status === "pending" ? "#FFF6D4" : "#E9F7EC",
                            color:
                              item.status === "pending" ? "#806504" : "#317D41",
                            padding: "10px",
                            textTransform: "capitalize",
                          }}
                        />
                      </div>
                      {item.remarks && (
                        <div
                          className={classes.displayFlex}
                          style={{
                            justifyContent: "space-between",
                            marginTop: "10px",
                          }}
                        >
                          <Typography className={classes.SomeOtherTextclosedBy}>
                            <span>Closed By:</span>
                            {item.closed_userInfo.name}
                          </Typography>
                          <Typography className={classes.SomeOtherTextclosedBy}>
                            <span>Closed on:</span>
                            {moment(item.closed_on).format("DD MMM YYYY")}
                          </Typography>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className={classes.PaginationDiv}>
                <Pagination total={totalCount} from={"supportTicket"} />
              </div>
            </div>
            <div className={classes.MessagePAge}>
              {selectedItem && (
                <div className={classes.MessagePageSubDiv}>
                  <div>
                    <div className={classes.MessagePageHeader}>
                      <Typography className={classes.headerText}>
                        {selectedItem.subject}
                      </Typography>
                      <Typography className={classes.SomeOtherTextclosedBy}>
                        {moment(selectedItem.createdAt).format("DD MMM, YYYY, hh:mm")}
                      </Typography>
                    </div>
                    <div className={classes.MessagePageHeaderContainer}>
                      <Typography className={classes.MeaggseSubHeaderHeading}>
                        From:
                        <span className={classes.messagefrom}>{selectedItem.userInfo.name}</span>
                      </Typography>
                      <Typography className={classes.MeaggseSubHeaderHeading}>
                        Mobile No.:
                        <span className={classes.messagefrom1}>{selectedItem.phone}</span>
                      </Typography>
                      <Typography className={classes.MeaggseSubHeaderHeading}>
                        Email ID:
                        <span className={classes.messagefrom1}>{selectedItem.email}</span>
                      </Typography>
                    </div>
                    <div style={{ padding: "15px" }}>
                      <Typography
                        className={`${classes.MeaggseSubHeaderHeading} ${classes.messagefrom2}`}
                      >
                        {selectedItem.details}
                      </Typography>
                      {selectedItem.documents.length > 0 && (
                        selectedItem.documents.map((item, index) => {
                          return (
                            <div
                              key={index} className={`mt-3 ${classes.displayFlexContainer} ${classes.imageContainer}`}
                              onClick={() => handlePdfClick(item)}
                            >
                              <div className={classes.displayFlexContainer}>
                                <img src={getImageURl(item.originalname)} alt="" />
                                <Typography
                                  className={classes.SomeOtherTextclosedBy}
                                  style={{
                                    color: "#0A1F41",
                                  }}
                                >
                                  {item.originalname}
                                </Typography>
                              </div>
                              <Typography className={classes.SomeOtheText}>{formatBytes(item.size)}</Typography>
                            </div>
                          );
                        })
                      )}
                      {selectedItem.remarks && (
                        <div>
                          <Typography className={`${classes.SomeOtherTextclosedBy} mt-3 mb-2`}>Remark</Typography>
                          <Typography className={classes.SomeOtheText1} style={{ color: "#0A1F41" }}>{selectedItem.remarks}</Typography>
                          <div
                            className={classes.displayFlex}
                            style={{
                              justifyContent: "space-between",
                              marginTop: "20px",
                              width: "60%",
                            }}
                          >
                            <Typography className={classes.SomeOtherTextclosedBy}>
                              <span>Closed By:</span>
                              {selectedItem.closed_userInfo.name}
                            </Typography>
                            <Typography className={classes.SomeOtherTextclosedBy}>
                              <span>Closed on:</span>
                              {moment(selectedItem.closed_on).format("DD MMM YYYY")}
                            </Typography>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {!selectedItem.remarks && (
                    <div style={{ padding: "15px" }}>
                      <div>
                        <Typography className={classes.formLable}>Remark</Typography>
                        <TextField
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                            marginBottom: "15px",
                          }}
                          id="emailidInput"
                          fullWidth
                          multiline
                          rows={4}
                          value={remarks}
                          onChange={(e) => {
                            setRemarks(e.target.value);
                          }}
                          size="small"
                        />
                      </div>
                      <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={remarks.length === 0 ? true : false}
                          className={classes.AddNotificationMainBtn}
                          onClick={() => handleCloseTicket()}
                        >
                          Close Ticket
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        ) : (
          <Typography>
            No Data Found
          </Typography>
        )
        }
      </Paper >
    </div >
  );
};

export default TeamLeaderPage;
