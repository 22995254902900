import React, { useState, useEffect } from "react";
import { Modal, Button, Typography } from "@mui/material";
import { MdClose } from "react-icons/md";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import image from "../../Assets/images/forgotPass.png";
import Box from "@mui/material/Box";
import OtpInput from "react-otp-input";
import { api, authApi } from "../../api/api";
import {
  GENERATE_FORGET_PASSWORD_OTP,
  RESET_PASSWORD,
  VERIFY_LOGIN_OTP,
} from "../../api/api-urls";
import classes from "./auth.module.css";
import {
  CloseEyeIcon,
  EditPenIcon,
  OpenEyeIcon,
} from "../../Components/Icons/Icons";

const ForgetPassword = (props) => {
  const [modalToggle, setModalToggle] = useState(true);

  const [time, setTime] = useState(15);
  const [disable, setDisable] = useState({
    name: `Resend in ${time}s`,
    state: true,
  });
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword1, setShowPassword1] = React.useState(false);
  const [otpReceived, setOtpReceived] = useState();
  const [successModal, setSuccessModal] = useState();
  const [setpassword, setSetPassword] = useState();
  const [Otp, setOtp] = useState();
  const otp = JSON.parse(sessionStorage.getItem("otp"));
  const [email, setEmail] = useState();
  const rootRef = React.useRef(null);

  function closeModal(data) {
    sessionStorage.removeItem("otp");
    setSuccessModal(false);
    props.modalClose(false);
    setModalToggle(false);
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevCount) => prevCount - 1);
      setDisable({
        name: `Resend in ${time - 1}s`,
        state: true,
      });
    }, 1000);

    if (time === 0) {
      clearInterval(timer);
      setDisable({
        name: `Resend OTP`,
        state: false,
      });
    }

    return () => {
      clearInterval(timer);
    };
  }, [time]);

  const handleOnchange = (e) => {
    const re = /^\d*$/;
    if (e === "" || re.test(e)) {
      setOtp(e);
    }
  };

  const handleResetClick = () => {
    setTime(15);
    handleOtp();
  };

  const handleOtp = async () => {
    const userEntry = {
      email: email,
    };
    const { data } = await authApi.post(
      GENERATE_FORGET_PASSWORD_OTP,
      userEntry
    );
    console.log(data);
    sessionStorage.setItem("otp", JSON.stringify(data));
    setOtpReceived(true);
  };

  const IsEmailCheck = (event) => {
    var x = event.charCode || event.keyCode;
    if ((x > 96 && x < 123) || x === 46 || x === 64 || (x > 47 && x < 58)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  };
  const handleVerify = async () => {
    const userEntry = {
      otp: Otp,
    };
    const { data } = await api.post(VERIFY_LOGIN_OTP, userEntry);
    console.log(data);
    setOtpReceived(false);
    setSetPassword(true);
  };
  const handleResetPassword = async () => {
    const userEntry = {
      password: password,
      confirmPassword: confirmPassword,
    };
    const { data } = await api.post(RESET_PASSWORD, userEntry);
    console.log(data);
    setSetPassword(false);
    setSuccessModal(true);
  };

  const editEmail = () => {
    console.log("setting to back screen");
    setOtpReceived(false);
  };

  return (
    <div>
      <Modal
        open={modalToggle}
        onClose={() => closeModal()}
        aria-labelledby="server-modal-title"
        data-testid="register_modal"
        aria-describedby="server-modal-description"
        sx={{
          display: "flex",
          top: "10%",
          height: "fit-content",
          marginTop: 10,
          justifyContent: "center",
        }}
        container={() => rootRef.current}
      >
        <Box
          sx={{
            position: "relative",
            bgcolor: "background.paper",
            borderRadius: 1,
            boxShadow: (theme) => theme.shadows[5],
          }}
          style={{ minWidth: "450px", height: "auto" }}
          className={"loginModalWidth"}
        >
          <div className={classes.forgetPasswordmodalclose}>
            <Button
              variant="text"
              sx={{ minWidth: "unset" }}
              className={"closebtn"}
              onClick={() => closeModal()}
            >
              <MdClose />
            </Button>
          </div>
          {!otpReceived && !setpassword && !successModal ? (
            <div>
              <div className={classes.ModalHeadingSection}>
                <Typography className={classes.modalHeadind}>
                  Forgot Password
                </Typography>
                <Typography className={classes.modalHeadin2}>
                  Please enter your registered email ID
                </Typography>
                <ValidatorForm
                  useref="form"
                  onSubmit={handleOtp}
                  style={{ width: "100%" }}
                  className={"InputForm"}
                >
                  <div className={classes.depFormContainer}>
                    <div>
                      <div className="mb-4">
                        <Typography
                          htmlFor="emailidInput"
                          className={classes.form_lable}
                        >
                          Email ID
                        </Typography>
                        <TextValidator
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                          }}
                          id="emailidInput"
                          fullWidth={true}
                          onKeyPress={(e) => IsEmailCheck(e)}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          size="small"
                          validators={["isEmail", "required"]}
                          errorMessages={[
                            "email is not valid",
                            "Email is Required",
                          ]}
                        />
                      </div>
                    </div>
                    <div className={classes.addMemberModalbtnSection}>
                      <Button
                        variant="contained"
                        type="submit"
                        fullWidth={true}
                        style={{ width: "100%", textTransform: "none" }}
                      >
                        Send OTP
                      </Button>
                    </div>
                  </div>
                </ValidatorForm>
              </div>
            </div>
          ) : null}
          {otpReceived && !setpassword && !successModal ? (
            <div style={{ width: "520px" }}>
              <div className={classes.ModalHeadingSection}>
                <Typography className={classes.modalHeadind}>
                  OTP sent to Registered Email ID
                </Typography>
                <Typography className={classes.modalHeadin2}>
                  Enter the 4-digit code we sent to <span>{email}</span>{" "}
                  <span
                    onClick={() => editEmail()}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <EditPenIcon />
                  </span>
                </Typography>
                {otp ? <p>OTP : {otp.email_otp}</p> : null}
                <ValidatorForm
                  useref="form"
                  onSubmit={handleVerify}
                  style={{ textAlign: "center" }}
                >
                  <div className="mt-3 mb-3">
                    <div className={`otp ${classes.OtpFeildsContainer}`}>
                      <OtpInput
                        value={Otp}
                        onChange={(e) => handleOnchange(e)}
                        numInputs={4}
                        renderInput={(props) => <input {...props} />}
                        isInputNum={true}
                      />
                    </div>
                    <div className="mt-3">
                      <p className="mb-0 login-text3">
                        Didn’t receive OTP?
                        <Button
                          disabled={disable.state}
                          className="fw-medium"
                          variant="text"
                          sx={{
                            "&.Mui-disabled": {
                              color: "#0000ff73 !important",
                            },
                          }}
                          style={{
                            color: "blue",
                            cursor: "pointer",
                            textTransform: "none",
                            border: "none",
                          }}
                          onClick={() => handleResetClick()}
                        >
                          {disable.name}
                        </Button>
                      </p>
                    </div>
                    <div className="mt-3">
                      <Button
                        variant="contained"
                        type="submit"
                        fullwidth
                        style={{ width: "100%", textTransform: "none" }}
                        className={classes.commonbtn}
                      >
                        Verify
                      </Button>
                    </div>
                  </div>
                </ValidatorForm>
              </div>
            </div>
          ) : null}
          {!otpReceived && setpassword && !successModal ? (
            <div>
              <div className={classes.ModalHeadingSection}>
                <Typography className={classes.modalHeadind}>
                  Set New Password
                </Typography>
                <Typography
                  className={classes.modalHeadin2}
                  style={{ textAlign: "center", width: "95%" }}
                >
                  Set your new password so you can login & access Team Player
                </Typography>
                <ValidatorForm
                  useref="form"
                  onSubmit={handleResetPassword}
                  style={{ width: "100%" }}
                  className={classes.InputForm}
                >
                  <div className="mt-4 mb-3">
                    <div className="mb-4">
                      <Typography
                        htmlFor="userpassword"
                        className={classes.form_lable}
                      >
                        New Password
                      </Typography>
                      <div className={classes.passwordInputContainer}>
                        <TextValidator
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                          }}
                          id="outlined-adornment-password"
                          type={showPassword ? "text" : "password"}
                          fullWidth={true}
                          size="small"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <div className={classes.showPassword}>
                          {showPassword ? (
                            <Button
                              variant="text"
                              onClick={() => setShowPassword(false)}
                            >
                              <CloseEyeIcon />
                            </Button>
                          ) : (
                            <Button onClick={() => setShowPassword(true)}>
                              <OpenEyeIcon />
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="mb-2">
                      <Typography
                        htmlFor="userpassword"
                        className={classes.form_lable}
                      >
                        Confirm New Password
                      </Typography>
                      <div className={classes.passwordInputContainer}>
                        <TextValidator
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                          }}
                          id="outlined-adornment-password"
                          type={showPassword1 ? "text" : "password"}
                          fullWidth={true}
                          size="small"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <div className={classes.showPassword}>
                          {showPassword1 ? (
                            <Button
                              variant="text"
                              onClick={() => setShowPassword1(false)}
                            >
                              <CloseEyeIcon />
                            </Button>
                          ) : (
                            <Button onClick={() => setShowPassword1(true)}>
                              <OpenEyeIcon />
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <Button
                        variant="contained"
                        type="submit"
                        fullWidth={true}
                        style={{ width: "100%", textTransform: "none" }}
                      >
                        Reset Password
                      </Button>
                    </div>
                  </div>
                </ValidatorForm>
              </div>
            </div>
          ) : null}
          {!otpReceived && !setpassword && successModal ? (
            <div className="mb-4">
              <div className={classes.ModalHeadingSection}>
                <img
                  src={image}
                  width={150}
                  height={150}
                  alt="successImage"
                  style={{ marginBottom: "20px", borderRadius: "50%" }}
                />
                <Typography className={`mb-2 ${classes.modalHeadind}`}>
                  Successful Password Reset
                </Typography>
                <Typography
                  className={`mb-5 ${classes.modalHeadin2}`}
                  style={{ textAlign: "center" }}
                >
                  Your password has been successfully reset.
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => closeModal()}
                  fullWidth={true}
                  style={{ width: "100%", textTransform: "none" }}
                >
                  Login
                </Button>
              </div>
            </div>
          ) : null}
        </Box>
      </Modal>
    </div>
  );
};

export default ForgetPassword;
