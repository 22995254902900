import React, { useEffect } from 'react'
import classes from "./supportTicket.module.css";
import { Typography, Tooltip, Card } from "@mui/material";
import { formatBytes } from '../../../Utilities/commonfunctions';
import { DeleteBinIcons, ExcelFileExtension, PdfFileIcon } from '../../../Components/Icons/Icons';


const FileReturnComponent = ({ item, index, remove, type }) => {
    let image, originalname, extension;
    if (type === "new") {
        image = URL.createObjectURL(item);
        originalname = item.name;
        extension = originalname.split('.').pop();
    }
    else {
        image =
            item.filename && item.destination
                ? `${process.env.REACT_APP_IMAGE_URL}/${item.destination}${item.filename}`
                : URL.createObjectURL(item);
        originalname = item.originalname;
        extension = originalname.split('.').pop();
    }
    useEffect(() => {
        console.log(image, originalname);
    }, [image, originalname]);
    return (
        <div>
            {
                extension === "pdf" ? (
                    <Card className={classes.cardContainer}>
                        <div className='d-flex align-items-center' style={{ columnGap: "10px" }}>
                            <PdfFileIcon />
                            <Typography className={classes.fileName}>{item.originalname || item.name}</Typography>
                        </div>
                        <Typography className={classes.SomeOtherTextclosedBy}>{formatBytes(item.size)}</Typography>
                    </Card>
                ) : (extension === "xlsx" || extension === "xls") ? (
                    <Card className={classes.cardContainer}>
                        <div className='d-flex align-items-center' style={{ columnGap: "10px" }}>
                            <ExcelFileExtension />
                            <Typography className={classes.fileName}>{item.originalname || item.name}</Typography>
                        </div>
                        <Typography className={classes.SomeOtherTextclosedBy}>{formatBytes(item.size)}</Typography>
                    </Card>
                ) : (
                    <div className={classes.subImageContainer}>
                        <img
                            src={image}
                            width="250px"
                            height="150px"
                            style={{ maxHeight: "250px" }}
                            alt={item.originalname || item.name}
                        />
                        <div className={classes.imageText}>
                            <Tooltip title={item.originalname || item.name}>
                                <Typography
                                    className={`${classes.form_lable} ${classes.detailsText1}`}
                                    noWrap
                                    style={{
                                        overflow: "hidden",
                                        width: "150px",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    {item.originalname || item.name}
                                </Typography>
                            </Tooltip>

                            <div style={{ display: "flex", columnGap: "10px" }}>
                                {item.size ? (
                                    <Typography className={`${classes.form_lable}`}>
                                        {formatBytes(item.size)}
                                    </Typography>
                                ) : null}
                                <Typography
                                    className={`${classes.form_lable} ${classes.ImageDeleteBtn}`}
                                    onClick={() => remove(item.filename)}
                                >
                                    <DeleteBinIcons />
                                </Typography>
                            </div>
                        </div>
                    </div >
                )
            }
        </div>
    );
}

export default FileReturnComponent