import React, { useEffect } from "react";
import classes from "./feedback.module.css";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  OppositeSortArrawBtnIcons,
  SortArrawBtnIcons,
} from "../../../Components/Icons/Icons";
import Pagination from "../../../Components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import FilterMenu from "../../../Components/customerPageFilter";
import { CustomerIndustyFilter } from "../../../Redux/actions/filterActions";
import { Industries } from "../../../Redux/constants/filterConstatns";
import FeedbackListItem from "./feedbackListItem";
import { fetchFeedbacks } from "../../../Redux/actions/feedbackAction";
import { handleSortClick } from "../../../Utilities/commonfunctions";

const TeamLeaderFeedback = ({ user_type }) => {
  const dispatch = useDispatch();
  const PaginationData = useSelector(
    (state) => state.paginationData.Pagination
  );
  const { rowCount, currentPage } = PaginationData || {};
  const feedbackList = useSelector((state) => state.feedbackData);
  const { loading, error, feedback, totalCount } = feedbackList || {};
  const { sortBy } = useSelector((state) => state.filter);

  useEffect(() => {
    dispatch(fetchFeedbacks(currentPage, rowCount, user_type, sortBy));
  }, [dispatch, currentPage, rowCount, user_type, sortBy]);

  useEffect(() => {
    console.log(feedback);
  }, [feedback]);

  return (
    <div>
      <div className={classes.TableContainer}>
        <Table className={classes.MainTable}>
          <TableHead>
            <TableRow className={classes.tableHeadRow}>
              <TableCell
                className={classes.tableHeadCell}
                style={{ width: "20%" }}
              >
                <div className={classes.tableHEadercelldiv}>
                  <span> Reviewer Name </span>{" "}
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "userInfo.name")
                    }
                  >
                    {sortBy?.includes("desc") &&
                    sortBy?.includes("userInfo.name") ? (
                      <OppositeSortArrawBtnIcons />
                    ) : (
                      <SortArrawBtnIcons />
                    )}
                  </span>
                </div>
              </TableCell>
              <TableCell
                className={classes.tableHeadCell}
                style={{ width: "37%" }}
              >
                <div className={classes.tableHEadercelldiv}>
                  <span> Review </span>{" "}
                  <span
                    onClick={() => handleSortClick(dispatch, sortBy, "rating")}
                  >
                    {sortBy?.includes("desc") && sortBy?.includes("rating") ? (
                      <OppositeSortArrawBtnIcons />
                    ) : (
                      <SortArrawBtnIcons />
                    )}
                  </span>
                </div>
              </TableCell>
              <TableCell
                className={classes.tableHeadCell}
                style={{ width: "26%" }}
              >
                <div className={classes.tableHEadercelldiv}>
                  <span> Documents </span> {/* <SortArrawBtnIcons /> */}
                </div>
              </TableCell>
              <TableCell
                className={classes.tableHeadCell}
                style={{ width: "17%" }}
              >
                <div className={classes.tableHEadercelldiv}>
                  <span> Date & Time </span>{" "}
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "createdAt")
                    }
                  >
                    {sortBy?.includes("desc") &&
                    sortBy?.includes("createdAt") ? (
                      <OppositeSortArrawBtnIcons />
                    ) : (
                      <SortArrawBtnIcons />
                    )}
                  </span>
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <td colSpan="9" className="text-center">
                  Loading...
                </td>
              </TableRow>
            ) : error ? (
              <TableRow>
                <td colSpan="9" className="text-center">
                  {error}
                </td>
              </TableRow>
            ) : feedback && feedback.length > 0 ? (
              feedback.map((item, index) => (
                <FeedbackListItem key={index} item={item} />
              ))
            ) : (
              <TableRow>
                <td colSpan="9" className="text-center">
                  No Data Found
                </td>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <Pagination total={totalCount} />
    </div>
  );
};

export default TeamLeaderFeedback;
